.button {
  width: 100%;
  margin: 20px 0;
  font-family: Rubik;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.16px;
  color: var(--primary-navy);
  border-radius: 8px;
  border: solid 1px rgba(0, 0, 0, 0.06);
  background-color: var(--white);
  align-items: baseline;
  padding: 0;
  display: flex;
  padding-left: 20px;

  &:hover {
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.05);
    border: solid 1px var(--primary-60);
    background-color: var(--primary-60);
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--white);
  }
}

.select_wrapper {
  width: 100%;
}
