.opened {
  transform: rotate(0deg) !important;
}

.collapse {
  position: relative !important;
  //background-color: transparent !important;
  border: none !important;
  user-select: none;
  background: var(--background) !important;
}

.icon {
  position: absolute;
  right: 15px;
  width: 10px;
  height: 10px;
  -webkit-mask: url(../../../assets/images/icons/arrow-up.svg) no-repeat center;
  mask: url(../../../assets/images/icons/arrow-up.svg) no-repeat center;
  background-color: var(--gray-60);
  transform: rotate(180deg);
}

.activeTitle {
  font-weight: bold;
  color: var(--gray-60) !important;
}
