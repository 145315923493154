.notification_block {
  background-color: rgba(34, 34, 34, 0.9);
  //border-radius: 16px;
}

.border {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 328px;
  min-height: calc(275px - (48px + 48px));
  background-color: var(--background);
  padding: 48px 0 48px 0;
  border-radius: 8px;
}

.title {
  margin: 0 0 12px 0 !important;
}

.text {
  margin: 0 25px !important;
}
.resendBtn {
  margin: 48px 0 0 0 !important;

  a {
    letter-spacing: inherit;
  }
}
