@import '../../../../assets/styles/include-media';

.form {
  margin-top: 47px;
  max-width: 770px;

  @include media('<desktop') {
    max-width: 100%;
    flex-direction: column;
    margin-bottom: 30px;
  }

  &__part {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0px 32px;
    width: 100%;
    max-width: 736px;

    @include media('<tablet') {
      grid-template-columns: 1fr;
    }

    > * {
      width: 100% !important;
    }
    input {
      min-width: 0;
    }
  }

  &__part__bio {
    display: grid;
    width: 100%;
    max-width: 736px;

    input {
      box-sizing: border-box;
      max-width: 342px;
    }
  }

  &__radioPart {
    margin-bottom: 24px;
  }
  &__subTitle {
    font: 700 20px/1.2 Rubik;
    color: var(--gray-60);
    text-align: center;
    margin: 20px 0 40px;
    max-width: 736px;
  }
  &__additional {
    display: flex;
    max-width: 770px;

    @include media('<desktop') {
      max-width: 100%;
      flex-direction: column;
    }
  }
  &__buttons {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    max-width: 736px;
  }
}

.modal {
  min-height: 250px;
}

.btns {
  display: flex;
  justify-content: center;
  width: 100%;
}

.password {
  &__input {
    width: 100%;
  }

  &__wrap {
    margin-bottom: 30px;
  }

  &__reset {
    font-size: 12px;
    line-height: 1.5;
    letter-spacing: 0.24px;
    color: var(--gray-30);
    margin: 5px 0 30px 15px;
    cursor: pointer;
    user-select: none;
  }

  &__fields {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
  }

  &__modal {
    min-height: 260px;
  }
}

.resendEmail {
  position: relative;

  &_btn {
    position: absolute;
    right: 0;
    bottom: 5px;
    margin: 0;
    a {
      &:before {
        display: none;
      }
    }
  }
}

.infinityEditor {
  :global {
    .rsw-editor {
      height: auto;
      max-width: 736px;
      .rsw-ce {
        height: inherit;
      }
    }
  }
}
