.b-elmBox {
  padding: 40px 100px;
  border-radius: 16px;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
  background-color: var(--panel-background);
  box-sizing: border-box;
  font-family: Rubik;
  font-size: 14px;
  line-height: 1.4;
  color: var(--gray-60);
  margin-bottom: 60px;

  &:last-child {
    margin-bottom: 0;
  }

  @include media('<tablet') {
    padding: 32px;
    margin-bottom: 40px;
  }

  &__illus {
    width: 190px;
    height: auto;
    display: block;
    margin: 0 auto 32px;

    @include media('<tablet') {
      margin-bottom: 20px;
    }
  }
  &__title {
    color: var(--primary-navy);
    font-family: Rubik;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    display: block;
    margin-bottom: 20px;
  }
  &__subTitle {
    font-size: 14px;
    font-family: Rubik;
    font-weight: 700;
    margin: 0 0 5px;
  }

  p {
    margin: 0 0 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
  a {
    color: var(--primary-60);
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
  &__mark {
    color: var(--primary-60);
  }
  &__list {
    li {
      position: relative;
      padding-left: 15px;
      padding-bottom: 15px;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 7px;
        height: 3px;
        width: 3px;
        border-radius: 50%;
        background-color: var(--gray-60);
      }
    }
  }
}

.b-elmTable {
  width: 100%;
  overflow: auto;
  margin-bottom: 20px;

  table {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
    margin: 0;
    min-width: 270px;

    td,
    th {
      color: var(--gray-60);
      font-family: Rubik;
      font-size: 12px;
      padding: 12px;
    }
    th {
      text-align: left;
    }
    thead {
      th {
        background-color: var(--gray-10);
        padding-top: 5px;
        padding-bottom: 5px;
      }
    }
    tbody {
      background-color: var(--elm-intro-table-row-1);

      &:nth-of-type(even) {
        background-color: var(--elm-intro-table-row-2);
      }
    }
  }
}
