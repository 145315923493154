@import '../../../assets/styles/include-media';

.b-formAccount {
  color: var(--gray-60);
  margin-top: 47px;
  max-width: 770px;

  @include media('<desktop') {
    max-width: 100%;
    flex-direction: column;
    margin-bottom: 30px;
  }

  &.-center {
    margin: 0 auto;
  }

  &__part {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0px 32px;
    width: 100%;
    max-width: 736px;

    @include media('<tablet') {
      grid-template-columns: 1fr;
    }

    > * {
      width: 100% !important;
    }
    input {
      min-width: 0;
    }
  }
  &__radioPart {
    margin-bottom: 24px;
  }
  &__subTitle {
    font: 700 16px/1.5 Rubik;
    color: var(--gray-60);
    text-align: left;
    margin: 20px 0 16px;
    max-width: 736px;
  }
  &__buttons {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    max-width: 736px;
  }

  ul {
    padding-left: 20px;
    list-style: disc;

    li {
      margin-bottom: 10px;
    }

    ul {
      margin-top: 8px;
      list-style: square;
      li {
        margin-bottom: 8px;
      }
    }
  }

  a {
    color: var(--primary-60);
  }
  &__googleCalendarIcon {
    width: 24px;
  }
  &__text {
    color: var(--gray-60);
  }
  &__googleAuth {
    font-size: 14px;
    ul {
      li {
        text-decoration: none;
        list-style: none;
        font-weight: bold;
      }
    }
  }
}
