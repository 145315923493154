@import '../../../assets/styles/include-media';

.link {
  display: flex;
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: 0.16px;
  color: var(--primary-60);
  align-items: center;
  margin-top: 20px;

  @include media('<tablet') {
    justify-content: center;
  }

  &__icon {
    margin-right: 8px;
    width: 16px;
    height: 16px;
  }

  &__ic {
    margin-right: 8px;
    width: 16px;
    height: 16px;
    border-radius: 16px;
    background-color: var(--primary-60);
  }
}

.title {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  color: var(--primary-navy);
}

.list {
  margin-top: 20px;
  margin-bottom: 20px;
}

.item {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tip {
  margin-bottom: 60px;
}
