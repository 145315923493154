:root,
:root.light {
  --gray-10: #e5e5e5;
  --gray-20: #ccc;
  --gray-30: #b2b2b2;
  --gray-60: #666;
  --gray-70: #4c4c4c;
  --gray-80: #222;
  --black: #121212;
  --white-black: #fff;
  --white-orange: #fff;
  --white-gray-80: #fff;
  --black-white: #000;
  --panel-color-10: #f9f9f9;
  --text-color-10: rgba(102, 102, 102, 0.5);
  --table-row-color: #f5f5f5;
  --table-row-color-hover: #e5e5e5;

  --primay-50: #ffb71b;
  --primay-yellow: #ffe800;
  --primary-60: #ff9015;
  --primary-70: #ff6b00;
  --primary-navy: #00002d;

  --white: #fff;
  --primary-80: #ea0029;
  --red-60: #da3a41;
  --green-60: #109c54;
  --red-70: #be1720;
  --red-80: #911118;

  // Not added on design
  --orange: #fe6b00;
  --rad: #d52121;
  --trail: #ffe9ba;
  --background: #fff;
  --panel-background: #fff;
  --user-avatar: #e2e2e4;
  --user-username: #ff9015;
  --user-username-title: #00002d;
  --take-ea-steps-left-shadow: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(255, 255, 255, 1) 84%
  );
  --take-ea-steps-right-shadow: linear-gradient(
    to left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(255, 255, 255, 1) 84%
  );
  --elm-intro-table-row-1: rgba(229, 229, 229, 0.3);
  --elm-intro-table-row-2: rgba(229, 229, 229, 0.5);
  --date-picker-day-hover: #f0f0f0;
  --bd-color1: #f0f0f0;
  --bd-color2: #e5e5e5;
  --bd-color3: rgba(0, 0, 0, 0.06);
  --chartBg1: #fcfcfc;
  --chartBg2: #f7f7f7;
  --bg-color-tr-1: rgba(255, 255, 255, 0.9);
  --bg-color-tr-2: rgba(229, 229, 229, 0.1);
  --color-disable: #ccc;
  --tag-color: #5a5a5a;
  --gray-60-10: #666;
  --gray-60-30: #666;
}

:root.dark {
  --gray-10: #222;
  --gray-20: #4c4c4c;
  --gray-30: #e5e5e5;
  --gray-60: #b2b2b2;
  --gray-70: #ccc;
  --gray-80: #e5e5e5;
  --black: #121212;
  --white-black: #00002d;
  --white-orange: #fca336;
  --white-gray-80: #222;
  --black-white: #fff;
  --panel-color-10: #333;
  --text-color-10: rgba(229, 229, 229, 0.45);
  --table-row-color: #222;
  --table-row-color-hover: #121212;

  --primay-50: #ffb71b;
  --primay-yellow: #ffe800;
  --primary-60: #fca336;
  --primary-70: #ff6b00;
  --primary-navy: #e5e5e5;

  --white: #fff;
  --primary-80: #ea0029;
  --red-60: #da3a41;
  --green-60: #15cf6f;
  --red-70: #be1720;
  --red-80: #911118;

  // Not added on design
  --orange: #fe6b00;
  --rad: #d52121;
  --trail: #ffe9ba;
  --background: #282828;
  --panel-background: #222;
  --user-avatar: #ff9015;
  --user-username: #00002d;
  --user-username-title: #fca336;
  --take-ea-steps-left-shadow: linear-gradient(
    to right,
    rgba(40, 40, 40, 0) 0%,
    rgba(40, 40, 40, 0.8) 50%,
    rgba(40, 40, 40, 1) 84%
  );
  --take-ea-steps-right-shadow: linear-gradient(
    to left,
    rgba(40, 40, 40, 0) 0%,
    rgba(40, 40, 40, 0.8) 50%,
    rgba(40, 40, 40, 1) 84%
  );
  --elm-intro-table-row-1: rgba(100, 100, 100, 0.3);
  --elm-intro-table-row-2: rgba(120, 120, 120, 0.5);
  --date-picker-day-hover: #222;
  --bd-color1: #4c4c4c;
  --bd-color2: #b2b2b2;
  --bd-color3: rgba(255, 255, 255, 0.06);
  --chartBg1: #282828;
  --chartBg2: #373737;
  --bg-color-tr-1: rgba(0, 0, 0, 0.75);
  --bg-color-tr-2: rgba(0, 0, 0, 0.8);
  --color-disable: #b2b2b2;
  --tag-color: #222;
  --gray-60-10: #e5e5e5;
  --gray-60-30: #b2b2b2;
}
