@import '../../../../assets/styles/include-media';

.userName {
  margin-left: 3px;
  margin-right: 3px;
  color: var(--primary-60);
}

.homeContent {
  max-width: 735px;
  margin-left: 0;
  margin-right: auto;
  margin-bottom: 40px;
  border-radius: 16px;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.05);
  background-color: var(--panel-background);
  text-align: center;

  &_wrapper {
    padding: 60px 100px;

    @include media('<tablet') {
      padding: 40px 32px;
    }
  }

  &_ill {
    width: 190px;
    height: 190px;
    display: block;
    margin: 0 auto 32px;
  }

  &_smallTitle {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    color: var(--primary-navy);
    margin: 0 0 32px;

    span {
      color: var(--primary-60);
    }
  }

  &_text {
    text-align: center;
    max-width: 535px;
    margin: 0 auto;
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 0.16px;
    color: var(--gray-60);
  }

  &_p {
    margin-bottom: 32px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.msg {
  margin-bottom: 20px;
}
