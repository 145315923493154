.title {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: var(--gray-60);
  margin-bottom: 40px;
}

.sub_title {
  font-family: Rubik;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: var(--gray-60);
  display: block;
  width: 350px;

  @media screen and (max-width: 824px) {
    width: 260px;
  }
}

.container {
  height: 100vh;
}
