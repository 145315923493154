.text-center {
  text-align: center;
  max-width: 545px;
}

.b-title {
  font-family: Rubik;
  font-weight: bold;
  font-size: 24px;
  margin: 10px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
}

.pre-title {
  font-family: Rubik;
  font-weight: bold;
  font-size: 20px;
  margin: 10px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
}

.sub-title {
  font-family: Rubik;
  font-weight: bold;
  font-size: 16px;
  margin: 10px;
  width: 351px;
  text-align: center;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;

  @media screen and (max-width: 824px) {
    width: 328px;
  }
}

.text-description {
  font-family: Rubik;
  font-size: 16px;
  line-height: 24px;
}

.text-long-description {
  font-family: Rubik;
  font-size: 14px;
  color: var(--primary-navy);
}

.bold {
  font-weight: 900;
}

.text-container {
  margin: 0 30px 30px 30px !important;
  text-align: center;
  max-width: 545px;
}

.links {
  color: var(--primary-60);
  letter-spacing: 0.16px;
  cursor: pointer;
}

.links_underline_text {
  text-decoration: underline;
}

.sub-container-row {
  display: flex;
  flex-direction: row;
  margin: 40px;
  align-items: center;
  justify-content: center;
}

.required-legend {
  color: var(--primary-80);
  opacity: 0.65;
}

.hoveredCell {
  font-size: 12px;
  background: var(--table-row-color-hover);
  padding-bottom: 10px;
  .b-ewp__cellEl {
    margin: 5px 0;
  }
}

.required-field-modifier {
  position: relative;
  &:before {
    height: 10px;
    width: 10px;
    font-size: 18px;
    line-height: 10px;
    text-align: center;
    font-weight: 400;
    position: absolute;
    left: -10px;
    top: 2px;
    color: var(--primary-80);
    content: '*';
  }

  &.-type_select {
    &:before {
      top: -6px;
    }
  }
}

.content-left {
  align-items: start;
  margin-left: 9px;
  margin-bottom: 16px;
}

.content-center {
  align-items: center;
}

.content-center-wrapper {
  flex: 1;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.button-radio {
  cursor: pointer;
}

.error-notification {
  display: flex;
  min-height: 21px;
  width: calc(100% - 14px);
  margin-bottom: 5px;
  margin-left: 14px;

  @media screen and (max-width: 824px) {
    min-width: 260px;
  }
}

.error-notification-text {
  font-size: 12px;
  color: var(--rad);
  line-height: 16px;
}

.font-s {
  font-size: 14px;
}

.font-m {
  font-size: 16px;
}

.font-l {
  font-size: 20px;
}

.font-x {
  font-size: 24px;
}

.text-break {
  overflow-wrap: anywhere;
}

.font-spacing-s {
  line-height: 1.43;
  letter-spacing: 0.16px;
}

.font-spacing-m {
  line-height: 1.43;
  letter-spacing: 0.16px;
}

.font-spacing-l {
  line-height: 1.43;
  letter-spacing: 0.16px;
}

.font-spacing-x {
  line-height: 1.43;
  letter-spacing: 0.16px;
}

.text_spacing {
  letter-spacing: 3px;
}

.popover {
  z-index: 1;
}

.b-yearPicker {
  color: var(--primary-navy);
  width: 160px;
  height: 37px;
  border-radius: 12px;
  padding-left: 20px;
  background-color: rgba(0, 0, 0, 0.05);
  font-size: 12px;
  position: relative;
  &:before {
    position: absolute;
    width: 14px;
    height: 15px;
    top: 50%;
    left: 17px;
    transform: translateY(-50%);
    content: '';
  }
}

// web Accessibility styles
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.contentContainer {
  max-width: 780px;
}

.simple-select {
  outline: none;
  cursor: pointer;
  padding: 5px;
  color: var(--primary-navy);
  background-color: var(--panel-background);
  margin-right: -5px;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  &::-ms-expand {
    display: none;
  }
}

body > iframe[style*='2147483647']:not([id='webpack-dev-server-client-overlay']) {
  display: none;
}

.svg-color {
  fill: var(--primary-navy);
}

.paragraph {
  font-size: 16px;
  line-height: 1.5;
  color: var(--gray-60);
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;

  &.-column {
    flex-direction: column;
  }
}

.btn {
  width: 189px;
  height: 48px;
  font-family: Rubik;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  text-align: center;
  //color: var(--white);

  &.-type_form {
    width: 160px;
  }

  &.-newEA {
    flex-shrink: 0;
    width: 205px;
    //position: absolute;
    //right: 0;

    @include media('<desktop') {
      position: relative;
    }
  }

  &.full-width {
    width: 100%;
  }
}
