.b-tableTool {
  font-size: 14px;
  line-height: 1.43;
  padding-left: 32px;
  position: relative;
  padding-right: 0;
  text-align: left;
  letter-spacing: 0.16px;
  color: var(--primary-navy);
  background: transparent;

  &:before {
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    content: '';
    position: absolute;
    width: 24px;
    height: 24px;
    background-color: var(--primary-navy);
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.3;
    //color: red;
  }

  &.-mr {
    margin-right: 24px;
  }

  &.-mobView {
    @include media('<desktop') {
      width: 24px;
      height: 24px;
      font-size: 0;
    }
  }

  &.-colorGrey {
    color: var(--gray-60);

    &:before {
      background-color: var(--gray-60);
    }
  }

  &.-active {
    color: var(--primary-60);

    &:before {
      background-color: var(--primary-60);
    }
  }

  &.-noIcon {
    display: flex;
    align-items: center;
    padding-left: 0;

    img {
      padding-right: 8px;
    }

    &:before {
      display: none;
    }
  }

  &.-info {
    &:before {
      -webkit-mask: url(../../images/icons/round-info.svg) no-repeat center;
      mask: url(../../images/icons/round-info.svg) no-repeat center;
      width: 24px;
      height: 24px;
    }
  }

  &.-actions {
    &:before {
      -webkit-mask: url(../../images/icons/dots.svg) no-repeat center;
      mask: url(../../images/icons/dots.svg) no-repeat center;
      transform: rotate(90deg) translateY(-5px) translateX(-12px);
      width: 24px;
      height: 24px;
    }
  }

  &.-info {
    &:before {
      -webkit-mask: url(../../images/icons/round-info.svg) no-repeat center;
      mask: url(../../images/icons/round-info.svg) no-repeat center;
      width: 24px;
      height: 24px;
    }
  }

  &.-tableView {
    &:before {
      -webkit-mask: url(../../images/icons/table_view_ic.svg) no-repeat center;
      mask: url(../../images/icons/table_view_ic.svg) no-repeat center;
      width: 24px;
      height: 24px;
    }
  }

  &.-cardView {
    &:before {
      -webkit-mask: url(../../images/icons/cards_view_ic.svg) no-repeat center;
      mask: url(../../images/icons/cards_view_ic.svg) no-repeat center;
      width: 24px;
      height: 24px;
    }
  }

  &.-send {
    &:before {
      -webkit-mask: url(../../images/icons/send.svg) no-repeat center;
      mask: url(../../images/icons/send.svg) no-repeat center;
    }
  }

  &.-message {
    &:before {
      -webkit-mask: url(../../images/icons/mail-icon.svg) no-repeat center;
      mask: url(../../images/icons/mail-icon.svg) no-repeat center;
    }
  }

  &.-userOff {
    &:before {
      -webkit-mask: url(../../images/icons/person_off.svg) no-repeat center;
      mask: url(../../images/icons/person_off.svg) no-repeat center;
    }
  }

  &.-userOn {
    &:before {
      -webkit-mask: url(../../images/icons/round-action-supervisor-account-black.svg) no-repeat center;
      mask: url(../../images/icons/round-action-supervisor-account-black.svg) no-repeat center;
    }
  }

  &.-assignmentEnd {
    &:before {
      -webkit-mask: url(../../images/icons/assignment-end.svg) no-repeat center;
      mask: url(../../images/icons/assignment-end.svg) no-repeat center;
    }
  }

  &.-download {
    &:before {
      -webkit-mask: url(../../images/icons/round-content-save-alt.svg) no-repeat center;
      mask: url(../../images/icons/round-content-save-alt.svg) no-repeat center;
    }
  }

  &.-delete {
    &:before {
      -webkit-mask: url(../../images/icons/delete.svg) no-repeat center;
      mask: url(../../images/icons/delete.svg) no-repeat center;
    }
  }

  &.-copy {
    &:before {
      -webkit-mask: url(../../images/icons/copy_ic.svg) no-repeat center;
      mask: url(../../images/icons/copy_ic.svg) no-repeat center;
    }
  }

  &.-elm-role {
    &:before {
      -webkit-mask: url(../../images/icons/elm-role.svg) no-repeat center;
      mask: url(../../images/icons/elm-role.svg) no-repeat center;
    }
  }

  &.-visibility-on {
    &:before {
      -webkit-mask: url(../../images/icons/icon-visible-on.svg) no-repeat center;
      mask: url(../../images/icons/icon-visible-on.svg) no-repeat center;
    }
  }

  &.-visibility-off {
    &:before {
      -webkit-mask: url(../../images/icons/icon-visibility-off.svg) no-repeat center;
      mask: url(../../images/icons/icon-visibility-off.svg) no-repeat center;
    }
  }

  &.-filter {
    &:before {
      -webkit-mask: url(../../images/icons/filter.svg) no-repeat center;
      mask: url(../../images/icons/filter.svg) no-repeat center;
    }
  }

  &.-revoke {
    &:before {
      -webkit-mask: url(../../images/icons/revoke.svg) no-repeat center;
      mask: url(../../images/icons/revoke.svg) no-repeat center;
    }
  }

  &.-ga {
    &:before {
      -webkit-mask: url(../../images/icons/icon-group-analysis.svg) no-repeat center;
      mask: url(../../images/icons/icon-group-analysis.svg) no-repeat center;
    }
  }

  &.-gav {
    &:before {
      -webkit-mask: url(../../images/icons/group-averages.svg) no-repeat center;
      mask: url(../../images/icons/group-averages.svg) no-repeat center;
    }
  }

  &.-new {
    &:before {
      -webkit-mask: url(../../images/icons/add-circle.svg) no-repeat center;
      mask: url(../../images/icons/add-circle.svg) no-repeat center;
    }
  }

  &.-order {
    &:before {
      -webkit-mask: url(../../images/icons/order.svg) no-repeat center;
      mask: url(../../images/icons/order.svg) no-repeat center;
    }
  }

  &.-sort {
    &:before {
      -webkit-mask: url(../../images/icons/sort.svg) no-repeat center;
      mask: url(../../images/icons/sort.svg) no-repeat center;
    }
  }

  &.-addPlus {
    &:before {
      width: 19px;
      height: 19px;
      display: block;
      font-size: 18px;
      line-height: 19px;
      text-align: center;
      border-radius: 50%;
      content: '+';
      color: var(--background);
      //position: static;
    }
  }
}

.b-tableToolContainer {
  display: flex;
  align-items: center;

  &.-centered {
    justify-content: center;
  }

  &.-mobMb {
    @include media('<desktop') {
      margin-bottom: 30px;
    }
  }
}

// searchForm
.searchForm {
  border: 1px solid var(--gray-30);
  border-radius: 3.5px;
  padding: 12px 12px 12px 16px;
  display: flex;
  align-items: center;

  input[type='search'] {
    border: 0;
    color: var(--primary-navy);
    background-color: transparent;
    font-size: 16px;
    font-family: Rubik;
    flex-grow: 1;
    padding-right: 15px;
    outline: none;
  }

  input[type='search']::-webkit-search-cancel-button {
    -webkit-appearance: none;
    -webkit-mask: url(../../../assets/images/icons/cross.svg) no-repeat center;
    mask: url(../../../assets/images/icons/cross.svg) no-repeat center;
    width: 20px;
    height: 20px;
    background: var(--primary-60);
    cursor: pointer;
  }

  &__icon {
    background-color: var(--gray-60);
    -webkit-mask: url(../../../assets/images/icons/search.svg) no-repeat center;
    mask: url(../../../assets/images/icons/search.svg) no-repeat center;
    width: 20px;
    height: 20px;
  }
}
