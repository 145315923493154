.sub_title {
  width: 352px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: var(--gray-60);

  @media screen and (max-width: 824px) {
    width: 328px;
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 0.16px;
  }
}
