.b-page-back-link {
  font-family: Rubik;
  font-size: 20px;
  display: block;
  font-weight: bold;
  line-height: 1.2;
  color: var(--primary-navy);
  padding-left: 48px;
  position: relative;
  margin-bottom: 36px;

  &:before {
    position: absolute;
    content: '';
    display: block;
    width: 24px;
    left: 0;
    height: 24px;
    background-color: var(--primary-navy);
    -webkit-mask: url(../../../assets/images/icons/round-navigation-arrow-back.svg) no-repeat center;
    mask: url(../../../assets/images/icons/round-navigation-arrow-back.svg) no-repeat center;
  }

  @include media('<tablet') {
    margin-bottom: 10px;
  }

  &.hasIcon {
    padding-right: 30px;
  }
}

.b-button-icon-back {
  position: absolute;
  display: block;
  left: -42px;
  top: 3px;
  width: 24px;
  height: 24px;
  background-color: var(--primary-navy);
  -webkit-mask: url('../../images/icons/round-navigation-arrow-back.svg') no-repeat center;
  mask: url('../../images/icons/round-navigation-arrow-back.svg') no-repeat center;

  @include media('<widescreen') {
    left: -30px;
  }
  @include media('<tablet') {
    left: -10px;
    top: 0;
  }
}

.b-button-icon {
  padding: 0;
  width: 20px;
  height: 20px;
  display: block;
  background-color: var(--gray-60);

  &:hover {
    background-color: var(--primary-60);
  }

  &.-w40 {
    width: 40px;
    height: 40px;
    mask-size: 40px !important;
  }

  &.-w16 {
    width: 16px !important;
    height: 16px !important;
    mask-size: 16px !important;
  }

  &.-w12 {
    width: 12px !important;
    height: 12px !important;
    mask-size: 12px !important;
  }

  &.-noHover {
    background-color: var(--gray-60);
  }

  &.-colorNavy {
    background-color: var(--primary-navy);
    &:disabled {
      background-color: var(--gray-60);
    }
  }

  &.-colorOrange {
    background-color: var(--primary-60);
  }

  &.-colorRed {
    background-color: var(--primary-80) !important;
  }

  &.-disabled {
    user-select: none;
    background: var(--gray-30);
  }

  &.-colorWhite {
    background-color: #fff;
  }

  &.-mh2 {
    margin: 0 2px;
  }

  &.-mr {
    margin-right: 15px;
  }

  &.-delete {
    -webkit-mask: url(../../../assets/images/icons/delete.svg) no-repeat center / 20px;
    mask: url(../../../assets/images/icons/delete.svg) no-repeat center / 20px;
  }

  &.-info {
    -webkit-mask: url(../../images/icons/round-info.svg) no-repeat center;
    mask: url(../../images/icons/round-info.svg) no-repeat center;
    width: 24px;
    height: 24px;
    background-color: var(--primary-navy);
  }

  &.-send {
    -webkit-mask: url(../../../assets/images/icons/send.svg) no-repeat center;
    mask: url(../../../assets/images/icons/send.svg) no-repeat center;
    width: 20px;
    height: 20px;

    &.-congrat_page {
      mask-size: 25px;
      width: 35px;
      margin-left: 0;
      height: 35px;
    }
  }

  &.-ewp_priority {
    -webkit-mask: url(../../images/icons/ewp/3.svg) no-repeat center;
    mask: url(../../images/icons/ewp/3.svg) no-repeat center;
  }

  &.-ewp_goals {
    -webkit-mask: url(../../images/icons/ewp/4.svg) no-repeat center;
    mask: url(../../images/icons/ewp/4.svg) no-repeat center;
  }

  &.-ewp_strats {
    -webkit-mask: url(../../images/icons/ewp/5.svg) no-repeat center;
    mask: url(../../images/icons/ewp/5.svg) no-repeat center;
  }
  &.-ea {
    -webkit-mask: url(../../images/icons/ea.svg) no-repeat center / 20px;
    mask: url(../../images/icons/ea.svg) no-repeat center / 20px;
  }
  &.-ewp_ka {
    -webkit-mask: url(../../images/icons/icons-ewp-key-action-4.svg) no-repeat center;
    mask: url(../../images/icons/icons-ewp-key-action-4.svg) no-repeat center;
  }

  &.-share {
    -webkit-mask: url(../../../assets/images/icons/icon-social-share.svg) no-repeat center;
    mask: url(../../../assets/images/icons/icon-social-share.svg) no-repeat center;
  }

  &.-edit {
    -webkit-mask: url(../../../assets/images/icons/edit_icon.svg) no-repeat center / 20px;
    mask: url(../../../assets/images/icons/edit_icon.svg) no-repeat center / 20px;
  }

  &.-close {
    -webkit-mask: url(../../../assets/images/icons/cross.svg) no-repeat center / 25px;
    mask: url(../../../assets/images/icons/cross.svg) no-repeat center / 25px;
  }

  &.-link_res {
    width: 30px;
    height: 30px;
    -webkit-mask: url(../../../assets/images/icons/link_res_icon.svg) no-repeat center / 30px;
    mask: url(../../../assets/images/icons/link_res_icon.svg) no-repeat center / 30px;
  }

  &.-viewResource {
    -webkit-mask: url(../../../assets/images/icons/resource_details_ic.svg) no-repeat center / 20px;
    mask: url(../../../assets/images/icons/resource_details_ic.svg) no-repeat center / 20px;
  }

  &.-deleteFile {
    background-color: var(--primary-60);
    transform: rotate(45deg);
    -webkit-mask: url(../../../assets/images/icons/round-content-add-circle.svg) no-repeat center / 20px;
    mask: url(../../../assets/images/icons/round-content-add-circle.svg) no-repeat center / 20px;
  }

  &.-icResources {
    -webkit-mask: url(../../../assets/images/icons/resource_library_ic.svg) no-repeat center;
    mask: url(../../../assets/images/icons/resource_library_ic.svg) no-repeat center;
    background-color: var(--primary-60);
  }

  &.-cohMessageRead {
    -webkit-mask: url(../../../assets/images/icons/mark-email-read-fill-0-wght-400-grad-0-opsz-48.svg)
      no-repeat center;
    mask: url(../../../assets/images/icons/mark-email-read-fill-0-wght-400-grad-0-opsz-48.svg) no-repeat
      center;
  }

  &.-calendar {
    -webkit-mask: url(../../images/icons/round-action-calendar-primary.svg) no-repeat center;
    mask: url(../../images/icons/round-action-calendar-primary.svg) no-repeat center;
  }

  &.-cohMessageUnread {
    -webkit-mask: url(../../../assets/images/icons/mark-email-unread-fill-0-wght-400-grad-0-opsz-48.svg)
      no-repeat center;
    mask: url(../../../assets/images/icons/mark-email-unread-fill-0-wght-400-grad-0-opsz-48.svg) no-repeat
      center;
  }

  &.-lock {
    width: 24px;
    height: 24px;
    -webkit-mask: url(../../../assets/images/icons/round-action-lock-dark.svg) no-repeat center / 24px;
    mask: url(../../../assets/images/icons/round-action-lock-dark.svg) no-repeat center / 24px;
  }

  &.mobile {
    -webkit-mask: url(../../images/icons/mobile.svg) no-repeat center;
    mask: url(../../images/icons/mobile.svg) no-repeat center;
  }

  &.desktop {
    -webkit-mask: url(../../images/icons/desktop.svg) no-repeat center;
    mask: url(../../images/icons/desktop.svg) no-repeat center;
  }
  &.-filter {
    -webkit-mask: url(../../images/icons/filter.svg) no-repeat center;
    mask: url(../../images/icons/filter.svg) no-repeat center;
  }
  &.view {
    -webkit-mask: url(../../images/icons/view.svg) no-repeat center;
    mask: url(../../images/icons/view.svg) no-repeat center;
  }
  &.edit {
    -webkit-mask: url(../../images/icons/edit.svg) no-repeat center;
    mask: url(../../images/icons/edit.svg) no-repeat center;
  }
  &.dots {
    -webkit-mask: url(../../images/icons/dots.svg) no-repeat center;
    mask: url(../../images/icons/dots.svg) no-repeat center;
  }
  &.ea-report {
    -webkit-mask: url(../../images/icons/icon-equity-audit-report.svg) no-repeat center;
    mask: url(../../images/icons/icon-equity-audit-report.svg) no-repeat center;
  }
  &.resource-details {
    -webkit-mask: url(../../images/icons/resource_view_ic.svg) no-repeat center;
    mask: url(../../images/icons/resource_view_ic.svg) no-repeat center;
  }
  &.resource-view {
    -webkit-mask: url(../../images/icons/resource_details_ic.svg) no-repeat center;
    mask: url(../../images/icons/resource_details_ic.svg) no-repeat center;
  }
  &.download {
    -webkit-mask: url(../../images/icons/round-content-save-alt.svg) no-repeat center;
    mask: url(../../images/icons/round-content-save-alt.svg) no-repeat center;
  }
  &.show-hide-column {
    color: transparent;
    margin-top: 4px;
    -webkit-mask: url(../../images/icons/icons-columns.svg) no-repeat center;
    mask: url(../../images/icons/icons-columns.svg) no-repeat center;
  }
  &.-checkmark {
    -webkit-mask: url(../../images/icons/checkmark.svg) no-repeat center / 20px;
    mask: url(../../images/icons/checkmark.svg) no-repeat center / 20px;
  }
}

.b-link-icon-left {
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: 0.16px;
  color: var(--primary-60);
  display: flex;
  align-items: center;

  img {
    padding-right: 6px;
  }
}

.b-link-icon {
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: 0.16px;
  color: var(--primary-60);
  padding-left: 24px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: -2px;
    left: 0;
    width: 20px;
    height: 20px;
    background-color: var(--primary-60);
  }

  &.-right {
    padding-right: 24px;
    padding-left: 0;
    &:before {
      right: 0;
      left: auto;
    }
  }

  &.-delete {
    &:before {
      -webkit-mask: url(../../../assets/images/icons/delete.svg) no-repeat center / 20px;
      mask: url(../../../assets/images/icons/delete.svg) no-repeat center / 20px;
    }
  }

  &.-edit {
    &:before {
      -webkit-mask: url(../../../assets/images/icons/edit_icon.svg) no-repeat center / 20px;
      mask: url(../../../assets/images/icons/edit_icon.svg) no-repeat center / 20px;
    }
  }

  &.-group {
    &:before {
      -webkit-mask: url(../../../assets/images/icons/group.svg) no-repeat center / 25px;
      mask: url(../../../assets/images/icons/group.svg) no-repeat center / 25px;
    }
  }

  &.-youtube-link {
    &:before {
      -webkit-mask: url(../../../assets/images/icons/icons-video-play.svg) no-repeat center / 20px;
      mask: url(../../../assets/images/icons/icons-video-play.svg) no-repeat center / 20px;
    }
  }

  &.-openResource {
    &:before {
      -webkit-mask: url(../../../assets/images/icons/open-in-new-fill-0-wght-400-grad-0-opsz-48.svg) no-repeat
        center / 20px;
      mask: url(../../../assets/images/icons/open-in-new-fill-0-wght-400-grad-0-opsz-48.svg) no-repeat center /
        20px;
    }
  }
}

.b-link-add {
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: 0.16px;
  color: var(--primary-60);
  padding-left: 24px;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 16px;
    height: 16px;
    border-radius: 16px;
    background-color: var(--primary-60);
    background-position: center;
    background-image: url('../../../assets/images/icons/round-content-add.svg');
  }
}

.b-link {
  text-decoration: underline;
  color: var(--primary-60) !important;

  &.-no-underline {
    text-decoration: none;
  }
}

.b-button-empty {
  //color: transparent;
  //font-size: 0;
  //background: transparent;
}
