@import '../../../assets/styles/include-media';

.container {
  text-align: center;
  display: none;
  @include media('<desktop') {
    display: block;
  }
}

.bigTitle {
  color: var(--primary-60);
  font-size: 20px;
  line-height: 1.2;
  font-weight: 700;
  padding-bottom: 12px;
  display: block;
}

.simpleTitle {
  font-size: 16px;
  text-transform: uppercase;
  display: inline-flex;
  color: var(--gray-60);
  margin-right: 10px;

  &:after {
    content: '';
    opacity: 0.5;
    background-color: var(--gray-30);
    width: 1px;
    height: 16px;
    margin-left: 10px;
  }
}

.row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.boldTitle {
  font-size: 16px;
  color: var(--primary-navy);
  font-weight: bold;
  display: inline-flex;
  align-items: center;
}
