.b-typography {
  font-size: 16px;
  line-height: 1.25;
  color: var(--gray-60);

  p {
    margin-bottom: 15px;
  }

  ul {
    list-style-type: disc;
    margin-left: 35px;
  }
}

.b-editorText {
  div {
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
    float: none !important;
  }

  div,
  p {
    background-color: transparent !important;
    color: var(--gray-60) !important;
  }

  hr {
    display: none;
  }

  a {
    color: var(--primary-60);
  }
  ul {
    list-style-type: disc;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    padding-left: 40px;
  }
}
