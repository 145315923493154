.b-avatar {
  width: 64px;
  height: 64px;
  background-color: var(--user-avatar);
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-shrink: 0;
  border: 4px solid var(--white-black);
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  color: var(--user-username);
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;

  @include media('<tablet') {
    font-size: 14px;
    width: 36px;
    height: 36px;
  }

  img {
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &.-mr {
    margin-right: 20px;
  }

  &.-w128 {
    width: 128px;
    height: 128px;

    @include media('<tablet') {
      font-size: 24px;
      width: 128px;
      height: 128px;
    }
  }

  &__profile {
    .b-avatar {
      @include media('<tablet') {
        font-size: 14px;
        width: 100px;
        height: 100px;
        margin-bottom: 15px;
      }
    }
  }
}

.b-imageUploader {
  display: flex;
  align-items: center;
  margin-bottom: 32px;

  .b-avatar {
    margin-right: 20px;
  }
}

.b-avatarZoomedModal {
  width: 100%;
  height: 100%;
  display: flex;
  margin-top: 60px;
  align-items: center;
  justify-content: center;

  @include media('<tablet') {
    height: auto;
    margin-top: 40%;
  }

  &__photo {
    background-color: var(--white-black);
    border: 4px solid var(--white-black);
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
    width: 200px;
    height: 200px;
    border-radius: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    img {
      width: 100%;
    }
  }
}

.b-imageUploader {
  &.-cohort {
    flex-wrap: wrap;
    justify-content: center;
  }
  &.-org-profile {
    margin: -14px 0 0 30px;
    height: 61px;
    display: flex;
  }
}
.b-avatar {
  &.-cohort {
    margin-right: 0;
    width: 103px;
    height: 103px;
    border-radius: 8px;
    text-transform: initial;
    text-align: center;
    box-shadow: 0 0 9px 1px rgba(0, 0, 0, 0.5);
    margin-bottom: 18px;
    background: transparent;
  }
  &.-org-profile {
    width: 100px;
    height: 53px;
    border-radius: 8px;
    box-shadow: 0 0 9px 1px rgba(0, 0, 0, 0.5);
    background: transparent;
  }
}
