.b-marketingPanel {
  &__imgMain {
    width: 120px;
    display: block;
    margin: 0 auto 10px;
  }

  &__text {
    text-align: center;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 20px;
  }
}
