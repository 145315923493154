.container {
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
  background-color: var(--white-gray-80);
  color: var(--gray-60);
  font-size: 12px;
}
.ReactTooltip {
  font-size: 20px !important;
  pointer-events: auto !important;
  opacity: 1 !important;
  padding: 0 !important;
  border: none !important;
  margin: 0 !important;
  background: transparent !important;

  &:hover {
    visibility: visible !important;
    opacity: 1 !important;
  }
  &:before,
  &:after {
    display: none;
  }
}
