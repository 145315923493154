// b-equityAuditsDetails
.b-equityAuditsDetails {
  &__elmMetricInput {
    width: 135px !important;
    min-width: auto;
  }
  &__formSubRow {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 32px;
  }
  &__formRow {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 32px;
    margin-bottom: 15px;

    @include media('<tablet') {
      grid-template-columns: 1fr;
      gap: 0;
    }
  }
  &__formTitle {
    color: var(--primary-navy);
    font-family: Rubik;
    font-size: 16px;
    font-weight: bold;
    margin: 0 0 32px;
  }

  // additional
  &__additional {
    display: flex;
    max-width: 730px;

    @include media('<tablet') {
      flex-direction: column;
    }
  }
  &__additionalCol {
    width: 50%;
    padding-right: 30px;

    @include media('<tablet') {
      width: 100%;
      padding-right: 0;
      padding-bottom: 10px;
    }
  }
  &__suborganization_list {
    li {
      padding: 15px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      a {
        color: inherit;

        &.-deleteBtn {
          position: relative;
          right: -8px;
          display: block;
          width: 24px;
          height: 24px;
          background-image: url('../../../assets/images/icons/delete.svg');
        }
      }
    }
  }

  &__pmInput {
    position: relative;
  }

  &__pmInputControl {
    width: 24px;
    height: 24px;
    position: relative;
    cursor: pointer;
    z-index: 2;
    background-color: var(--primary-navy) !important;

    &.-user {
      margin-left: 5px;
      -webkit-mask: url(../../images/icons/round-action-account-circle-black.svg) no-repeat center;
      mask: url(../../images/icons/round-action-account-circle-black.svg) no-repeat center;
    }

    &.-edit {
      -webkit-mask: url(../../images/icons/round-image-edit.svg) no-repeat center;
      mask: url(../../images/icons/round-image-edit.svg) no-repeat center;
      mask-size: 20px 20px;
    }
  }

  &__pmInputControls {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 9px;
    top: 12px;

    a {
      color: var(--primary-60);
      font-size: 14px;
      position: absolute;
      right: 0;
      white-space: nowrap;
      bottom: -15px;
      z-index: 1;
    }
  }
  &__lockWrap {
    position: relative;

    & .-lock {
      position: absolute;
      right: 25px;
      top: 17px;
      z-index: 1;
      -webkit-mask: url(../../images/icons/lock.svg) no-repeat center;
      mask: url(../../images/icons/lock.svg) no-repeat center;
      mask-size: 20px 20px;
      height: 20px;
      width: 20px;
      background-color: var(--primary-60);
    }
  }
  &__modalLock {
    p {
      padding: 0 50px;
      color: var(--gray-60);
      line-height: 1.5;
      letter-spacing: 0.15px;
    }
  }
}
