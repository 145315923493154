.b-elmMyReportItem {
  display: flex;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 25px 30px 20px 20px;
  margin-bottom: 12px;

  @include media('<tablet') {
    display: block;
  }

  &__text {
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 0.16px;
    color: var(--gray-60);
    margin-left: 33px;

    @include media('<tablet') {
      margin-left: 0;
    }
  }

  &__info {
    width: 168px;
    flex-shrink: 0;
    @include media('<tablet') {
      margin-bottom: 20px;
      width: 100%;
    }
  }

  &__name {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    color: var(--gray-60);
  }

  &__nameWrapper {
    display: flex;
    margin-bottom: 12px;
  }

  &__sband {
    border-radius: 8px;
    width: 140px;
    height: 43px;
    display: flex;
    margin-left: 28px;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
    line-height: 1;
    letter-spacing: 0.23px;
    text-align: center;
    color: var(--primary-60);

    @include media('<tablet') {
      margin-left: 0;
    }

    &.-mid {
      color: var(--primary-60);
      background-color: rgba(255, 144, 21, 0.2);
    }

    &.-dev {
      background-color: rgba(255, 232, 0, 0.2);
      color: var(--primay-yellow);
    }

    &.-high {
      background-color: rgba(16, 156, 84, 0.2);
      color: var(--green-60);
    }
  }

  &__count {
    position: relative;
    top: 2px;
    margin-right: 8px;
    width: 20px;
    flex-shrink: 0;
    height: 20px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primary-60);
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    color: var(--white-black);
  }
}
