.title_wrapper {
  margin-bottom: 30px !important;
}
.container {
  @media screen and (max-width: 1366px) {
    display: block !important;
  }
}
.content {
  overflow: auto;
  width: 100%;
}

.checkBox {
  input {
    margin-top: -1px;
    margin-right: 10px;
  }
}
