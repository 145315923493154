.input_wrapper {
  position: relative;
  color: var(--gray-60);

  @media screen and (max-width: 824px) {
    width: 314px;
  }
}

.input_placeholder_block {
  display: flex;
  justify-content: center;
  flex-direction: column;

  &:before {
    content: attr(data-content);
    position: absolute;
    font-family: Rubik;
    top: -7px;
    left: 6px;
    display: flex;
    padding: 0 5px;
    font-size: 12px;
    color: var(--gray-60);
    white-space: nowrap;
    z-index: 9;
    background: var(--background);
  }
}

.input {
  min-width: 314px;
  height: 48px;
  font-size: 16px;
  border: 1px solid var(--gray-30);
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  color: var(--gray-60);
  background: var(--background);

  &_disable {
    //pointer-events: none;
    //opacity: 0.5;
    background-color: inherit;
  }

  @media screen and (max-width: 824px) {
    min-width: 280px;
  }
}

@-webkit-keyframes autofill {
  0%,
  100% {
    background: transparent !important;
  }
}

input:-webkit-autofill {
  -webkit-animation-delay: 1s; /* Safari support - any positive time runs instantly */
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}

.password__show {
  position: absolute;
  right: 0px;
  top: 7px;
  padding: 6px;
}

.input_error {
  border: 1px solid var(--rad);
  color: var(--rad);
}

.input_focus {
  box-shadow: 0 0 0 1px var(--primary-60);

  &:focus {
    outline: none;
    border: 1px solid var(--primary-60);
  }
}

.input_wrapper_error {
  color: var(--rad);
}

.input_label_focus {
  &:before {
    color: var(--primary-60);
  }
}

.textarea {
  min-height: 68px;
  min-width: 342px;
  box-sizing: border-box;
  font-family: Rubik;
  padding-top: 10px;

  @media screen and (max-width: 824px) {
    min-width: 314px;
  }
}
