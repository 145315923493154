.b-customChart {
  width: 715px;
  height: 100%;
  border-radius: 16px;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
  background-color: var(--panel-background);
  display: flex;
  padding: 40px 36px 40px 28px;
  flex-direction: column;
  margin-top: 15px;

  .Diversity {
    background-color: var(--primary-80);
  }
  .Equity {
    background-color: var(--primary-60);
  }
  .Inclusion {
    background-color: var(--primay-50);
  }

  &__lines {
    width: 100%;
    height: 100%;
    margin-top: 30px;
    padding-top: 10px;
    position: relative;
    border-top: 1px solid var(--bd-color2);
    background: linear-gradient(
      to right,
      var(--chartBg1) 10%,
      var(--chartBg2) 10% 20%,
      var(--chartBg1) 20% 30%,
      var(--chartBg2) 30% 40%,
      var(--chartBg1) 40% 50%,
      var(--chartBg2) 50% 60%,
      var(--chartBg1) 60% 70%,
      var(--chartBg2) 70% 80%,
      var(--chartBg1) 80% 90%,
      var(--chartBg2) 90% 100%
    );

    &.-heightAuto {
      height: auto;
    }
  }

  &__percentage {
    display: flex;
    justify-content: space-between;
    color: var(--gray-60);
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    position: relative;

    span {
      width: 32px;
      position: absolute;
      transform: translateX(-50%);
      font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    }
  }

  &__graph {
    width: 100%;
    user-select: none;
  }

  &__chart {
    width: 100%;
    display: flex;
  }

  &__labelContainer {
    position: relative;
    height: 45px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:hover {
      .b-customChart__labelHover {
        left: 0;
      }
    }
  }

  &__labelHover {
    width: 200%;
    left: -200vw;
    top: 40px;
    z-index: 10;
    position: absolute;
  }

  &__labelHoverContent {
    position: absolute;
    top: 0;
    left: 0;
    padding: 8px;
    background: var(--panel-background);
  }

  &__labels {
    max-width: 300px;
    margin-top: 40px;
    position: relative;

    &.-w300 {
      width: 300px;
    }

    &__title {
      display: flex;
      justify-content: flex-end;
      padding-right: 20px;
      color: var(--primay-50);
      font-size: 12px;
      position: absolute;
      right: 0;
      top: -15px;
      font-weight: bold;
    }

    ul {
      padding-right: 20px;
    }

    li,
    .list {
      user-select: none;
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: var(--primary-navy);

      .arrow {
        position: absolute;
        right: -3px;
        -webkit-mask: url(../../images/icons/round-navigation-arrow-drop-up.svg) no-repeat center;
        mask: url(../../images/icons/round-navigation-arrow-drop-up.svg) no-repeat center;
        width: 24px;
        height: 24px;
        z-index: 1;
        top: 5px;
        background-color: var(--primary-60);

        &.-close {
          transform: rotate(-180deg) !important;
        }
      }

      .elem {
        display: flex;
        height: 41px;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 1.1;
        flex-direction: column;
        justify-content: center;
        padding-top: 2px;
        padding-bottom: 2px;
        font-size: 12px;
      }
    }

    &__children {
      color: var(--gray-60) !important;
      font-size: 12px !important;
    }
  }

  &__empty {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.24px;
    color: var(--gray-60);
    z-index: 1;
    width: 100%;
    text-align: center;
  }

  &__legend {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 10px;
    user-select: none;
  }

  &__legendGeneralTitle {
    display: flex;
    justify-content: center;
    color: var(--primay-50);
    font-weight: bold;
    margin-top: 60px;
    font-size: 12px;
  }

  &__legendTitle {
    display: flex;
    align-items: center;
    margin: 0 15px;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--gray-60);

    &:last-child {
      margin-right: 0;
    }
  }

  &__legendDot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 10px;

    &.-complete {
      background-color: var(--primary-80);
    }
    &.-in_progress {
      background-color: var(--primary-70);
    }
    &.-not_yet_started {
      background-color: var(--primary-60);
    }
    &.-stalled {
      background-color: var(--primay-50);
    }
    &.-no_longer_pursuing {
      background-color: var(--gray-10);
    }
  }

  &__tooltip {
    display: block;
    width: 100%;
    height: 100%;
    font-size: 14px;
    padding: 6px;
  }

  &__line {
    height: 45px;
    display: flex;
    position: relative;
    align-items: center;
  }

  &__doubleLine {
    height: 45px;
    display: flex;
    position: relative;
    justify-content: center;
    flex-direction: column;
    .b-customChart__score {
      margin: 5px 0;
    }
  }

  &__dot {
    position: absolute;
    transform: translate(-50%, -50%);
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent !important;
    top: 50%;
    cursor: pointer;
    z-index: 2;

    &.Diversity {
      &:before {
        background-color: var(--primary-80);
      }
    }
    &.Equity {
      &:before {
        background-color: var(--primary-60);
      }
    }
    &.Inclusion {
      &:before {
        background-color: var(--primay-50);
      }
    }

    &:before {
      content: '';
      border-radius: 50%;
      width: 8px;
      height: 8px;
      transition: transform 0.3s;
    }

    &:hover {
      &:before {
        transform: translateY(50%) scale(1.3);
      }
    }
  }

  &__band {
    height: 4px;
    border-radius: 4px;
    background-color: var(--bd-color2);
    z-index: 1;
    position: absolute;
  }

  &__score {
    height: 6px;
    border-radius: 0 4px 4px 0;
    background-color: var(--orange);
    z-index: 1;
    position: relative;

    &-val {
      top: -5px;
      position: absolute;
      right: -30px;
      font-size: 12px;
      color: var(--gray-60);
    }
    &.-global {
      background-color: #ccc;
      height: 0;
      opacity: 0;
      -webkit-transition: all 300ms ease;
      transition: all 300ms ease;
      &.-isCompare {
        height: 6px;
        opacity: 1;
        -webkit-transition: all 300ms ease;
        transition: all 300ms ease;
      }
    }
  }

  &__multilineBlock {
    display: flex;
    flex-direction: column;
    height: 100px;
    position: relative;
    margin-bottom: 26px;
  }
  &__boldLine {
    height: 6px;
    border-radius: 4px;
    background-color: var(--bd-color2);
    z-index: 1;
    margin: 7px 0;
    position: relative;
    cursor: pointer;

    .percent {
      position: absolute;
      right: -33px;
      top: -5px;
      font-size: 12px;
      font-weight: bold;
      color: var(--gray-60-10);
    }

    &.-complete {
      background-color: var(--primary-80);
    }
    &.-in_progress {
      background-color: var(--primary-70);
    }
    &.-not_yet_started {
      background-color: var(--primary-60);
    }
    &.-stalled {
      background-color: var(--primay-50);
    }
    &.-no_longer_pursuing {
      background-color: var(--gray-10);
    }
  }
}
