.container {
  max-width: 327px;
  margin: 30px;
  text-align: center;
  line-height: 1.5;
}

.button {
  width: 148px;
  border-radius: 16px;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: var(--white);
}

.title {
  font-size: 20px;
}
