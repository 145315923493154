.b-filtersModal {
  display: flex;
  padding: 0 60px;
  margin-top: 40px;

  @include media('<desktop') {
    flex-direction: column;

    &__filter {
      margin-top: 24px;
    }
    ul {
      margin-left: 30px;
    }
  }

  &__modalTitle {
    padding-top: 60px;
  }

  &__filterScrollable {
    height: calc(100vh - 405px);
    overflow: auto;
    width: 100%;
    border-bottom: 1px solid var(--gray-20);
  }

  &__list {
    width: 250px;

    @include media('<desktop') {
      margin-bottom: 25px;
    }
    &:first-child {
      margin-right: 60px;
    }

    &_fullWidth {
      width: 100%;
      margin-right: 0 !important;
    }

    ul {
      color: var(--gray-60);

      > li {
        > span {
          display: block;
          font-size: 16px;
          margin: 7px 0;
          padding: 7px 0;
        }
        > ul {
          margin-left: 20px;
        }
      }

      a {
        display: block;
        font-size: 16px;
        padding: 7px 10px;
        border-radius: 15px;
        cursor: pointer;
        color: var(--gray-60);
        margin: 7px 0 7px -10px;
        user-select: none;

        &.-active {
          color: var(--primary-60) !important;
        }

        &:hover {
          @include media('>tablet') {
            background-color: var(--chartBg2);
          }
        }
      }
    }
  }

  &__title {
    font-size: 20px;
    font-weight: bold;
    color: var(--primary-navy);
    margin-bottom: 24px;
  }

  &_active {
    color: var(--primary-60) !important;
  }

  &__buttons {
    display: flex;
    padding: 20px 0 50px;
    justify-content: center;
  }

  &__mobileModal {
    min-height: auto;
  }
}
