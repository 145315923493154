.b-calendar {
  &__header {
    width: 100%;
  }

  &__pageSubTitle {
    position: relative;
    font-size: 20px;
    font-weight: 700;
    margin: 0 0 53px;
    color: var(--primary-navy);

    @include media('<widescreen') {
      display: flex;
    }

    @include media('<tablet') {
      color: var(--gray-60);

      span {
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        text-align: center;
      }
    }
  }
  &__backBtn {
    position: absolute;
    left: -42px;
    top: 0;
    cursor: pointer;

    .back-icon {
      width: 24px;
      height: 24px;
      background-color: var(--primary-navy);
      -webkit-mask: url('../../images/icons/round-navigation-arrow-back.svg') no-repeat center;
      mask: url('../../images/icons/round-navigation-arrow-back.svg') no-repeat center;
    }

    @include media('<widescreen') {
      position: static;
      margin-right: 8px;
    }
  }

  &__content {
  }
}
