.container {
  width: 430px;
  box-sizing: border-box;
  padding: 18px 18px 29px;
  cursor: pointer;
}

.left {
  width: 100%;
}

.organizations {
  display: flex;
  flex-direction: column;
  span {
    margin: 9px 0;
  }
}

.header {
  display: block;
  margin-bottom: 0;
}
