.footer {
  flex-direction: column;
  display: flex;
  align-content: flex-end;
  justify-content: flex-end;
  width: 100%;
  height: 80px;
  position: relative;
  background: url('../../../assets/images/icons/triangle.svg') no-repeat;
  background-size: cover;
  &__line {
    height: 47px;
    background-color: #000037;
    width: 100%;
    position: absolute;
  }
}
@media screen and (max-width: 1110px) {
  .footer {
    background: url('../../../assets/images/icons/triangle.svg') no-repeat bottom;
    background-size: contain;
  }
}
@media screen and (max-width: 824px) {
  .footer {
    background: none;
  }
  .footer {
    height: 10px;

    &__line {
      display: none;
    }
  }
}
