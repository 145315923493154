.b-myProfile {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  @include media('<tablet') {
    grid-template-columns: 1fr;
  }

  &.-column {
    grid-template-columns: 1fr;
  }

  .content-left {
    margin-left: 0;
  }
  .container-row {
    justify-content: flex-start;
    align-items: center;
  }
  .text-long-description {
    font: 400 16px/1.5 Rubik;
    color: var(--gray-60);
    margin-left: 8px;
  }

  .check-box-container {
    margin-bottom: 16px;
  }
  .checkbox-text {
    font: 400 14px/1.71 Rubik;
    color: var(--gray-60);
    margin-left: 8px;
  }
}
