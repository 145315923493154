@import '../../../assets/styles/include-media';

.inputContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include media('<tablet') {
    display: block;
  }
}

.container {
  position: relative;
  //margin-bottom: 10px;
  @include media('<tablet') {
    //margin-bottom: 20px;
    margin-right: 5px;
  }
}

.file {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  color: var(--gray-60);
  margin-right: 45px;
}

.fileClose {
  background-color: var(--background);
  margin-left: 20px;
  width: 20px;
  height: 20px;
  background-position: center;
  background-image: url('../../../assets/images/icons/round-navigation-close.svg');
}

.error {
  @include media('<tablet') {
  }
}
