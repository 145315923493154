@import '../../../assets/styles/include-media';

.Sidebar {
  width: 330px;
  max-height: 100vh;
  box-sizing: border-box;
  min-width: 330px;
}

.Sidebar_inner {
  border-right: solid 1px rgba(0, 0, 0, 0.06);
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  padding: 0 34px 34px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 330px;
  height: 100vh;
  box-sizing: border-box;
  overflow: auto;
  background: var(--background);
}

.Logo {
  margin-top: 58px;
  margin-bottom: 46px;
  display: flex;
  justify-content: center;
}

.copy {
  font-family: Rubik;
  font-size: 12px;
  color: var(--primary-navy);
  margin-top: auto;
}

.UserPanel {
  padding: 22px 25px;
  display: flex;
  border-radius: 16px;
  background-color: rgba(0, 0, 0, 0.05);
  align-items: center;
  margin-bottom: 35px;

  &__name {
    font-family: Rubik;
    font-size: 14px;
    font-weight: bold;
    color: var(--user-username-title);
    padding-bottom: 8px;

    a {
      color: inherit;
    }
  }

  &__logout {
    display: block;
    font-family: Rubik;
    font-size: 12px;
    color: var(--user-username-title);
    text-decoration: underline;

    &:hover {
      color: var(--primary-navy);
    }
  }
}

.Menu {
  padding-left: 23px;
  margin-bottom: 30px;
  &__item {
    display: flex;
    align-items: center;
    padding: 18px 0;

    &__calendar {
      padding-top: 6px;
    }

    &:hover {
      .Menu__text {
        color: var(--primary-60);
      }

      .Menu__ic {
        background-color: var(--primary-60);
      }
    }
  }

  &__item_active {
    .Menu__text {
      color: var(--primary-60);
    }

    .Menu__ic {
      background-color: var(--primary-60);
    }
  }

  &__item_disabled {
    pointer-events: none;
    opacity: 0.4;
  }

  &__ic_home {
    -webkit-mask: url(../../../assets/images/icons/round-action-home.svg) no-repeat center;
    mask: url(../../../assets/images/icons/round-action-home.svg) no-repeat center;
  }

  &__ic_calendar {
    -webkit-mask: url(../../../assets/images/icons/round-action-calendar-today.svg) no-repeat center;
    mask: url(../../../assets/images/icons/round-action-calendar-today.svg) no-repeat center;
  }

  &__ic_dashboard {
    -webkit-mask: url(../../../assets/images/icons/round-action-dashboard.svg) no-repeat center;
    mask: url(../../../assets/images/icons/round-action-dashboard.svg) no-repeat center;
  }

  &__ic_projects {
    -webkit-mask: url(../../../assets/images/icons/round-file-folder.svg) no-repeat center;
    mask: url(../../../assets/images/icons/round-file-folder.svg) no-repeat center;
  }

  &__ic_auditManagement {
    -webkit-mask: url(../../../assets/images/icons/round-communication-list-alt.svg) no-repeat center;
    mask: url(../../../assets/images/icons/round-communication-list-alt.svg) no-repeat center;
  }

  &__ic_group {
    -webkit-mask: url(../../../assets/images/icons/group.svg) no-repeat center;
    mask: url(../../../assets/images/icons/group.svg) no-repeat center;
  }
  &__ic_myprojects {
    -webkit-mask: url(../../../assets/images/icons/round-action-view-list.svg) no-repeat center;
    mask: url(../../../assets/images/icons/round-action-view-list.svg) no-repeat center;
  }
  &__ic_adminTool {
    -webkit-mask: url(../../../assets/images/icons/icon-action-settings.svg) no-repeat center;
    mask: url(../../../assets/images/icons/icon-action-settings.svg) no-repeat center;
  }

  &__ic_mail {
    -webkit-mask: url(../../../assets/images/icons/mail-icon.svg) no-repeat center;
    mask: url(../../../assets/images/icons/mail-icon.svg) no-repeat center;
  }

  &__ic_resources {
    -webkit-mask: url(../../../assets/images/icons/resource_library_ic.svg) no-repeat center;
    mask: url(../../../assets/images/icons/resource_library_ic.svg) no-repeat center;
  }

  &__ic_belovedTeam {
    -webkit-mask: url(../../../assets/images/icons/beloved-team-ic.svg) no-repeat center;
    mask: url(../../../assets/images/icons/beloved-team-ic.svg) no-repeat center;
  }
  &__ic_belovedCredit {
    -webkit-mask: url(../../../assets/images/icons/icon-beloved-credit.svg) no-repeat center;
    mask: url(../../../assets/images/icons/icon-beloved-credit.svg) no-repeat center;
  }

  &__ic {
    margin-right: 16px;
    width: 16px;
    height: 16px;
    background-color: var(--primary-navy);
  }

  &__text {
    font-family: Rubik;
    font-size: 14px;
    font-weight: bold;
    position: relative;
    top: 1px;
    color: var(--primary-navy);
  }

  &__subItem {
    padding: 12px 0;
    display: inline-block;
    margin-left: 33px;
    width: 100%;

    &:hover {
      .Menu__text {
        color: var(--primary-60);
      }
    }
  }

  &__subItem_active {
    .Menu__text {
      color: var(--primary-60);
    }
  }

  &__subItem_last {
    padding-bottom: 0;
  }

  &__parentItem {
    padding: 18px 0;

    &_task {
      padding: 6px 0 0 0;
    }
  }

  &__parentItemTitle {
    padding-bottom: 12px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &_noHover {
      .Menu__text {
        color: var(--primary-navy) !important;
      }
      .Menu__ic {
        background-color: var(--primary-navy) !important;
      }
    }

    &:hover {
      .Menu__text {
        color: var(--primary-60);
      }
      .Menu__ic {
        background-color: var(--primary-60);
      }
    }
  }
}

// mobile header styles
.Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  position: relative;
  //z-index: 10;
}

.navOpener {
  width: 20px;
  height: 14px;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
  background: transparent;
  padding: 0;

  &:before,
  &:after,
  span {
    background-color: var(--primary-navy);
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 2px;
    margin-top: -1px; /* height/2 */
  }
  &:before,
  &:after {
    content: '';
    top: 0;
    margin: 0;
  }
  &:after {
    top: auto;
    right: 10px;
    bottom: 0;
    margin: 0;
  }
  span {
    right: 4px;
  }
}

.navClose {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  overflow: hidden;
  background: transparent;
  padding: 0;
  position: absolute;
  right: 23px;
  top: 32px;

  &__wrapper {
    width: 100vw;
    position: fixed;
    top: 0;
    z-index: 200;
    left: 0;
    height: 100px;
    //background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 70%, rgba(255,255,255,0) 100%);
  }

  &:before,
  &:after {
    content: '';
    background-color: var(--primary-navy);
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 2px;
    width: 13px;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
}

.mobileNav {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: var(--background);
  padding: 100px 60px;
  z-index: 100;
  display: flex;
  flex-direction: column;
  overflow: auto;

  &_heading {
    padding: 30px 60px;
  }

  .Menu {
    padding-left: 0;
    &__item {
      padding: 24px 0;
      &__calendar {
        padding-top: 8px;
      }
    }
    &__subItem {
      padding: 16px 0;
    }
    &__parentItem {
      padding: 24px 0;

      &_task {
        padding: 6px 0 0 0;
      }
    }
  }
}
