.b-page {
  padding: 58px 63px;
  flex-grow: 1;
  min-width: 1px;
  background: var(--background);
  overflow-x: hidden;
  @include media('<widescreen') {
    padding-left: 30px;
    padding-right: 30px;
  }

  @include media('<tablet') {
    padding: 30px 20px 40px 20px;
  }

  &__730 {
    max-width: 730px;

    &.pt {
      padding-top: 30px;
    }

    div[class*='Input_input_wrapper'] {
      width: 100%;
    }
  }

  &__title2 {
    position: relative;
    display: block;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 30px;

    &.-backIcon {
      padding-left: 42px;
    }

    &.-mb0 {
      margin-bottom: 0;
    }

    .b-button-icon-back {
      top: 0;
      left: 0;
    }
  }

  &__title {
    position: relative;
    display: block;
    font-size: 24px;
    font-weight: bold;
    color: var(--primary-navy);
    margin-bottom: 35px;

    @include media('<tablet') {
      text-align: center;
      color: var(--gray-60);
      font-size: 20px;
      margin-bottom: 15px;
      box-sizing: border-box;
      padding: 0 20px;
      width: 100%;
    }

    &.-withTabs {
      @include media('<tablet') {
        margin-bottom: 30px;
      }
    }

    &.-noMb {
      margin-bottom: 0;
    }

    &.-noDesktopMb {
      margin-bottom: 0;
      @include media('<tablet') {
        margin-bottom: inherit;
      }
    }

    .mark {
      color: var(--primary-60);
      margin-left: 8px;
    }
  }

  &__controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    @include media('<desktop') {
      flex-direction: column-reverse;
    }

    &.-mobHorizontal {
      @include media('<desktop') {
        flex-direction: row;
        justify-content: center;
      }
    }
  }

  &__search {
    width: 340px;

    @include media('<desktop') {
      width: 100%;
      margin-bottom: 25px;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 35px;
    position: relative;

    @include media('<widescreen') {
      flex-wrap: wrap;
      row-gap: 20px;
    }

    @include media('<tablet') {
      display: flex;
      flex-direction: column;
    }

    &.-mobReverse {
      @include media('<tablet') {
        flex-direction: column-reverse;
      }
    }
  }

  &__headerBtns {
    display: flex;
    align-items: center;
    height: 28px;

    @include media('<desktop') {
      position: relative;
    }
  }

  &__cards {
    margin-top: 60px;
    padding-bottom: 60px;
    @include media('<desktop') {
      margin-top: 40px;
    }
  }

  &__cardItem {
    width: 100% !important;
    max-width: calc(50% - 10px) !important;
    margin-bottom: 20px !important;
    margin-right: 0 !important;

    @media screen and (min-width: 1360px) {
      max-width: 490px !important;
      margin-bottom: 0px !important;
    }
    @include media('<tablet') {
      max-width: 100% !important;
    }
  }

  &__cardsList {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media screen and (min-width: 1360px) {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
      gap: 30px;
    }

    @include media('<desktop') {
      margin-bottom: 40px;
    }
  }
}

.b-infScrollWrapper {
  margin: -40px;

  @include media('<tablet') {
    margin: -40px -20px;
  }

  &__normalize {
    margin: 40px;
    @include media('<tablet') {
      margin: 40px 20px;
    }
  }
}
