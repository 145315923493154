.b-rdgTable {
  width: 100%;
  border: none !important;
  span,
  div {
    color: var(--gray-60);
    font-size: 12px;
    outline: none;
  }

  &__rowRed {
    border: 1px solid rgba(255, 0, 0, 1) !important;
    box-shadow: 0px 0px 0px 1px rgba(255, 0, 0, 1) inset;
  }

  &__cellBase {
    height: 100%;
    flex-grow: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    contain: size layout style paint;
    overflow: clip;
  }

  &__cellCenter {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  &__cellEnd {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
  }

  &__cellTextWrap {
    text-overflow: clip;
    white-space: inherit;
    line-height: 1.43;
    display: flex;
    align-items: center;
  }

  &__headerCell {
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 11px !important;
    font-weight: 400 !important;
    line-height: 1.8;
    letter-spacing: 0.2px;
    color: var(--gray-60);

    &.-clickable {
      cursor: pointer;
    }
  }
}

.b-belTable {
  &__row {
    cursor: pointer;
  }
}

.rdg {
  .r1otpg64700-beta7[aria-selected='true'] {
    background-color: rgba(255, 144, 21, 0.11);
  }
  &-header-row {
    background-color: var(--background);
  }
  &-cell {
    border: none;
  }
  &-row {
    border: 1px solid transparent;
    background-color: var(--table-row-color);
    &:hover {
      background-color: var(--table-row-color-hover);
    }
  }
  &-cell[aria-selected='true'] {
    box-shadow: inherit;
  }
  &-header-sort-name {
    font-size: 10px !important;
    font-weight: normal;
    letter-spacing: 0.2px;
  }
  .check-box-container {
    width: auto;
    box-sizing: content-box;
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
  }
  .checkBox {
    height: 100%;
  }
}
.b-tableArrow {
  display: inline-block;
  border-style: solid;
  border-width: 0 4px 5px 4px;
  border-color: transparent transparent var(--gray-60) transparent;
  margin-left: 7px;

  &.-reverse {
    transform: rotate(180deg);
  }

  &.-hide {
    opacity: 0;
  }
}
