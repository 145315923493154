.b-tabs {
  display: flex;
  align-items: center;
  width: 100%;
  overflow-x: auto;
  padding-bottom: 10px;

  &__item {
    position: relative;
    display: flex;
    align-items: center;
    padding-bottom: 8px;
    margin-right: 30px;
    border-bottom: 3px solid transparent;

    &.-mark {
      &:before {
        content: '';
        position: absolute;
        width: 6px;
        height: 6px;
        border-radius: 6px;
        background: var(--primary-80);
        top: 7px;
        right: -12px;
      }
    }

    &:hover {
      .b-tabs__icon {
        background-color: var(--primary-60);
      }

      .b-tabs__text {
        color: var(--primary-60);
      }
    }
  }

  &__item_disabled {
    pointer-events: none;
    opacity: 0.7;
  }

  &__item_active {
    border-bottom: 3px solid var(--primary-60);

    .b-tabs__icon {
      background-color: var(--primary-60);
    }

    .b-tabs__text {
      color: var(--primary-60);
      font-weight: bold;
    }
  }

  &__text {
    font-family: Rubik;
    font-size: 14px;
    line-height: 1.43;
    white-space: nowrap;
    letter-spacing: 0.16px;
    color: var(--gray-30);
  }

  &__icon {
    margin-right: 8px;
    width: 20px;
    height: 20px;
    background-color: var(--gray-30);
  }

  &__icon_users {
    -webkit-mask: url(../../../assets/images/icons/icon-user-list.svg) no-repeat center;
    mask: url(../../../assets/images/icons/icon-user-list.svg) no-repeat center;
  }
  &__icon_beloved {
    -webkit-mask: url(../../../assets/images/icons/round-action-beloved.svg) no-repeat center;
    mask: url(../../../assets/images/icons/round-action-beloved.svg) no-repeat center;
  }

  &__icon_settings {
    -webkit-mask: url(../../../assets/images/icons/round-action-settings.svg) no-repeat center;
    mask: url(../../../assets/images/icons/round-action-settings.svg) no-repeat center;
  }

  &__icon_dashboard {
    -webkit-mask: url(../../../assets/images/icons/round-action-dashboard.svg) no-repeat center;
    mask: url(../../../assets/images/icons/round-action-dashboard.svg) no-repeat center;
  }
  &__icon_organization {
    -webkit-mask: url(../../images/icons/icon-organization.svg) no-repeat center;
    mask: url(../../images/icons/icon-organization.svg) no-repeat center;
  }
  &__icon_map {
    -webkit-mask: url(../../images/icons/icon-map.svg) no-repeat center;
    mask: url(../../images/icons/icon-map.svg) no-repeat center;
  }
  &__icon_beloved_credit {
    -webkit-mask: url(../../images/icons/icon-beloved-credit.svg) no-repeat center;
    mask: url(../../images/icons/icon-beloved-credit.svg) no-repeat center;
  }
  &__icon_equility {
    -webkit-mask: url(../../images/icons/ea.svg) no-repeat center;
    mask: url(../../images/icons/ea.svg) no-repeat center;
  }

  &__icon_map {
    -webkit-mask: url(../../images/icons/round-maps-place.svg) no-repeat center;
    mask: url(../../images/icons/round-maps-place.svg) no-repeat center;
  }

  &__icon_table {
    -webkit-mask: url(../../images/icons/table_view_ic.svg) no-repeat center;
    mask: url(../../images/icons/table_view_ic.svg) no-repeat center;
  }

  &__icon_card {
    -webkit-mask: url(../../images/icons/cards_view_ic.svg) no-repeat center;
    mask: url(../../images/icons/cards_view_ic.svg) no-repeat center;
  }

  &__icon_ea_reports {
    -webkit-mask: url(../../images/icons/icon-equity-audit-report.svg) no-repeat center;
    mask: url(../../images/icons/icon-equity-audit-report.svg) no-repeat center;
  }

  &__icon_elm_reports {
    -webkit-mask: url(../../images/icons/bel-ea-report-icon.svg) no-repeat center;
    mask: url(../../images/icons/bel-ea-report-icon.svg) no-repeat center;
  }

  &__icon_ewp_details {
    -webkit-mask: url(../../images/icons/ewp/1.svg) no-repeat center;
    mask: url(../../images/icons/ewp/1.svg) no-repeat center;
  }

  &__icon_ewp_priority {
    -webkit-mask: url(../../images/icons/ewp/3.svg) no-repeat center;
    mask: url(../../images/icons/ewp/3.svg) no-repeat center;
  }

  &__icon_ewp_goals {
    -webkit-mask: url(../../images/icons/ewp/4.svg) no-repeat center;
    mask: url(../../images/icons/ewp/4.svg) no-repeat center;
  }

  &__icon_ewp_strats {
    -webkit-mask: url(../../images/icons/ewp/5.svg) no-repeat center;
    mask: url(../../images/icons/ewp/5.svg) no-repeat center;
  }

  &__ewp_review_items {
    -webkit-mask: url(../../images/icons/review_items_icon.svg) no-repeat center;
    mask: url(../../images/icons/review_items_icon.svg) no-repeat center;
  }

  &__icon_ewp_ka {
    -webkit-mask: url(../../images/icons/icons-ewp-key-action-4.svg) no-repeat center;
    mask: url(../../images/icons/icons-ewp-key-action-4.svg) no-repeat center;
  }

  &__icon_ea_debrief {
    -webkit-mask: url(../../images/icons/desktop.svg) no-repeat center;
    mask: url(../../images/icons/desktop.svg) no-repeat center;
  }

  &__icon_ea {
    -webkit-mask: url(../../images/icons/view.svg) no-repeat center;
    mask: url(../../images/icons/view.svg) no-repeat center;
  }
  &__icon_elm {
    -webkit-mask: url(../../images/icons/elm.svg) no-repeat center;
    mask: url(../../images/icons/elm.svg) no-repeat center;
  }
  &__icon_belovedTeam {
    -webkit-mask: url(../../images/icons/beloved-team-ic.svg) no-repeat center;
    mask: url(../../images/icons/beloved-team-ic.svg) no-repeat center;
  }
  &__icon_debrief {
    -webkit-mask: url(../../images/icons/edit.svg) no-repeat center;
    mask: url(../../images/icons/edit.svg) no-repeat center;
  }
  &__icon_ea_ga {
    -webkit-mask: url(../../images/icons/group.svg) no-repeat center;
    mask: url(../../images/icons/group.svg) no-repeat center;
  }
  &__icon_cohort {
    -webkit-mask: url(../../../assets/images/icons/cohort.svg) no-repeat center;
    mask: url(../../../assets/images/icons/cohort.svg) no-repeat center;
  }
  &__icon_ga {
    -webkit-mask: url(../../../assets/images/icons/icon-group-analysis.svg) no-repeat center;
    mask: url(../../../assets/images/icons/icon-group-analysis.svg) no-repeat center;
  }
  &__icon_ewp {
    -webkit-mask: url(../../../assets/images/icons/ewp/icons-ewp-active.svg) no-repeat center;
    mask: url(../../../assets/images/icons/ewp/icons-ewp-active.svg) no-repeat center;
  }
}
