.rsw-editor {
  color: var(--primary-navy);
  height: 150px;
  .rsw-ce {
    font-weight: normal;
    height: 100px;
    overflow: auto;

    &:focus-visible {
      outline: none;
    }

    a {
      color: var(--primary-60);
    }

    div {
      width: 100% !important;
      margin: 0 !important;
      padding: 0 !important;
      float: none !important;
    }

    hr {
      display: none;
    }

    ul {
      list-style-type: disc;
      margin-top: 1em;
      margin-bottom: 1em;
      margin-left: 0;
      margin-right: 0;
      padding-left: 40px;
    }
  }

  .rsw-btn {
    color: var(--primary-navy);
    &:hover {
      background-color: var(--gray-20);
    }

    &[data-active='true'] {
      background-color: var(--primary-60);
      color: var(--white-black) !important;
    }
  }

  .rsw-toolbar {
    background-color: var(--gray-10);
    position: sticky;
    top: 0;
  }

  .rsw-dd {
    background-color: var(--gray-10);
    color: var(--primary-navy);
  }
}
