.radioBtns {
  display: flex;
  align-items: flex-start;
  margin-left: -6px;
}

.input {
  width: 342px;

  @media screen and (max-width: 824px) {
    width: 314px;
  }
}
