.ReactModal__Content {
  background-color: var(--background) !important;
}

.rc-collapse-content {
  color: var(--gray-60) !important;
  background-color: var(--background) !important;
}

.rc-collapse-header {
  color: var(--gray-60) !important;
}

// Google autocomplete
.pac-container {
  background-color: var(--panel-background);
  .pac-item {
    border-top: 1px solid var(--bd-color1);
    &:hover {
      background-color: var(--primary-60);
      span {
        color: var(--primary-navy);
      }
    }
    span {
      color: var(--gray-60);
      //font-size: 16px;
    }
  }
}

.Toastify__toast {
  background-color: var(--panel-background) !important;
  color: var(--primary-navy) !important;
}

.Toastify__close-button {
  opacity: 0.7 !important;
  color: var(--primary-navy) !important;
}
