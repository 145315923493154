.b-elm {
  &.-relative {
    position: relative;
  }

  &__bottomBtns {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__p {
    font-size: 16px;
    line-height: 1.5;
    color: var(--gray-60);
    margin-bottom: 24px;
  }

  &__orangeBold {
    font-weight: bold;
    color: var(--primary-60);

    &.-mb5 {
      margin-bottom: 5px;
    }
  }

  &__mobBtn {
    margin: 40px auto;
    display: flex;
    justify-content: center;
  }
}

.b-elmCreate {
  &__row {
    display: flex;
    align-items: center;
    margin-bottom: 0;

    &.-between {
      justify-content: space-between;
    }

    &.-mb30 {
      margin-bottom: 30px;
    }

    &.-alignStretchMob {
      @include media('<tablet') {
        align-items: stretch;
      }
    }

    &.-alignStretch {
      align-items: stretch;
    }
  }

  &__select {
    @include media('<tablet') {
      > div {
        width: 100%;
      }
    }
  }

  &__startDate {
    align-items: center;
    display: flex;
    flex-grow: 1;
    //justify-content: space-between;
    @include media('<tablet') {
      display: block;
    }
  }

  &__title {
    margin-right: 8px;
    width: 92px;
    flex-shrink: 0;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    color: var(--primary-navy);
  }

  &__date {
    flex-shrink: 0;
    //width: 150px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    .react-datepicker-wrapper {
      width: inherit;
    }
  }

  &__reminderText {
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 0.16px;
    color: var(--gray-60);
    margin-left: 5px;
  }

  &__reminderSelect {
    border: 0;
    margin: -5px;
    padding: 5px;
    background-color: var(--background);
    font-size: 16px;
    display: inline-block;
    font-weight: bold;
    line-height: 1.5;
    color: var(--gray-60);
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    //text-indent: 3px;
    text-overflow: '';

    &::-ms-expand {
      display: none;
    }
  }

  &__downloadLink {
    font-size: 12px;
    line-height: 1.5;
    letter-spacing: 0.24px;
    color: var(--gray-60);
    text-decoration: underline;
  }

  &__users {
    margin-bottom: 32px;
  }

  &__finish {
    margin: 0 auto;
    width: 100%;
    max-width: 425px;
  }

  &__finishIll {
    text-align: center;
    width: 190px;
    margin: 10px auto 48px;
    height: 190px;
    display: block;
  }

  &__finishTitle {
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.2;
    text-align: center;
    color: var(--primary-navy);
  }

  &__finishText {
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    color: var(--gray-60);
    margin-bottom: 32px;
  }
}

.b-elmItem {
  &__row {
    display: flex;
    margin-bottom: 15px;
  }

  &__title {
    width: 95px;
    flex-shrink: 0;
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 0.16px;
    color: var(--gray-60);
  }

  &__val {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.43;
    color: var(--gray-60);
    flex-grow: 1;
  }

  &__tipText {
    font-size: 14px;
    color: var(--gray-60);
    margin-bottom: 15px;
  }

  &__links {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 230px;
    flex-wrap: wrap;

    .b-panelItem__link {
      margin-bottom: 10px;
    }
  }
}

.b-elmDetails {
  &__dwndTemplete {
    position: relative;
    top: -20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__btns {
    display: flex;
    align-items: center;
    @include media('<tablet') {
      display: block;
    }
  }

  &__mainBtn {
    min-width: 168px;
    flex-shrink: 0;
    font-size: 16px;
    @include media('<tablet') {
      width: 255px;
      margin-left: auto !important;
      margin-right: auto !important;
    }

    &.-ml8 {
      margin-left: 8px;
      @include media('<tablet') {
        margin-left: 0;
        margin-top: 10px;
      }
    }
  }

  &__panel {
    padding: 36px;
    box-sizing: border-box;
    border-radius: 16px;
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
    background-color: var(--panel-background);
    max-width: 780px;
    margin-bottom: 40px;
  }

  &__panelHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  &__panelRow {
    display: flex;
    column-gap: 20px;

    @include media('<desktop') {
      display: block;
    }
  }

  &__panelParam {
    margin-bottom: 20px;
    display: flex;
    width: 100%;
    &.-w50 {
      width: 50%;
      @include media('<desktop') {
        width: 100%;
      }
    }
  }

  &__divider {
    width: 100%;
    height: 1px;
    margin-bottom: 20px;
    background-color: rgba(151, 151, 151, 0.3);
  }

  &__val {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.43;
    color: var(--gray-60);
    margin-bottom: 10px;
    flex-grow: 1;

    &.-alignFix {
      position: relative;
      top: -3px;
    }

    &.-wysiwyg {
      width: calc(100% - 125px - 36px);
      font-weight: inherit;
      font-size: inherit;
      line-height: inherit;
    }
    &.-alignEnd {
      display: flex;
      align-items: flex-end;
      margin-bottom: 0;
    }
    .react-datepicker-wrapper {
      width: 100%;
    }
  }

  &__title {
    flex-shrink: 0;
    width: 105px;
    margin-right: 20px;
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 0.16px;
    color: var(--gray-60);
    @include media('<desktop') {
      width: 95px;
    }
  }
}

.b-elmTake {
  &__head {
    display: flex;
    margin-bottom: 40px;
    align-items: center;
    justify-content: space-between;
    @include media('<desktop') {
      justify-content: center;
    }
  }

  &__mainTitle {
    margin-bottom: 0;
  }

  &__descBox {
    width: 100%;
    max-width: 655px;
    margin: 0 auto;
  }

  &__terms {
    align-items: center;
    display: flex;
    margin-top: 5px;
    margin-bottom: 30px;
    .text-long-description {
      padding-left: 10px;
    }
  }

  &__termsModal {
    padding: 20px 20px 40px;
    font-size: 12px;
    color: var(--primary-navy);

    a {
      color: var(--primary-60);
    }

    p {
      margin-bottom: 15px;
    }

    h4 {
      font-weight: bold;
      text-decoration: underline;
      margin-bottom: 20px;
    }

    ul {
      li {
        margin-left: 20px;
        margin-bottom: 10px;
        list-style-type: disc;
      }
    }
  }

  &__saveBtn {
    flex-shrink: 0;
    padding: 0;
    width: 160px;
    height: 48px;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    text-align: center;

    &.-desktop {
      position: absolute;
      top: 58px;
      right: 63px;
    }
  }

  &__checboxes {
    width: 100%;
    max-width: 350px;

    li {
      margin-bottom: 10px;
      display: flex;

      font-size: 14px;
      line-height: 1.71;
      color: var(--gray-60);

      .check-box-wrap {
        flex-shrink: 0;
        margin-right: 8px;
      }

      input[type='text'] {
        margin-left: 10px;
        border: 0;
        background-color: var(--panel-background);
        color: var(--primary-navy);
        border-bottom: 1px solid #bebebe;
        &:focus-visible {
          outline-color: var(--primary-60);
        }
        @include media('<tablet') {
          width: 70px;
        }
      }
    }
  }

  &__select {
    max-width: 365px;
    width: 100%;
    margin: 0 auto;

    > div {
      width: 100%;
    }
  }
}

.b-elmUserActionModal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.b-elmAddUserModal {
  &__title {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    color: var(--primary-navy);
  }
  &__item {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__list {
    padding: 20px 50px 20px;
    @include media('<tablet') {
      padding: 20px 0 20px;
    }
  }

  &__roleTitle {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    color: var(--gray-60);
    margin-bottom: 15px;
  }

  &__roles {
    margin-bottom: 30px;
  }

  &__rGroup {
    margin-left: -10px;
  }

  &__inputs {
    margin-bottom: 20px;
  }

  &__addBtn {
    margin-bottom: 60px;
    display: flex;
    justify-content: center;
  }
}
