.b-credits {
  &__noContent {
    margin-top: 20px;
    border-radius: 16px;
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
    background-color: var(--panel-background);
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 388px;
    max-width: 735px;
    justify-content: center;
    align-items: center;
  }

  &__noContentText {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    text-align: center;
    color: var(--primary-navy);
    margin-bottom: 40px;
  }

  &__form {
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 25px;
    padding-right: 25px;
    box-sizing: border-box;
    width: 100%;
    max-width: 550px;
    padding-bottom: 50px;
  }

  &__formContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 824px) {
      width: 314px;
      max-width: 314px;
    }

    &-item {
      display: flex;
      //max-width: 450px;
      width: 100%;
      align-items: center;

      @media screen and (max-width: 824px) {
        max-width: 314px;
      }

      .react-datepicker-wrapper {
        width: 100%;
      }
    }
    &-input {
      width: 100%;
      input {
        min-width: inherit;
        height: 32px;
      }
    }
    &-title {
      min-width: 110px;
      color: var(--gray-60);
      line-height: 1.43;
      letter-spacing: 0.16px;
      font-size: 14px;

      @media screen and (max-width: 824px) {
        min-width: 90px;
      }
    }
  }

  &__formBtns {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__formModal {
    min-height: auto;
  }

  &__createBtnCont {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  &__statusCont {
    display: flex;
    align-items: center;
  }

  &__statusDate {
    padding-left: 10px;
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 0.16px;
    color: var(--primary-navy);

    &.-orange {
      color: var(--primary-60);
    }
  }
  &__simpleInputWrap {
    font-weight: bold;
  }
  &__simpleInput {
    width: 60px;
    input {
      border: 1px solid transparent;
      min-width: 80px;
      height: 20px;
      font-weight: bold;
      padding: 0;
    }
    &.-w40 {
      width: 20px;
      input {
        min-width: 40px;
      }
    }
  }
  &__historyLastUpdate {
    min-height: 20px;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.14px;
    font-style: italic;
  }

  &__notes {
    display: block;
  }
}
