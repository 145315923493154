@import '../../../../assets/styles/include-media';

.form {
  display: flex;
  margin-top: 47px;
  max-width: 770px;

  @include media('<desktop') {
    max-width: 100%;
    flex-direction: column;
    margin-bottom: 30px;
  }

  &__part {
    width: 50%;
    padding-right: 30px;

    @include media('<desktop') {
      width: 100%;
      padding: 0;
    }

    > * {
      width: 100% !important;
    }
    input {
      min-width: 0;
    }
  }
  &__additional {
    display: flex;
    max-width: 770px;

    @include media('<desktop') {
      max-width: 100%;
      flex-direction: column;
    }
  }
  &__additionalCol {
    width: 50%;
    padding-right: 30px;

    &:last-child {
      @include media('<tablet') {
        text-align: center;
      }
    }

    @include media('<desktop') {
      width: 100%;
      padding: 0 0 30px;
    }
  }
  &__buttons {
    display: flex;
    justify-content: flex-end;
    //justify-content: center;
  }

  &__suborganization {
    padding: 0 15px;
    &_opener {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: var(--gray-60);
      font-size: 16px;
      font-weight: 700;
      font-family: Rubik;
      white-space: nowrap;

      .icon {
        width: 10px;
        height: 10px;
        background-color: red;
        -webkit-mask: url(../../../../assets/images/icons/arrow-up.svg) no-repeat center;
        mask: url(../../../../assets/images/icons/arrow-up.svg) no-repeat center;
        background-color: #1d1d1d;
      }
    }

    &_list {
      color: var(--gray-60);
      font-size: 16px;
      font-family: Rubik;

      li {
        padding: 15px 0;
        cursor: pointer;

        a {
          color: var(--gray-60);
        }
      }
    }
  }

  &__addLink {
    color: var(--primary-60);
    cursor: pointer;
    font-weight: 400;
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    padding: 0 15px;
    margin-bottom: 60px;
    margin-top: 15px;
    background-color: transparent;

    &:before {
      content: '+';
      background-color: var(--primary-60);
      color: #fff;
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
      text-align: center;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      margin-right: 8px;
    }
  }
}
