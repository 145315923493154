@import '../../../assets/styles/include-media';

.filters {
  &_active {
    color: var(--primary-60);
  }
  &_button {
    color: var(--gray-60);
    background: transparent;
    padding: 0;
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    transition: none;

    &_active {
      color: var(--primary-60);
    }
  }

  &_popup {
    border-radius: 8px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.05);
    background-color: var(--background);
    margin-top: 10px;
    width: 196px;
    overflow: hidden;

    ul {
      font-family: Rubik;
      font-size: 14px;
      color: var(--gray-60);

      li {
        cursor: pointer;
        padding: 12px 16px;

        &:hover {
          background-color: var(--gray-10);
        }
      }
    }
  }
}

.pagination {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.counts {
  display: flex;
  align-items: center;

  &__title {
    font-size: 11px;
    color: var(--gray-60);
    margin-right: 8px;
  }
}

.popover {
  font-size: 12px;
  font-weight: bold;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--primary-navy);

  img {
    transform: rotate(-90deg);
    width: 16px;
    margin-left: 2px;
  }
}

.navigation {
  display: flex;
  align-items: center;
}

.currentPage {
  font-size: 12px;
  color: var(--gray-60);
}

.btns {
  margin-left: 28px;
  display: flex;
}

.button {
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  background-color: var(--gray-10);

  &_active {
    background-color: var(--primary-60);

    button {
      background-color: var(--white) !important;
    }
  }

  &__icon {
    width: 24px;
    height: 24px;
    -webkit-mask: url(../../../assets/images/icons/round-navigation-chevron-left.svg) no-repeat center;
    mask: url(../../../assets/images/icons/round-navigation-chevron-left.svg) no-repeat center;
    background: var(--gray-30);
  }
}

.right {
  transform: rotate(180deg);
}
