.title {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: var(--gray-60);
}

.selectWrap {
  margin-top: 0;
  margin-bottom: 0;
  height: 83px;
  display: block;
}

.select {
  min-height: 48px;
  height: 48px;
}
.sub_organization_title {
  font-size: 16px;
  font-family: Rubik;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--gray-60);
  margin-left: 28px;
  margin-bottom: 20px;
}

.sub_title {
  margin: 0 30px 30px 30px !important;
  text-align: center;
  max-width: 300px;
  span {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: var(--gray-60);
  }
}

.title_info {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: var(--gray-60);
}

.info_wrap {
  margin-bottom: 40px;
}

.container {
  height: 100vh;
}

.info {
  color: #fb9312;
  text-decoration: underline;
}

.modal {
  padding: 40px;
  max-height: 80vh;
  overflow: auto;
  width: 562px;

  @media screen and (max-width: 824px) {
    width: 328px;
  }

  @media screen and (max-width: 320px) {
    width: 170px;
  }

  h2 {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: var(--primary-navy);
  }
  h3 {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    color: var(--gray-60);
    text-align: center;
    margin-bottom: 0;
    margin-top: 20px;
  }

  p {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--gray-60);
    margin-top: 0;
  }
}

.cross {
  cursor: pointer;
  position: absolute;
  right: 16px;
  top: 16px;
}
