.emptyBlock {
  width: 100%;
  height: 70%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;

  &__card {
    background-color: var(--background);
    border-radius: 16px;
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__text {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: var(--primary-navy);
  }
}
