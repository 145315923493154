@import '../../../assets/styles/include-media';

.back {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
  height: 100vh;
  overflow-y: auto;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
}

.modal {
  position: relative;
  margin: 65px auto;
  z-index: 105;
  width: 100%;
  min-height: 600px;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
  background-color: var(--background);

  @include media('<tablet') {
    margin: 0;
    border-radius: 0;
    height: 100vh;
    overflow-y: scroll;
  }

  &__close {
    z-index: 1;
    width: 24px;
    height: 24px;
    position: absolute;
    right: 10px;
    top: 10px;
    @include media('<desktop') {
      top: 32px;
    }
  }
}

.wrapTitle {
  position: relative;
  padding-top: 40px;
  text-align: center;
}

.wrapMobileTitle {
  padding-top: 32px;
  padding-left: 30px;
  padding-right: 30px;
}

.title {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: var(--gray-60);
}

.backBtn {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 15px;
}

.icon {
  transform: rotate(-90deg);
}
