@import '../../../../../../assets/styles/include-media';

.container {
  display: flex;
  justify-content: center;
}

.steps {
  display: flex;
}

.round {
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  line-height: 20px;
  background-color: var(--gray-20);
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  color: var(--white);

  &__active {
    background-color: var(--primary-60);

    &:not(:first-child):before {
      background-color: var(--primary-60) !important;
    }
  }

  &:not(:first-child):before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 34px;
    height: 2px;
    right: 18px;
    background-color: var(--gray-20);
  }

  &:not(:last-child) {
    margin-right: 32px;
  }
}
