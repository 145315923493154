@import '../../../../assets/styles/include-media';

// boxList
.boxList {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 30px;

  &_item {
    padding: 28px 48px 28px 28px;
    position: relative;

    .checkbox {
      position: absolute;
      right: 20px;
      top: 20px;
    }
  }
  &_userName {
    color: var(--primary-navy);
    display: block;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 8px;
  }
  ul {
    font-size: 14px;
    color: var(--gray-60);

    li {
      margin-bottom: 8px;
      height: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

// infoPopup
.infoPopup {
  padding: 24px;
  margin-top: 16px;
  box-sizing: border-box;
  width: 328px;
  background: var(--background);
  //position: absolute;
  left: 0;
  top: 100%;
  z-index: 10;

  &_item {
    padding: 0 0 16px 37px;
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &_title {
    font-family: Rubik;
    font-size: 20px;
    font-weight: 700;
    color: var(--gray-60);
    display: block;
    text-align: center;
    margin-bottom: 16px;
  }
  &_subTitle {
    display: block;
    font-family: Rubik;
    font-size: 16px;
    font-weight: 700;
    color: var(--primary-navy);
    margin-bottom: 5px;
  }
  p {
    font-family: Rubik;
    font-size: 14px;
    line-height: 1.43;
    color: var(--gray-60);
    margin: 0;
  }
  &__icon {
    background-color: var(--primary-navy);
    position: absolute;
    top: 0;
    left: 0;
    margin-right: 8px;

    &_send {
      -webkit-mask: url(../../../../assets/images/icons/send.svg) no-repeat center;
      mask: url(../../../../assets/images/icons/send.svg) no-repeat center;
      width: 20px;
      height: 20px;
    }
    &_personOff {
      -webkit-mask: url(../../../../assets/images/icons/person_off.svg) no-repeat center;
      mask: url(../../../../assets/images/icons/person_off.svg) no-repeat center;
      width: 19px;
      height: 20px;
    }
    &_delete {
      -webkit-mask: url(../../../../assets/images/icons/delete.svg) no-repeat center;
      mask: url(../../../../assets/images/icons/delete.svg) no-repeat center;
      width: 14px;
      height: 18px;
    }
  }
}
