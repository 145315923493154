.b-belovedUser {
  &__list {
    display: flex;
    @media screen and (max-width: 1380px) {
      justify-content: center;
    }
    flex-wrap: wrap;
    @include media('<tablet') {
      max-width: 300px;
      margin: 0 auto;
    }
  }
}

.b-belovedUserCard {
  max-width: 300px;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;

  &__editBtn {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 35px;
    height: 35px;
    padding: 0;

    &:before {
      top: 7px;
      left: 8px;
    }
  }
}
