@import '../../../../../assets/styles/include-media';

.addCalendar {
  &__opt {
    display: block;
    margin-bottom: 24px;
  }
}

.step4Text {
  @include media('<tablet') {
    text-align: center;
  }
}

.finishStep {
  &__ill {
    text-align: center;
    width: 190px;
    margin: 0 auto;
    height: 190px;
    margin-top: 10px;
    display: block;
    margin-bottom: 48px;
  }

  &__container {
    margin: 0 auto;
    width: 100%;
    max-width: 425px;
  }

  &__title {
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.2;
    text-align: center;
    color: var(--primary-navy);
  }

  &__text {
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    color: var(--gray-60);
    margin-bottom: 32px;
  }

  &__info {
    text-align: center;
    margin-bottom: 32px;
  }

  &__addCalendar {
    margin-top: 10px;
    display: flex;
    justify-content: center;
  }

  &__notifBox {
    width: 100%;
    max-width: 375px;
    margin: 20px auto 40px;
  }

  &__notifTitle {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.43;
    color: var(--gray-60);
    margin-bottom: 24px;
  }

  &__notifLine {
    justify-content: space-between;
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  &__notifStatus {
    width: 175px;
    margin-right: 15px;
    font-size: 16px;
    line-height: 1.5;
    color: var(--primary-60);
  }

  &__notifKey {
    width: 130px;
    font-size: 16px;
    line-height: 1.5;
    color: var(--gray-60);
    margin-right: 15px;
  }

  &__notifSend {
    padding: 5px;
  }

  &__nextBtn {
    margin: 0 auto;
    width: 130px;
  }
}
