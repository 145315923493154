.b-phoneNumber {
  position: relative;
  z-index: 10;
  input {
    width: 100% !important;
    height: 52px !important;
    border: 1px solid var(--gray-30) !important;
    color: var(--gray-60) !important;
    min-width: 334px;
    background: var(--background) !important;

    @media screen and (max-width: 824px) {
      min-width: 314px;
    }
  }
  .special-label {
    color: var(--gray-60) !important;
    left: 7px !important;
    font-size: 12px !important;
    background: var(--background) !important;
  }

  &.-error {
    .form-control {
      color: var(--rad) !important;
      border: 1px solid var(--rad) !important;
    }
  }

  &.-focus {
    .special-label {
      color: var(--primary-60) !important;
    }
    .form-control {
      box-shadow: 0 0 0 1px var(--primary-60) !important;
      outline: none !important;
      border: 1px solid var(--primary-60) !important;
      border-color: var(--primary-60) !important;
    }
  }
}
