.react-datepicker-wrapper {
  width: auto;
  @include media('<desktop') {
    display: flex;
    align-items: center;
  }
}

.datepicker-no-panel {
  .react-datepicker__input-container {
    input {
      background-color: var(--background);
    }
  }
}
.react-datepicker__header {
  .react-datepicker__day-name {
    color: var(--gray-70);
  }
  .react-datepicker__current-month {
    color: var(--primary-navy);
  }
}
.react-datepicker-time__header {
  color: var(--primary-navy) !important;
}

.react-datepicker__input-container {
  input {
    background-color: var(--panel-background);
    font-family: Rubik;
    border: 0;
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    color: var(--gray-60);
    cursor: pointer;

    &::placeholder {
      color: var(--primary-60);
      font-weight: normal;
      font-size: 14px;
    }
  }
  .react-datepicker-ignore-onclickoutside {
    cursor: text !important;
  }
}

.react-datepicker__day {
  color: var(--gray-70) !important;

  &--disabled {
    opacity: 0.5;
  }

  &--selected {
    color: var(--background) !important;
  }
}

.react-datepicker {
  border-radius: 0rem;
  border: 1px solid var(--gray-20) !important;

  &__triangle {
    &:after {
      border-bottom-color: var(--bd-color1) !important;
    }
  }

  &__input-container {
    input:focus-visible {
      outline: var(--orange) auto 1px;
    }
  }
  &__header {
    background-color: var(--bd-color1) !important;
  }
}

.react-datepicker-popper {
  z-index: 10;
}

.react-datepicker__time {
  &-list {
    background-color: var(--background);
    color: var(--gray-70);

    &-item {
      &--selected {
        color: var(--background) !important;
        background-color: var(--primary-60) !important;
      }
      &:hover {
        background: var(--date-picker-day-hover) !important;
        color: var(--gray-70) !important;
      }
      &--disabled {
        opacity: 0.5;
      }
    }
  }
}

.react-datepicker__month-container {
  //border-radius: 0.3rem;
  //border: 1px solid var(--gray-20) !important;
  background-color: var(--background);
}
.react-datepicker__day {
  &:hover {
    background: var(--date-picker-day-hover) !important;
  }
  &--keyboard-selected {
    background-color: transparent !important;
  }
  &--today {
    background-color: var(--primay-yellow) !important;
    color: var(--color) !important;
    &:hover {
      background-color: var(--primay-yellow) !important;
    }
  }
  &--selected {
    background-color: var(--primary-60) !important;
    &:hover {
      background-color: var(--primary-60) !important;
    }
  }
}
