.b-newsBlock {
  &__container {
    margin-top: 60px;
  }
  &__title {
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--primary-navy);
  }

  &__slider {
    margin-top: 5px;
  }
}

.b-newsBlockItem {
  padding: 20px;
  margin: 0 10px;
  height: 200px;
  box-sizing: border-box;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.15) !important;
  display: block;
  position: relative;

  @include media('<desktop') {
    height: 300px;
  }

  &:hover {
    .b-newsBlockItem__extra {
      opacity: 1;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  &__user {
    font-size: 12px;
    font-weight: bold;
    line-height: 1.67;
    color: var(--primary-60);
  }

  &__date {
    font-size: 10px;
    font-weight: 500;
    line-height: 2;
    color: var(--text-color-10);
  }

  &__image {
    object-fit: cover;
    height: 90px;
    display: block;
    width: 100%;
    margin-bottom: 10px;
    @include media('<desktop') {
      height: 190px;
    }
  }

  &__text {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.36;
    color: var(--gray-60);

    &.-lg {
      overflow: hidden;
      max-height: 150px;
      display: -webkit-box;
      -webkit-line-clamp: 7;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
    }

    &.-md {
      overflow: hidden;
      max-height: 150px;
      display: -webkit-box;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
    }

    &.-read {
      font-weight: normal;
      opacity: 0.8;
    }

    &.-pre {
      white-space: pre-line;
    }
  }

  &__extra {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: 0.3s;
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    font-size: 13px;
    line-height: 1.31;
    color: var(--gray-60);
    padding: 20px 20px 20px;
    background: var(--bg-color-tr-1);
    border-radius: 16px;
  }

  &__readMore {
    font-size: 12px;
    font-weight: 600;
    line-height: 1.42;
    margin-top: 6px;
    text-align: center;
    color: var(--primary-60);
  }

  &__link {
    position: absolute;
    bottom: 5px;
    left: 20px;
  }
}
