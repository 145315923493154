.text {
  max-width: 350px;

  @media screen and (max-width: 824px) {
    max-width: 287px;
  }
}

.title {
  font-size: 20px;
}
