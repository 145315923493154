.b-equityAudits {
  &__checkbox {
    margin: 0 0 10px 6px;

    &-header {
      justify-content: flex-end !important;
    }
  }

  &__elmStatus {
    display: flex;
    height: 100%;
    line-height: 1.5;
    flex-direction: column;
    justify-content: center;
  }

  &__pmLink {
    background-color: transparent;
    font-size: 14px;
    padding: 0;
    display: flex;
    align-items: center;
    color: var(--gray-60);
    font-weight: bold;
    img {
      padding-left: 7px;
    }
  }
  a {
    color: var(--primary-60);
    text-decoration: underline;
  }
}

.selectLeadForm {
  &__userList {
    margin-bottom: 40px;
    li {
      display: flex;
    }
  }
}

.debriefLeadField {
  color: inherit !important;
  text-decoration: none !important;
}
