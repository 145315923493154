.b-cohortCard {
  display: flex;
  padding: 18px !important;

  &__title {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.38;
    color: var(--primary-navy);
  }

  &__dates {
    font-size: 14px;
    line-height: 1.5;
    color: var(--gray-60);
  }
}

.b-cohortParticipant {
  &__search {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__organization {
    font-size: 20px;
    color: var(--primary-navy);
    font-weight: bold;
  }
  .b-tableTool.-mobView {
    @include media('<desktop') {
      width: auto;
      height: auto;
      font-size: 14px;
    }
  }

  .b-page__controls {
    @include media('<desktop') {
      flex-direction: inherit;
    }
  }

  &__controls {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
}

.b-cohortDetails {
  &__heading {
    font-size: 18px;
    color: var(--primary-navy);
    margin-bottom: 15px;

    b {
      font-size: 24px;
    }
  }
  &__activity {
    margin-top: -20px !important;
    img {
      width: 20px;
    }
  }
}

.b-cohortSession {
  &__title {
    position: relative;
  }

  &__subTitleBlock {
    display: flex;
    justify-content: space-between;
  }

  &__subTitle {
    font-size: 20px;
    font-weight: bold;
    color: var(--primary-navy);
    margin-bottom: 29px;
    overflow-wrap: anywhere;
  }

  &__fieldTitle {
    color: var(--primary-navy);
    font-size: 16px;
    font-weight: bold;
  }

  &__fieldValue {
    color: var(--gray-60);
    font-size: 16px;
    font-weight: bold;

    a {
      color: var(--primary-60);
    }

    &.-preLine {
      white-space: pre-line;
    }
  }

  &__img {
    width: 22px;
  }

  .b-panelData__row {
    grid-template-columns: 122px 1fr;
    margin-bottom: 32px;

    &.-rw4 {
      grid-template-columns: 120px 0fr 60px 1fr;
    }
    &.-activity {
      grid-template-columns: 120px 0fr 120px 1fr;
    }
  }

  &__resources {
    display: flex;
    flex-direction: column;
    margin-top: 10px;

    &__item {
      margin: 10px 0;
      display: flex;
      align-content: center;
      justify-content: space-between;
      width: 100%;
      color: var(--gray-60);
      font-size: 16px;
      font-weight: bold;
    }
  }
}
