.b-modal {
  padding: 30px 30px 70px;

  .simple-text {
    color: var(--primary-navy);
  }

  &__buttons {
    display: flex;
    justify-content: center;
  }

  &__content {
    padding-bottom: 40px;
    &.-center {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__fixed {
    position: fixed;
  }

  &__title {
    margin: 0px;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    color: var(--primary-navy);
    margin-bottom: 15px;
  }

  &__text {
    margin: 0;
    margin-bottom: 15px;
    font-size: 14px;
    line-height: 1.71;
    color: var(--gray-60);
    margin-left: 38px;
  }

  &__cross {
    cursor: pointer;
    position: absolute;
    right: 16px;
    top: 16px;
    width: 24px;
    height: 24px;
    background: var(--primary-navy);
    -webkit-mask: url(../../../assets/images/icons/cross.svg) no-repeat center;
    mask: url(../../../assets/images/icons/cross.svg) no-repeat center;
  }

  &__add-user {
    padding: 40px;
    max-height: 80vh;
    overflow: auto;
    width: 350px;
    min-height: auto;
    color: var(--primary-navy) !important;

    &_back {
      background-color: rgba(0, 0, 0, 0.7);
    }
  }
  &__forgot-password {
    max-width: 400px;
    padding: 40px;
  }

  &__main {
    min-height: auto;
  }

  &__assignBelUser {
    z-index: 998;
  }
}
