.b-equityAudit {
  &__orgBigName {
    color: var(--primary-60);
    overflow-wrap: anywhere;

    &.-desktop {
      @include media('<desktop') {
        display: none;
      }
    }
  }

  &__pageTitle {
    @include media('<desktop') {
      color: var(--gray-60);
      font-size: 20px;
      margin-bottom: 32px;
      text-align: center;
    }
  }

  &__textCenter {
    text-align: center;
  }

  &__bottom {
    display: flex;
    justify-content: center;
  }

  &__btnPlusIc {
    font-size: 24px;
    padding-right: 5px;
    font-weight: 400;
  }

  &__text16 {
    font-size: 16px;
    line-height: 1.5;
    color: var(--gray-60);
  }

  &__text14 {
    font-size: 14px;
    line-height: 1.43;
    color: var(--gray-60);
  }

  &__text12 {
    font-size: 12px;
    line-height: 1.5;
    color: var(--gray-60);

    &.-mtMinus10 {
      margin-top: -10px;
    }
  }
}

.b-EAListPlaceholder {
  text-align: center;

  &.-leftAlign {
    text-align: left;
  }

  &__panl {
    padding: 32px;
  }

  &__ill {
    width: 190px;
    height: 190px;
    display: block;
    margin: 0 auto 20px;
  }

  &__title {
    font-size: 16px;
    margin-bottom: 24px;
  }

  &__text {
    text-align: left;
    max-width: 535px;
    margin: 0 auto;
  }
}

.b-EATypo {
  &__pageTitle {
    @include media('<desktop') {
      color: var(--gray-60);
      font-size: 20px;
    }
  }
}

.b-EACreate {
  &__2btnsWrapper {
    display: flex;
    justify-content: center;
    padding-top: 60px;
    max-width: 735px;
  }

  &__step {
    position: relative;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    .pageContainer__title {
      margin-bottom: 0;
    }

    @include media('<desktop') {
      justify-content: center;
    }
  }

  &__saveBtn {
    width: 156px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    color: var(--primary-60);
    border-radius: 16px;
    border: solid 2px var(--primary-60);

    img {
      padding-right: 5px;
    }
  }

  &__users {
    margin-top: 20px;
  }

  &__user {
    display: flex;
    margin-bottom: 20px;
  }

  &__dateArea {
    margin-top: 20px;
    display: flex;

    .-addCalendar {
      margin-top: 0;
    }
    &.-w-100 {
      .react-datepicker-wrapper {
        width: 100%;
      }
    }

    .react-datepicker-wrapper {
      width: auto;
      margin-right: 10px;
      @include media('<desktop') {
        display: flex;
        align-items: center;
      }
    }

    .react-datepicker__input-container {
      input {
        font-family: Rubik;
        border: 0;
        font-size: 16px;
        font-weight: bold;
        line-height: 1.5;
        color: var(--gray-60);
      }
    }
  }

  &__userName {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    color: var(--gray-60);
    width: 160px;
  }
}

.b-EAModal {
  padding: 40px 108px 60px;

  @include media('<desktop') {
    padding: 23px 24px 60px;
  }

  &__title {
    position: relative;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.2;
    color: var(--primary-navy);
    margin-bottom: 40px;

    @include media('<desktop') {
      //max-width: 80%;
      padding: 0 15px;
    }
  }

  &__subTitle {
    color: var(--primary-navy);
    display: block;
    font-size: 16px;
    margin-bottom: 15px;
  }

  &__buttons {
    display: flex;
    justify-content: center;
  }

  &__back {
    width: 24px;
    height: 24px;
    background-color: var(--gray-80);
    display: block;
    position: absolute;

    left: -10px;
    -webkit-mask: url('../../images/icons/round-navigation-chevron-left.svg') no-repeat center;
    mask: url('../../images/icons/round-navigation-chevron-left.svg') no-repeat center;
  }

  &__close {
    width: 24px;
    height: 24px;
    background-color: var(--gray-80);
    display: block;
    position: absolute;
    right: -20px;
    top: -40px;
    -webkit-mask: url('../../images/icons/cross.svg') no-repeat center;
    mask: url('../../images/icons/cross.svg') no-repeat center;

    @include media('<tablet') {
      top: 0;
      left: -10px;
      -webkit-mask: url('../../images/icons/round-navigation-chevron-left.svg') no-repeat center;
      mask: url('../../images/icons/round-navigation-chevron-left.svg') no-repeat center;
    }
  }

  .addCalendar {
    display: flex;
    flex-direction: column;

    &__date {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: 0.18px;
      text-align: center;
      color: var(--gray-60);
      margin-top: 20px;
    }

    &__title {
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: center;
      color: var(--gray-60);
    }
  }

  &__content {
    font-size: 16px;
    line-height: 1.5;
    color: var(--gray-60);

    p {
      margin: 0 0 20px;
    }
  }

  &__list {
    padding-left: 30px;

    @include media('<tablet') {
      padding-left: 20px;
    }

    li {
      position: relative;
      padding-left: 15px;
      padding-bottom: 15px;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 10px;
        height: 3px;
        width: 3px;
        border-radius: 50%;
        background-color: var(--gray-60);
      }
    }
  }

  &__definitionList {
    padding-left: 30px;

    @include media('<tablet') {
      padding-left: 20px;
    }

    dt {
      font-weight: 700;
      color: var(--primary-60);
      display: block;
      padding: 0 0 5px;
    }
    dd {
      margin: 0 0 15px;
    }
  }
}

// b-EATaking
.b-EATaking {
  position: relative;
  &__header {
    max-width: 900px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;

    @include media('<tablet') {
      flex-direction: column;
      height: auto;
      margin-bottom: 20px;
    }
  }

  &__saveExit {
    position: absolute;
    top: 0;
    right: 0;
  }

  &__box {
    max-width: 832px;
    margin: 0 auto;
    padding: 60px;
    border-radius: 16px;
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
    background-color: var(--panel-background);
    display: flex;
    flex-direction: column;
    align-items: center;

    @include media('<desktop') {
      padding: 32px;
    }

    p {
      &.-w100 {
        width: 100%;
      }
      margin: 0 0 26px;
    }
  }

  &__container {
    max-width: 460px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Rubik;
    font-size: 14px;
    line-height: 1.43;
    color: var(--gray-60);
  }

  &__list {
    list-style: none;
    margin: 0 auto 24px;

    &.-w100 {
      width: 100%;
    }

    li {
      position: relative;
      padding-left: 12px;
      margin-bottom: 5px;

      &:before {
        content: '';
        position: absolute;
        top: 8px;
        left: 0;
        background-color: var(--gray-60);
        width: 4px;
        height: 4px;
        border-radius: 50%;
      }
    }
  }

  &__boxTitle {
    font: 700 16px/1.5 Rubik;
    color: var(--primary-navy);
    margin: 0 0 16px;
  }

  &__buttons {
    display: flex;
    align-items: center;
    margin-top: 50px;

    @include media('<tablet') {
      margin-bottom: 24px;
      margin-top: 32px;

      > * {
        width: 50% !important;
        margin: 0 4px;
      }
    }
  }

  &__linkRow {
    display: flex;
    align-items: center;
  }

  &__link {
    text-decoration: underline;
    color: var(--primary-60);
    font-size: 14px;
    align-self: center;

    &:hover {
      text-decoration: none;
    }
  }

  &__infoLink {
    display: inline-block;
    vertical-align: middle;
    line-height: 0;
    padding: 0;
    background-color: var(--gray-30);
    -webkit-mask: url(../../../assets/images/icons/info-outlined.svg) no-repeat center;
    mask: url(../../../assets/images/icons/info-outlined.svg) no-repeat center;
    width: 24px;
    height: 24px;
    margin: 0 0 0 8px;
  }

  // questions
  &__questions {
    max-width: 632px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include media('<desktop') {
      width: 100%;
    }

    p {
      color: var(--primary-navy);
      font-size: 16px;
      line-height: 1.43;
      font-weight: 700;
      text-align: center;

      @include media('<desktop') {
        font-size: 14px;
      }
    }
  }

  // stepsBlock
  &__stepsBlock {
    position: relative;
    display: flex;
    justify-content: center;
    margin-left: -50px;
    margin-right: -50px;

    @include media('<desktop') {
      margin-left: -20px;
      margin-right: -20px;
    }

    &:after {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      background-image: var(--take-ea-steps-left-shadow);
      width: 100px;
      @include media('<desktop') {
        width: 30px;
      }
    }
    &:before {
      content: '';
      position: absolute;
      left: 0;
      z-index: 10;
      top: 0;
      bottom: 0;
      background-image: var(--take-ea-steps-right-shadow);
      width: 100px;
      @include media('<desktop') {
        width: 30px;
      }
    }
  }

  // steps
  &__steps {
    color: var(--gray-30);
    font-weight: 700;
    font-size: 14px;
    max-width: 100%;
    overflow-x: auto;
    display: flex;
    padding-bottom: 20px;
    margin-bottom: 20px;
    padding-right: 100px;
    padding-left: 100px;

    @include media('<desktop') {
      padding-right: 20px;
      padding-left: 20px;
    }

    li {
      cursor: pointer;
      display: inline-flex;
      position: relative;
      margin-top: 30px;

      &:hover {
        .b-EATaking__countingWrapper {
          transition: 0.3s;
          opacity: 1;
        }
      }

      &.disabled {
        pointer-events: none;
      }

      &.active {
        color: var(--primary-60) !important;
      }
      &.pin-red {
        &:before {
          background-color: var(--primary-80);
        }
      }
      &.pin-yellow {
        &:before {
          background-color: var(--primay-yellow);
        }
      }

      &.pin-green {
        &:before {
          background-color: var(--green-60);
        }
      }

      &.pin-gray {
        &:before {
          background-color: var(--gray-30);
        }
      }

      &:last-child {
        &:after {
          display: none;
        }
      }

      &:before {
        content: '';
        width: 6px;
        height: 6px;
        border-radius: 50%;
        margin: 5px 8px 0 0;
        flex-shrink: 0;
      }

      &:after {
        content: '';
        background-color: var(--gray-60);
        opacity: 0.5;
        width: 36px;
        height: 1px;
        margin: 8px 8px 0;
        flex-shrink: 0;
      }
    }
  }

  // questionsList
  &__questionsList {
    list-style: none;
    margin: 0 0 32px;
    padding: 0;
    font-size: 14px;
    color: var(--primary-navy);
    max-width: 544px;
    width: 100%;

    @include media('<desktop') {
      font-size: 12px;
      margin-bottom: 24px;
    }
    li + li {
      margin-top: 8px;
    }
    li {
      &.active {
        box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.05);

        button {
          border-color: var(--primary-60);
          background-color: var(--primary-60);
          font-weight: 700;
          color: var(--white);
        }
      }

      button {
        transition: 0ms;
        color: var(--gray-60);
        border: solid 1px var(--bd-color3);
        border-radius: 8px;
        background-color: transparent;
        text-align: left;
        padding: 18px 40px;
        display: block;
        width: 100%;
        height: 100%;
        outline: 0;
      }
    }
  }

  // counting
  &__counting {
    font-weight: 400;
    font-size: 12px;
    padding: 5px 12px;
    border-radius: 4px;
    border: solid 1px var(--gray-10);
    background-color: var(--white);
    display: inline-block;
    color: var(--gray-60);
    margin-bottom: 5px;
  }

  &__countingWrapper {
    opacity: 0;
    width: 200px;
    position: absolute;
    left: 0;
    top: -30px;
  }

  // counter
  &__counter {
    color: #fbc408;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 24px;
  }
}

// b-EADetails
.b-EADetails {
  &__pageTitle {
    @include media('<tablet') {
      color: var(--gray-60);
      font-size: 20px;
    }
  }

  &__header {
    height: 48px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 29px;

    @include media('<desktop') {
      height: auto;
      flex-direction: column;
    }

    .pageContainer__title {
      margin: 0;

      @include media('<tablet') {
        color: var(--gray-60);
        font-size: 20px;
        margin-bottom: 15px;
      }
    }

    .button {
      box-sizing: border-box;
    }
  }

  &__headerButtons {
    display: flex;
    flex-shrink: 0;
    position: absolute;
    right: 0;

    @include media('<desktop') {
      flex-direction: column;
      align-items: center;
    }

    .button {
      margin-left: 12px;

      @include media('<desktop') {
        margin-left: 0;
        margin-top: 12px;
      }
    }
  }

  &__headerRow {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__ogrTitle {
    color: var(--primary-60);
    font-size: 20px;
    line-height: 1.2;
    font-weight: 700;
    margin-bottom: 12px;
    margin-top: 32px;
  }
  &__org {
    font-size: 16px;
    text-transform: uppercase;
    display: inline-flex;
    color: var(--gray-60);
  }
  &__suborg {
    font-size: 16px;
    color: var(--primary-navy);
    font-weight: bold;
    margin-left: 10px;
    display: inline-flex;
    align-items: center;

    &:before {
      content: '';
      opacity: 0.5;
      background-color: var(--gray-30);
      width: 1px;
      height: 16px;
      margin-right: 10px;
    }
  }

  &__headerRow,
  &__ogrTitle {
    display: none;

    @include media('<tablet') {
      display: flex;
    }
  }

  &__dateRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__date {
    color: var(--gray-60);
    font-weight: 700;
    position: relative;
    top: 3px;
    width: 140px;

    @include media('<tablet') {
      width: 100px;
    }

    .react-datepicker__input-container {
      input {
        width: 100%;
        font-family: Rubik;
        border: 0;
        font-size: 16px;
        font-weight: bold;
        line-height: 1.5;
        color: var(--gray-60);
        position: relative;
        top: -3px;
      }
    }
  }

  &__buttonRow {
    display: flex;
    justify-content: center;

    @include media('<tablet') {
      flex-direction: column;
      align-items: center;
    }

    .button {
      @include media('<tablet') {
        margin-bottom: 20px;
      }
    }
  }

  &__box {
    padding: 36px;
    box-sizing: border-box;
    border-radius: 16px;
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
    background-color: var(--panel-background);
    //max-width: 735px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    margin-bottom: 40px;

    @include media('<desktop') {
      display: block;
    }
  }

  &__currentStatusTag {
    display: inline-flex;
    align-items: center;
    padding: 4px 12px;
    font-size: 14px;
    letter-spacing: 0.16px;
    color: var(--primary-navy);
    border-radius: 8px;
    background-color: rgba(0, 0, 45, 0.2);
    margin-bottom: 10px;

    &:before {
      content: '';
      width: 6px;
      height: 6px;
      background-color: var(--primary-navy);
      border-radius: 50%;
      margin-right: 8px;
    }
  }

  &__detailsList {
    th {
      text-align: left;
      font-weight: 400;
    }
    th,
    td {
      color: var(--gray-60);
      font-size: 14px;
      padding: 10px 15px 10px 0;
    }
    td {
      font-weight: 700;
    }
  }

  &__rightDetails {
    margin-top: 28px;
    @include media('<desktop') {
      margin-top: 0;
    }
  }

  &__debriefMetricLabel {
    @include media('<desktop') {
      width: 73px;
    }
  }

  &__scheduleLink {
    font-weight: 400;
    color: var(--primary-60);
    display: flex;
    align-items: center;
  }

  &__scheduleLinkIcon {
    display: block;
    margin-right: 5px;
    width: 20px;
    height: 20px;
    background-color: var(--primary-60);
    -webkit-mask: url(../../../assets/images/icons/round-action-calendar-today.svg) no-repeat center;
    mask: url(../../../assets/images/icons/round-action-calendar-today.svg) no-repeat center;
  }

  &__dateHeading {
    color: var(--primary-navy);
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 4px;

    @include media('<tablet') {
      margin-top: 20px;
    }

    small {
      color: var(--gray-60);
      font-size: 12px;
      font-weight: 400;
      margin-left: 8px;

      @include media('<tablet') {
        display: block;
        margin-left: 0;
        margin-top: 4px;
      }
    }
  }

  &__block50 {
    width: 50%;

    @include media('<tablet') {
      width: 100%;
    }
  }

  &__btn {
    background: none;
    padding: 0;
    background-color: var(--primary-navy);
    margin-left: 20px;

    &:hover {
      background-color: var(--primary-60);
    }

    &:disabled {
      pointer-events: none;
      background-color: grey;
    }

    &_send {
      -webkit-mask: url(../../../assets/images/icons/send.svg) no-repeat center;
      mask: url(../../../assets/images/icons/send.svg) no-repeat center;
      width: 20px;
      height: 20px;

      &.-congrat_page {
        mask-size: 25px;
        width: 35px;
        margin-left: 0;
        height: 35px;
      }
    }
    &_delete {
      -webkit-mask: url(../../../assets/images/icons/delete.svg) no-repeat center;
      mask: url(../../../assets/images/icons/delete.svg) no-repeat center;
      width: 14px;
      height: 18px;
    }
  }
}
