@import '../../../../assets/styles/include-media';

.form {
  margin-top: 47px;
  max-width: 770px;

  @include media('<desktop') {
    max-width: 100%;
    flex-direction: column;
    margin-bottom: 30px;
  }
}
