.tabs {
  display: flex;
  align-items: center;
  width: 100%;
  overflow-x: auto;
  padding-bottom: 10px;

  &__item {
    display: flex;
    align-items: center;
    padding-bottom: 8px;
    margin-right: 30px;
    border-bottom: 3px solid transparent;

    &:hover {
      //border-bottom: 3px solid var(--primary-60);

      .tabs__icon {
        background-color: var(--primary-60);
      }

      .tabs__text {
        color: var(--primary-60);
        //font-weight: bold;
      }
    }
  }

  &__item_active {
    border-bottom: 3px solid var(--primary-60);

    .tabs__icon {
      background-color: var(--primary-60);
    }

    .tabs__text {
      color: var(--primary-60);
      font-weight: bold;
    }
  }

  &__text {
    font-family: Rubik;
    font-size: 14px;
    line-height: 1.43;
    white-space: nowrap;
    letter-spacing: 0.16px;
    color: var(--gray-30);
  }

  &__icon {
    margin-right: 8px;
    width: 20px;
    height: 20px;
    background-color: var(--gray-30);
  }

  &__icon_profile {
    -webkit-mask: url(../../../assets/images/icons/round-action-account-circle.svg) no-repeat center;
    mask: url(../../../assets/images/icons/round-action-account-circle.svg) no-repeat center;
  }

  &__icon_biography {
    -webkit-mask: url(../../../assets/images/icons/biography.svg) no-repeat center;
    mask: url(../../../assets/images/icons/biography.svg) no-repeat center;
  }

  &__icon_settings {
    -webkit-mask: url(../../../assets/images/icons/round-action-settings.svg) no-repeat center;
    mask: url(../../../assets/images/icons/round-action-settings.svg) no-repeat center;
  }

  &__icon_termsConditions {
    -webkit-mask: url(../../../assets/images/icons/round-action-description.svg) no-repeat center;
    mask: url(../../../assets/images/icons/round-action-description.svg) no-repeat center;
  }

  &__icon_privacyPolicy {
    -webkit-mask: url(../../../assets/images/icons/round-editor-insert-drive-file.svg) no-repeat center;
    mask: url(../../../assets/images/icons/round-editor-insert-drive-file.svg) no-repeat center;
  }

  &__icon_support {
    -webkit-mask: url(../../../assets/images/icons/round-action-contact-support.svg) no-repeat center;
    mask: url(../../../assets/images/icons/round-action-contact-support.svg) no-repeat center;
  }
}
