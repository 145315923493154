@import '../../../assets/styles/include-media';

.stackedBarCanvas {
  &__holder {
    border-radius: 16px;
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
    background-color: var(--panel-background);
    padding: 40px 36px 13px 183px;

    @include media('<desktop') {
      padding-left: 36px;
    }

    @include media('<tablet') {
      padding: 20px;
    }
  }

  &__labels {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 700;
    color: var(--gray-30);
    margin-bottom: 8px;
    margin-left: -5px;
    margin-right: -15px;

    &--desktop {
      @include media('<tablet') {
        display: none;
      }
    }

    &--mobile {
      display: none;

      @include media('<tablet') {
        display: flex;
      }
    }
  }
  &__row {
    display: flex;
    align-items: center;
    background: linear-gradient(
      to right,
      var(--chartBg1) 10%,
      var(--chartBg2) 10%,
      var(--chartBg2) 20%,
      var(--chartBg1) 20%,
      var(--chartBg1) 30%,
      var(--chartBg2) 30%,
      var(--chartBg2) 40%,
      var(--chartBg1) 40%,
      var(--chartBg1) 50%,
      var(--chartBg2) 50%,
      var(--chartBg2) 60%,
      var(--chartBg1) 60%,
      var(--chartBg1) 70%,
      var(--chartBg2) 70%,
      var(--chartBg2) 80%,
      var(--chartBg1) 80%,
      var(--chartBg1) 90%,
      var(--chartBg2) 90%,
      var(--chartBg2) 100%
    );
    min-height: 60px;

    @include media('<desktop') {
      flex-direction: column;
      align-items: stretch;
      height: auto;
      padding: 10px 0;
    }
    @include media('<tablet') {
      background: linear-gradient(
        to right,
        var(--chartBg1) 25%,
        var(--chartBg2) 25%,
        var(--chartBg2) 50%,
        var(--chartBg1) 50%,
        var(--chartBg1) 75%,
        var(--chartBg2) 75%,
        var(--chartBg2) 100%
      );
    }
  }
  &__rowGroupName {
    font-size: 12px;
    font-weight: 600;
    line-height: 2;
    text-align: right;
    color: var(--gray-60);
    width: 146px;
    margin-left: -170px;
    @include media('<tablet') {
      margin-left: 0;
      width: auto;
      text-align: left;
    }
  }
  &__rowGroup {
    background: linear-gradient(
      to right,
      var(--chartBg1) 10%,
      var(--chartBg2) 10%,
      var(--chartBg2) 20%,
      var(--chartBg1) 20%,
      var(--chartBg1) 30%,
      var(--chartBg2) 30%,
      var(--chartBg2) 40%,
      var(--chartBg1) 40%,
      var(--chartBg1) 50%,
      var(--chartBg2) 50%,
      var(--chartBg2) 60%,
      var(--chartBg1) 60%,
      var(--chartBg1) 70%,
      var(--chartBg2) 70%,
      var(--chartBg2) 80%,
      var(--chartBg1) 80%,
      var(--chartBg1) 90%,
      var(--chartBg2) 90%,
      var(--chartBg2) 100%
    );
    padding-top: 20px;
    position: relative;

    @include media('<tablet') {
      background: linear-gradient(
        to right,
        var(--chartBg1) 25%,
        var(--chartBg2) 25%,
        var(--chartBg2) 50%,
        var(--chartBg1) 50%,
        var(--chartBg1) 75%,
        var(--chartBg2) 75%,
        var(--chartBg2) 100%
      );
    }
  }

  &__arrow {
    position: absolute;
    right: 6px;
    top: 50%;
    transform: translateY(-50%);
    -webkit-mask: url(../../images/icons/round-navigation-arrow-drop-up.svg) no-repeat center;
    mask: url(../../images/icons/round-navigation-arrow-drop-up.svg) no-repeat center;
    width: 14px;
    height: 14px;
    z-index: 1;
    background-color: var(--primary-60);

    &.-right {
      transform: translateY(-50%) rotate(-90deg) !important;
    }
  }

  &__rowLabel {
    cursor: pointer;
    width: 146px;
    margin-left: -170px;
    color: var(--gray-60);
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    padding-right: 24px;
    position: relative;
    user-select: none;
    overflow-wrap: break-word;

    &.-orange {
      color: var(--primary-60);
    }

    @include media('<desktop') {
      margin-left: 0;
      text-align: left;
      width: auto;
      padding: 0 0 5px 5px;
    }
  }
  &__bar {
    display: flex;
    flex-grow: 1;

    &.-empty {
      color: var(--gray-60);
      line-height: 1.33;
      font-size: 12px;
    }
  }
  &__data {
    border-radius: 4px;
    height: 18px;
    text-align: center;
    position: relative;
    overflow: hidden;
    font-size: 12px;
    line-height: 18px;
    font-weight: 700;
    color: var(--gray-60);

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      opacity: 0.3;
    }

    &--developing {
      &:after {
        background-color: var(--primay-yellow);
      }
    }
    &--mid {
      &:after {
        background-color: var(--primay-50);
      }
    }
    &--high {
      &:after {
        background-color: var(--green-60);
      }
    }
  }

  &__legend {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
  }
  &__legendItem {
    padding: 0 20px;
    font-size: 12px;
    line-height: 1.7;
    color: var(--gray-60);
    display: flex;
    align-items: center;

    @include media('<tablet') {
      padding: 0 10px;
    }

    &:before {
      content: '';
      opacity: 0.5;
      border-radius: 4px;
      width: 20px;
      height: 9px;
      margin-right: 8px;
    }

    &--developing {
      &:before {
        background-color: var(--primay-yellow);
      }
    }
    &--mid {
      &:before {
        background-color: var(--primay-50);
      }
    }
    &--high {
      &:before {
        background-color: var(--green-60);
      }
    }
  }

  &__note {
    font-size: 12px;
    text-align: center;
    padding-top: 24px;
    color: var(--gray-70);
    display: block;
  }
}
