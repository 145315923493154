body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

body,
span,
a,
div,
button,
option,
p {
  font-family: Rubik;
}

body {
  background-color: var(--background);
}

a:focus-visible,
b:focus-visible,
span:focus-visible,
button:focus-visible,
option:focus-visible,
div:focus-visible {
  outline: var(--orange) auto 1px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  text-decoration: none;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

button {
  transition: 0.3s ease-in-out;
  border: none;
  font-family: inherit;
  cursor: pointer;
}

input {
  &:disabled {
    background-color: inherit;
  }
}

/* Crutch browser white color */
input:-webkit-autofill {
  filter: none;
  transition: background 99999999999999s 0s, color 99999999999999s 0s, background-color 99999999999999s 0s;
}
