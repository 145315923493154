// b-pmProfile
.b-pmProfile {
  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    justify-content: space-between;
  }

  &__pageTitle {
    margin-bottom: 0;

    @include media('<tablet') {
      font-size: 16px;
      font-weight: 400;
      color: var(--gray-60);
    }

    .name {
      @include media('<tablet') {
        display: block;
        font-weight: 700;
        font-size: 24px;
        color: var(--primary-navy);
        margin-top: 5px;
      }
    }
  }

  // infoList
  &__infoList {
    display: flex;
    align-items: center;
    margin-top: 30px;
    font-family: Rubik;
    font-size: 14px;

    @include media('<tablet') {
      flex-direction: column;
      margin: 20px 0 40px;
    }

    li {
      display: flex;
      align-items: center;
      color: var(--gray-60);
    }

    li + li {
      margin-left: 10px;

      @include media('<tablet') {
        margin-left: 0;
        margin-top: 10px;
      }

      &:before {
        content: '';
        width: 1px;
        height: 16px;
        opacity: 0.5;
        background-color: var(--gray-30);
        margin-right: 10px;

        @include media('<tablet') {
          display: none;
        }
      }
    }
  }

  // form
  &__form {
    max-width: 630px;
  }
  &__formRow {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 32px;
    margin-bottom: 15px;

    @include media('<tablet') {
      grid-template-columns: 1fr;
      gap: 0;
    }
  }
  &__formTitle {
    color: var(--primary-navy);
    font-size: 16px;
    font-weight: bold;
    margin: 0 0 32px;

    span {
      font-size: 14px;
      font-weight: 400;
      color: var(--gray-60);
      margin-left: 5px;

      @include media('<tablet') {
        display: block;
        margin: 10px 0 0;
      }
    }
  }
}
