.input_wrapper {
  position: relative;
  //margin-bottom: 10px;
  color: var(--gray-60);
  width: 342px;

  @media screen and (max-width: 824px) {
    width: 314px;
  }
}

.input_placeholder_block {
  display: flex;
  justify-content: center;
  flex-direction: column;
  &:before {
    content: attr(data-content);
    position: absolute;
    top: -7px;
    left: 6px;
    display: flex;
    background: white;
    padding: 0 5px;
    font-size: 14px;
    white-space: nowrap;
    z-index: 9;
    background: var(--background);
  }
}
.input {
  width: calc(100% - 14px);
  min-width: 250px;
  height: 48px;
  font-size: 16px;
  border: 1px solid var(--gray-30);
  border-radius: 5px;
  padding-left: 10px;
  color: var(--gray-60);
  background: var(--background);

  &_disable {
    pointer-events: none;
    opacity: 0.5;
  }
}

.password__show {
  position: absolute;
  right: -10px;
  top: 7px;
  padding: 6px;
}

.input_error {
  border: 1px solid var(--rad);
  color: var(--rad);
}

.input_wrapper_error {
  color: var(--rad);
}

.input_focus {
  box-shadow: 0 0 0 1px var(--primary-60);

  &:focus {
    outline: none;
    border: 1px solid var(--primary-60);
  }
}

.input_label_focus {
  &:before {
    color: var(--primary-60);
  }
}
