.b-status {
  height: 28px;
  flex-shrink: 0;
  line-height: 28px;
  padding-left: 34px;
  padding-right: 20px;
  font-size: 14px;
  letter-spacing: 0.16px;
  display: inline-block;
  border-radius: 8px;
  position: relative;
  z-index: 1;

  &:before {
    position: absolute;
    content: '';
    width: 6px;
    height: 6px;
    background-color: var(--green-60);
    top: 50%;
    transform: translateY(-50%);
    left: 20px;
    border-radius: 6px;
  }

  &.-completed {
    color: var(--green-60);
    background-color: rgba(16, 156, 84, 0.2);

    &:before {
      background-color: var(--green-60);
    }

    &.-locked {
      &:after {
        position: relative;
        top: 3px;
        margin-left: 5px;
        content: url('../../../assets/images/icons/round-action-lock.svg');
      }
    }
  }

  &.-prepCompl {
    color: var(--primary-navy);
    background-color: rgba(0, 0, 45, 0.2); // 2

    &:before {
      background-color: var(--primary-navy);
    }
  }

  &.-darkNavy {
    color: var(--primary-navy);
    background-color: rgba(0, 0, 45, 0.5); // 5

    &:before {
      background-color: var(--primary-navy);
    }
  }

  &.-color2 {
    color: var(--primary-navy);
    background-color: rgba(0, 0, 45, 0.3); // 3

    &:before {
      background-color: var(--primary-navy);
    }
  }

  &.-inPrep {
    color: #edd808;
    background-color: rgba(255, 232, 0, 0.2);
    &:before {
      background-color: #edd808;
    }
  }

  &.-color1 {
    background-color: rgba(255, 107, 0, 0.3);
    color: #ff6b00;
    &:before {
      background-color: #ff6b00;
    }
  }

  &.-color3 {
    color: #3ee48e;
    background-color: rgba(62, 228, 142, 0.2);

    &:before {
      background-color: #3ee48e;
    }
  }

  &.-color5 {
    color: #ea0029;
    background-color: rgba(234, 0, 41, 0.2);

    &:before {
      background-color: #ea0029;
    }
  }

  &.-inProgress {
    color: var(--primary-60);
    background-color: rgba(255, 144, 21, 0.2);

    &:before {
      background-color: var(--primary-60);
    }
  }

  &.-used {
    color: var(--gray-60);
    background-color: rgba(178, 178, 178, 0.2);

    &:before {
      background-color: var(--gray-60);
    }
  }

  &.-red {
    // cb2527
    color: #cb2527;
    background-color: rgba(203, 37, 39, 0.2);

    &:before {
      background-color: #cb2527;
    }
  }
}

.b-tableStatusMark {
  display: inline-flex;
  align-items: center;

  &:after {
    content: '';
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-left: 8px;
    flex-shrink: 0;
  }

  &.completed {
    &:after {
      background-color: var(--green-60);
    }
  }

  &.color1 {
    &:after {
      background-color: #666666;
    }
  }

  &.color2 {
    &:after {
      background-color: #00002d;
    }
  }

  &.color3 {
    &:after {
      background-color: #ea0029;
    }
  }

  &.color4 {
    &:after {
      background-color: #ff6b00;
    }
  }

  &.color5 {
    &:after {
      background-color: #3ee48e;
    }
  }

  &.inactive {
    &:after {
      background-color: var(--gray-30);
    }
  }

  &.red {
    &:after {
      background-color: #fb5b1e;
    }
  }

  &.redest {
    &:after {
      background-color: #cb2527;
    }
  }

  &.preparationCompleted {
    &:after {
      background-color: var(--primary-navy);
    }
  }
  &.inPreparation {
    &:after {
      background-color: var(--primay-yellow);
    }
  }
  &.inProgress {
    &:after {
      background-color: var(--primary-60);
    }
  }
}
