.css-26l3qy-menu {
  z-index: 10;
}

.css-1okebmr-indicatorSeparator {
  width: 0;
}
.css-26l3qy-menu {
  z-index: 9990;
}

.select {
  &_error {
    border-color: red;
  }
}

.select_wrapper {
  position: relative;
  width: 342px;

  @media screen and (max-width: 824px) {
    width: 314px;
  }
}

.input_placeholder_block {
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: var(--gray-60);
  font-size: 16px;
  font-weight: normal;

  &:before {
    font-family: Rubik;
    font-size: 12px;
    content: attr(data-content);
    position: absolute;
    top: -7px;
    left: 6px;
    display: flex;
    background: white;
    padding: 0 5px;
    white-space: nowrap;
    z-index: 9;
    color: var(--gray-60);
    background: var(--background);
  }

  [class*='singleValue'] {
    color: var(--gray-60);
  }
}

.labelFocus {
  &:before {
    color: var(--primary-60);
  }
}

.groupLabelClickable {
  cursor: pointer;

  &_hidden {
    font-size: 0;
    height: 0;
  }
}

.select_item {
  overflow-x: hidden;
}
