.container {
  max-width: 327px;
  margin: 30px;
  text-align: center;
  line-height: 1.5;
}

.wrapper {
  height: 100vh;
}

.button {
  width: 284px;
  border-radius: 16px;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: var(--white);
  margin-bottom: 14px;
}

.title {
  font-size: 20px;
  margin: 0;
  margin-top: 84px;
}

.modal {
  padding: 40px;
  max-height: 250px;

  //h2 {
  //  font-size: 16px;
  //  font-weight: bold;
  //  line-height: 1.5;
  //  color: var(--primary-navy);
  //  text-align: center;
  //}
  //
  //p {
  //  font-size: 16px;
  //  line-height: 1.5;
  //  text-align: center;
  //  color: var(--gray-60);
  //}
}
