.b-chart {
  &__compareBlock {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    &__text {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
    .b-report__avg-subTitle {
      font-size: 14px;
      margin-left: 28px;

      &:before {
        top: -2px;
      }
    }
  }
  &__standards {
    display: flex;
    justify-content: center;
    margin-top: 24px;
    font-size: 12px;
    line-height: 1.5;
    letter-spacing: 0.24px;
    color: var(--gray-60);
  }
  &__chartWrap {
    padding: 40px 18px 40px 50px;
    border-radius: 16px;
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
    max-width: 735px;
    background-color: var(--panel-background);
  }

  &__checkbox {
    color: var(--gray-60);
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
  }

  &__modal {
    padding: 40px 64px 60px 64px;

    p {
      font-size: 16px;
      color: var(--gray-60);
      line-height: 1.25;
      padding: 5px 0;
    }

    &__title {
      font-size: 20px;
      font-weight: bold;
      padding-top: 0;
      span {
        color: var(--primary-navy);
      }
    }
  }

  &__bold {
    color: var(--primary-navy) !important;
    font-weight: bold;
  }
  &__chartAveragesWrap {
    padding: 40px 40px 40px 50px;
    border-radius: 16px;
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
    max-width: 735px;
    background-color: var(--panel-background);
  }
}
