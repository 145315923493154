.b-userControl {
  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    line-height: 1.5;
    color: var(--gray-60);
    margin-bottom: 24px;
  }

  &__container {
    width: 340px;
    margin: 0 auto;
  }

  &__list {
    margin-top: 30px;
    margin-bottom: 20px;
  }

  &__addLinkWrapper {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  &__text {
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 0.16px;
    text-align: center;
    color: var(--gray-60);
  }
}
