.b-resource {
}

.b-resourcePanel {
  padding: 40px;
}

.b-resourceForm {
  &__field {
    margin-bottom: 30px;
    display: grid;
    grid-template-columns: 200px 1fr 1fr;
    grid-column-gap: 20px;
    justify-items: stretch;
    align-items: center;

    &.-description {
      align-items: start;
      grid-template-columns: 200px 1fr;
    }

    &.-file {
      align-items: stretch;
    }

    &.-type {
      grid-template-columns: 2fr 1fr;
    }

    &.-dates {
      grid-template-columns: 200px 160px auto;
    }

    .error {
      font-size: 12px;
      color: var(--rad);
      line-height: 16px;
    }
  }

  &__select {
    margin-bottom: -21px;
  }

  &__selfEnd {
    justify-self: end;
    .check-box-body {
      width: auto;
    }
  }

  &__publicLabelTooltip {
    width: 180px;
  }

  &__publicLabel {
    display: flex;
    align-items: center;
    color: var(--primary-navy);

    .b-resourceCard__headingIcon {
      margin-left: 0;
      margin-right: 5px;
    }
  }

  &__lastUpdate {
    display: grid;
    grid-template-columns: 100px 100px;
    grid-column-gap: 20px;
  }
  &__fieldInput {
    font-family: Rubik;
    font-size: 14px;
    color: var(--gray-60);
    font-weight: 600;
    background-color: transparent;
    width: 100%;
    line-height: 1.43;
    border: 0;
    outline: none;
    resize: none;

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    &::placeholder {
      font-weight: 400;
      font-family: Rubik;
      color: var(--text-color-10);
    }
  }

  &__fileName {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.43;
    color: var(--gray-60);

    .b-button-icon {
      margin-left: 10px;
    }
  }

  &__thumbnail {
    height: 55px;
    margin-right: 10px;
  }

  &__thumbnailWrapper {
    display: flex;
    align-items: center;
  }

  &__buttons {
    max-width: 550px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }
}

.b-resourceNote {
  margin-top: 10px;
  margin-bottom: 50px;

  &__open {
    position: relative;
    font-size: 14px;
    padding-right: 20px;
    line-height: 1.43;
    letter-spacing: 0.16px;
    color: var(--primary-60);

    &:after {
      position: absolute;
      content: '';
      border-style: solid;
      top: 5px;
      transform: rotate(90deg);
      border-width: 0 5px 6px 5px;
      border-color: transparent transparent var(--primary-60) transparent;
      margin-left: 6px;
    }

    &.-opened {
      &:after {
        transform: rotate(180deg);
      }
    }
  }

  &__list {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  &__item {
    padding: 20px;
    margin-bottom: 10px;
    background-color: var(--panel-color-10);
    display: grid;
    grid-template-columns: 130px auto 90px;
    grid-column-gap: 20px;
    justify-items: stretch;
    border-radius: 3px;
  }

  &__itemDate {
    font-size: 12px;
    line-height: 1.67;
    letter-spacing: 0.14px;
    color: var(--gray-60);
  }

  &__itemUser {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.43;
    letter-spacing: 0.16px;
    color: var(--gray-60);
  }

  &__itemText {
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 0.16px;
    color: var(--gray-60);
  }

  &__controls {
    display: grid;
    grid-template-columns: 20px 20px;
    grid-column-gap: 20px;
    justify-self: end;
    align-self: center;
  }

  &__inputWrapper {
    position: relative;
  }

  &__input {
    display: block;
    width: 100%;
    box-sizing: border-box;
    font-family: Rubik;
    font-size: 14px;
    color: var(--primary-navy);
    resize: none;
    background-color: transparent;
    border: solid 1px #b2b2b2;
    padding: 15px 15px 30px;
    border-radius: 4px;
    outline: none;

    &::placeholder {
      font-family: Rubik;
      color: var(--text-color-10);
    }
  }

  &__add {
    position: absolute;
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 0.16px;
    color: var(--primary-60);
    display: block;
    padding: 8px 10px;
    bottom: 1px;
    right: 10px;
    background-color: transparent;

    &.-disabled {
      opacity: 0.6;
      pointer-events: none;
    }
  }
}

// b-resourceListItem
.b-resourceListItems {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 40px;

  @media screen and (min-width: 1360px) {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: 30px;
  }

  @include media('<tablet') {
    display: block;
  }

  &__wrapper {
    margin: -40px;
  }
}
.b-resourceCard {
  width: 100%;
  max-width: calc(50% - 10px);
  margin-bottom: 20px;
  padding: 18px 14px 16px 18px;
  border-radius: 16px;
  box-sizing: border-box;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
  background-color: var(--panel-background);
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 1360px) {
    max-width: 490px;
    margin-bottom: 0px;
  }

  @include media('<tablet') {
    max-width: 100%;
  }

  &__heading {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-bottom: 1px solid var(--gray-20);
    padding-bottom: 10px;
    margin-bottom: 13px;
  }
  &__title {
    color: var(--primary-navy);
    font-size: 16px;
    line-height: 1.4;
    font-weight: 700;
  }
  &__headingIcon {
    background-color: var(--primary-navy);
    -webkit-mask: url(../../../assets/images/icons/public_black_18dp.svg) no-repeat center;
    mask: url(../../../assets/images/icons/public_black_18dp.svg) no-repeat center;
    width: 18px;
    height: 18px;
    background-size: 18px 18px;
    flex-shrink: 0;
    display: block;
    margin-left: 10px;
  }
  &__infoPanel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    flex-wrap: wrap;
  }
  &__infoPanelLeft {
    display: flex;
    align-items: center;

    @include media('<tablet') {
      margin-bottom: 15px;
    }
  }
  &__infoPanelRight {
    display: flex;
    align-items: center;

    @include media('<tablet') {
      margin-bottom: 15px;
      justify-content: flex-end;
    }
  }
  &__categoryIcon {
    margin-right: 20px;
    background-repeat: no-repeat;
    flex-shrink: 0;

    &--audio {
      background-image: url(../../../assets/images/icons/audio.svg);
      background-size: 22px 24px;
      width: 22px;
      height: 24px;
    }
    &--pdf {
      background-image: url(../../../assets/images/icons/pdf.svg);
      background-size: 22px 24px;
      width: 21px;
      height: 24px;
    }
    &--video {
      background-image: url(../../../assets/images/icons/video.svg);
      background-size: 20px 25px;
      width: 20px;
      height: 25px;
    }
    &--journal {
      background-image: url(../../../assets/images/icons/journal.svg);
      background-size: 29px 24px;
      width: 29px;
      height: 24px;
    }
    &--blog {
      background-image: url(../../../assets/images/icons/blog.svg);
      background-size: 29px 24px;
      width: 29px;
      height: 24px;
    }
    &--audio2 {
      background-image: url(../../../assets/images/icons/audio2.svg);
      background-size: 20px 25px;
      width: 20px;
      height: 25px;
    }
    &--docs {
      background-image: url(../../../assets/images/icons/docs.svg);
      background-size: 29px 30px;
      width: 29px;
      height: 30px;
    }
    &--gslides {
      background-image: url(../../../assets/images/icons/gslides.svg);
      background-size: 22px 24px;
      width: 22px;
      height: 24px;
    }
  }
  &__logo {
    margin-right: 20px;
  }
  &__date {
    color: var(--gray-60);
    font-size: 12px;
    margin-left: 20px;
  }
  .b-status {
    margin-left: 20px;
  }
  &__detailsLink {
    color: var(--gray-60);
    font-size: 14px;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  p {
    font-size: 14px;
    line-height: 1.5;
    color: var(--gray-60);
    margin: 0 0 15px;
  }
  &__dList {
    dl {
      margin: 0 0 14px;
      dt {
        display: inline;
        color: var(--primary-60);
        font-size: 14px;
        line-height: 1.4;
        font-weight: 700;
        margin: 0;
        padding-right: 4px;
      }
      dd {
        overflow-wrap: break-word;
        display: inline;
        font-size: 14px;
        margin: 0;
        line-height: 1.4;
        color: var(--gray-60);
      }
    }
  }
  &__tools {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: auto;
    margin-right: -9px;

    .b-tableTool {
      &.-mr {
        margin-right: 0;
      }
    }
  }
  &__share {
    background: url(../../../assets/images/icons/share.svg) no-repeat;
    background-size: 18px 20px;
    width: 18px;
    min-width: 18px;
    height: 20px;
    cursor: pointer;
    display: block;
    margin: 0 9px;
  }
  &__bookmark {
    background-image: url(../../../assets/images/icons/bookmark_border_orange2_24dp.svg);
    background-size: 18px 18px;
    background-repeat: no-repeat;
    width: 18px;
    height: 18px;
    display: block;
    cursor: pointer;
    margin: 0 9px;
    //margin-left: 18px;

    &--active {
      background-image: url(../../../assets/images/icons/bookmark_border_orange_24dp.svg);
    }
  }
}

// b-resourceView
.b-resourceView {
  max-width: 784px;

  &__container {
    color: rgba(85, 85, 85, 1);
    font-size: 14px;
    line-height: 1.64;
  }

  &__header {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
  }

  &__globeIcon {
    display: block;
    background-color: var(--primary-navy);
    -webkit-mask: url(../../../assets/images/icons/public_black_18dp.svg) no-repeat center;
    mask: url(../../../assets/images/icons/public_black_18dp.svg) no-repeat center;

    width: 18px;
    height: 18px;
  }

  &__openResource {
    margin-top: 10px;
    display: block;

    .b-link-icon {
      font-size: 16px;
    }
  }

  &__backlinkContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;

    @include media('<tablet') {
      display: block;
      margin-bottom: 25px;
    }

    .b-page-back-link {
      margin-bottom: 0;
    }

    .b-resourceView__date {
      @include media('<tablet') {
        margin-top: 20px;
        display: block;
        text-align: center;
        margin-left: 0;
      }
    }
  }

  &__belContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  &__heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-bottom: 10px;

    @include media('<tablet') {
      flex-direction: column;
      align-items: initial;
      margin-bottom: 20px;
      margin-top: 5px;
    }

    .b-resourceCard__tools {
      position: absolute;
      bottom: -30px;
      right: 0;

      @include media('<tablet') {
        position: static;
      }
    }
  }

  &__headingleft {
    @include media('<tablet') {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__headingRight {
    @include media('<tablet') {
      order: -1;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 23px;
    }
  }
  &__date {
    font-size: 16px;
    line-height: 1.18;
    font-weight: 500;
    color: var(--text-color-10);
    margin-left: 20px;

    &.-org {
      @include media('<tablet') {
        text-align: left;
      }
    }
  }
  &__dList {
    overflow: hidden;
    font-size: 16px;
    line-height: 1.25;
    margin: 0 0 5px;

    @include media('<tablet') {
      font-size: 14px;
    }

    dt {
      color: var(--primary-60);
      font-weight: 700;
      padding: 0 4px 0 0;
      float: left;
      clear: both;
    }
    dd {
      color: var(--gray-60);
      margin: 0 0 14px;
      min-height: 20px;

      &:last-child {
        margin-bottom: 0;
      }

      @include media('<tablet') {
        margin-bottom: 10px;
      }
    }
  }
  &__box {
    padding: 35px 30px 50px;
    border-radius: 16px;
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
    background-color: var(--panel-background);

    .rpv-core__inner-pages {
      overflow: visible !important;
    }

    @include media('<tablet') {
      padding: 35px 25px 30px;
    }
  }
  &__description {
    color: var(--gray-60);
    font-size: 16px;
    margin-top: -10px;
    margin-bottom: 20px;
  }
  &__boxHolder {
    padding: 35px 35px 0;
    color: var(--primary-navy);

    @include media('<tablet') {
      padding: 25px 0 0;
    }
  }
  &__illustration {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    border-radius: 20px;
    height: auto;

    @include media('<tablet') {
      border-radius: 15px;
    }
  }
  blockquote {
    font-style: italic;
    text-align: center;
    font-weight: 300;
    margin: 30px 47px;

    @include media('<tablet') {
      margin: 0;
    }
  }
  p {
    margin: 0 0 25px;
  }
  &__link {
    color: rgba(99, 124, 239, 1);
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.tagList {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 5px -4px 10px;

  @include media('<tablet') {
    padding: 0;
  }

  &__item {
    border-radius: 8px;
    background-color: var(--bd-color2);
    text-align: center;
    font-size: 12px;
    color: var(--tag-color);
    padding: 2px 10px;
    margin: 4px;
    white-space: nowrap;
  }

  &__link {
    color: inherit;
  }
}
