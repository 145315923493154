.button {
  font-family: Rubik;
  border-radius: 16px;
  height: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: max-content;
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
  position: relative;
  user-select: none;

  &-mh {
    margin: 0 10px;
  }

  &-icon {
    flex-direction: row;
  }

  &-small {
    font-size: 16px !important;
    border-radius: 16px;
    width: 128px;
  }

  &-middle {
    font-size: 16px !important;
    border-radius: 16px;
    width: 148px;
  }

  &-large {
    font-size: 16px !important;
    border-radius: 16px;
    width: 158px;
  }
  &-x {
    font-size: 16px !important;
    border-radius: 16px;
    width: 284px;
  }

  &-disabled {
    opacity: 0.7;
    pointer-events: none;
  }

  &-alignCenter {
    display: flex;
    margin: 0 auto;
  }
}

.long-button {
  width: 335px;
}

.orange {
  border: 0.5px solid var(--orange);
  background-color: var(--orange);
  font-size: 20px;
  font-weight: bold;
  color: var(--white-black);

  &:active {
    background-color: var(--white);
    color: var(--orange);
  }
}

.orange-light {
  border: 0.5px solid var(--primary-60);
  background-color: var(--primary-60);
  font-size: 20px;
  font-weight: bold;
  color: var(--white-black);

  &:active {
    background-color: var(--white);
    color: var(--primary-60);
  }
}

.white {
  border: 0.5px solid var(--white-orange);
  background-color: transparent;
  font-size: 20px;
  font-weight: bold;
  color: var(--primary-60);

  &:active {
    background-color: var(--orange);
    color: var(--white-black);
  }

  &.button-disabled {
    opacity: 1;
    color: var(--gray-30);
  }
}

.transparency {
  border: 0.5px solid var(--background);
  background-color: transparent;
  font-size: 20px;
  font-weight: bold;
  color: var(--primary-60);

  &:active {
    background-color: var(--primary-60);
    color: var(--white-black);
  }
}

.bordered {
  border: solid 2px var(--primary-60);
  background-color: transparent;
  font-size: 20px;
  font-weight: bold;
  color: var(--primary-60);

  img {
    padding-right: 5px;
  }

  &:disabled {
    color: var(--gray-20) !important;
    opacity: 1 !important;
    border-color: var(--gray-20);
  }
}

.primary {
  border: 0.5px solid var(--primary-60);
  background-color: var(--primary-60);
  font-size: 16px;
  font-weight: bold;
  color: var(--white-black);

  &:active {
    background-color: var(--white);
    color: var(--primary-60);

    .b-button-icon {
      transition: 0.3s ease-in-out;
      background-color: var(--primary-60);
    }
  }
}
