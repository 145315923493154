.b__list {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.list {
  width: 185px;
  list-style: disc;

  &__item {
    &__wrap {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    &__text {
      list-style: disc;
    }

    &__range {
      color: var(--gray-60);
      text-align: center;
      width: 50%;
    }
  }
}

.gray {
  color: var(--gray-60);
}

.black {
  color: var(--primary-navy);
}

.yellow {
  color: #edd808;
}

.orange {
  color: var(--primary-60);
}

.green {
  color: var(--green-60);
}

.modal {
  padding: 60px 40px;
}
