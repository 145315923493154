.myAccount {
  display: flex;
  flex-direction: column;
  align-items: center;

  $block: &;

  &__title {
    font: 700 20px/1.2 Rubik;
    color: var(--gray-60);
    text-align: center;
    margin-bottom: 28px;
  }
  &__userInfo {
    padding: 32px 24px;
    margin: 0 auto 16px;
    min-width: 260px;
    border-radius: 16px;
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
    background: var(--panel-background);
    box-sizing: border-box;
    width: 100%;
  }
  &__userName {
    font: 700 20px/1.2 Rubik;
    color: var(--primary-navy);
    text-align: center;
    margin-bottom: 4px;
    display: block;
  }
  &__userEmail {
    font: 400 14px/1.43 Rubik;
    color: var(--gray-60);
    letter-spacing: 0.16px;
    text-align: center;
    display: block;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__userText {
    font: 400 12px/1.5 Rubik;
    color: var(--gray-30);
    letter-spacing: 0.24px;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 4px;
    display: block;
  }
  &__userSecondEmail {
    font: 400 14px/1.43 Rubik;
    color: var(--gray-60);
    letter-spacing: 0.16px;
    text-align: center;
    display: block;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__reset {
    font: 400 14px/1.43 Rubik;
    color: var(--gray-60);
    text-align: center;
    text-decoration: underline;
    &:hover {
      color: var(--orange);
    }
    &:active {
      color: var(--orange);
    }
  }
  &__linkWrapper {
    padding: 46px 0 0;
    &:after {
      margin-top: 36px;
      margin-bottom: 36px;
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      opacity: 0.5;
      background: var(--gray-30);
    }
  }
  &__link {
    margin-bottom: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 280px;
    transition: 0.4s;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  &__text {
    font: 400 16px/1.5 Rubik;
    color: var(--gray-60);

    #{$block}__link:hover & {
      color: var(--orange);
    }
    #{$block}__link:active & {
      color: var(--orange);
    }
  }
  &__icon {
    margin-right: 8px;
    width: 20px;
    height: 20px;
    background-color: var(--gray-30);

    #{$block}__link:hover & {
      background-color: var(--orange);
    }
    #{$block}__link:active & {
      background-color: var(--orange);
    }
  }
  &__icon_profile {
    -webkit-mask: url(../../../../assets/images/icons/round-action-account-circle.svg) no-repeat center;
    mask: url(../../../../assets/images/icons/round-action-account-circle.svg) no-repeat center;
  }
  &__icon_settings {
    -webkit-mask: url(../../../../assets/images/icons/round-action-settings.svg) no-repeat center;
    mask: url(../../../../assets/images/icons/round-action-settings.svg) no-repeat center;
  }
  &__icon_termsConditions {
    -webkit-mask: url(../../../../assets/images/icons/round-action-description.svg) no-repeat center;
    mask: url(../../../../assets/images/icons/round-action-description.svg) no-repeat center;
  }
  &__icon_privacyPolicy {
    -webkit-mask: url(../../../../assets/images/icons/round-editor-insert-drive-file.svg) no-repeat center;
    mask: url(../../../../assets/images/icons/round-editor-insert-drive-file.svg) no-repeat center;
  }
  &__icon_support {
    -webkit-mask: url(../../../../assets/images/icons/round-action-contact-support.svg) no-repeat center;
    mask: url(../../../../assets/images/icons/round-action-contact-support.svg) no-repeat center;
  }
  &__bottomWrapper {
    min-width: 280px;
  }
  &__logout {
    font: 400 16px/1.5 Rubik;
    color: var(--gray-60);
    text-decoration: underline;
    &:hover {
      color: var(--orange);
    }
    &:active {
      color: var(--orange);
    }
  }
  &__version {
    font: 400 12px/1.5 Rubik;
    color: var(--gray-60);
    margin-top: 48px;
    display: block;
    //&:hover {
    //  color: var(--orange);
    //}
    //&:active {
    //  color: var(--orange);
    //}
  }
}
