.container {
  margin-top: 150px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.mobileContainer {
  margin-top: 20px;
}

.title {
  margin: 48px 0 20px 0;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: var(--primary-navy);
}

.text {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: var(--gray-60);
  max-width: 388px;
}
