.b-report {
  &__tabsWrapper {
    margin-bottom: 35px;
  }

  &__avg-subTitle {
    font-size: 20px;
    line-height: 24px;
    &:before {
      right: -8px !important;
      top: 2px;
      //left: auto;
      -webkit-mask: url(../../../assets/images/icons/open-in-new-fill-0-wght-400-grad-0-opsz-48.svg) no-repeat
        center / 16px;
      mask: url(../../../assets/images/icons/open-in-new-fill-0-wght-400-grad-0-opsz-48.svg) no-repeat center /
        16px;
    }
  }

  &__notification {
    background-image: linear-gradient(to bottom, #ff9015, #ffc32f 99%);
    margin: 0 -20px;
    padding: 24px 20px 85px 20px;
    color: var(--white);
    text-align: center;
    font-size: 14px;
    font-family: Rubik;
    position: relative;
  }
  &__notificationTitle {
    font-size: 24px;
    display: block;
    margin-bottom: 5px;
  }
  &__arrow {
    margin: -30px -20px 10px;
    position: relative;
    height: 30px;
    background-color: white;
    border-radius: 20px 20px 0 0;

    &:before {
      content: '';
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 100%;
      background: url(../../images/icons/arrow.png) no-repeat center;
      width: 103px;
      height: 31px;
    }
  }
  &__pageTitle {
    position: relative;
    z-index: 1;

    @include media('<tablet') {
      margin-bottom: 8px;
    }
  }
  &__pageTitleLabel {
    @include media('<tablet') {
      display: none;
    }
  }
  &__orgName {
    color: var(--primary-60);
    margin-left: 8px;

    @include media('<tablet') {
      color: var(--primary-navy);
      font-size: 20px;
    }
  }
  &__pageSubTitle {
    color: var(--primary-navy);
    position: relative;
    font-size: 20px;
    font-weight: 700;
    margin: 0 0 53px;

    @include media('<widescreen') {
      display: flex;
    }

    @include media('<tablet') {
      color: var(--gray-60);

      span {
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        text-align: center;
      }
    }
  }
  &__backBtn {
    position: absolute;
    left: -42px;
    top: 0;
    cursor: pointer;

    .back-icon {
      width: 24px;
      height: 24px;
      background-color: var(--primary-navy);
      -webkit-mask: url('../../images/icons/round-navigation-arrow-back.svg') no-repeat center;
      mask: url('../../images/icons/round-navigation-arrow-back.svg') no-repeat center;
    }

    //&:focus-visible {
    //  background-color: red;
    //}

    @include media('<widescreen') {
      position: static;
      margin-right: 8px;
    }
    //@include media('<tablet') {
    //  display: none;
    //}
  }

  // report heading
  &__heading {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 15px;
  }

  &__headingTitle {
    font-family: Rubik;
    font-size: 16px;
    color: var(--gray-60);
    margin: 0 auto 0 0;
  }

  &__headingLink {
    color: var(--primary-60);
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }

  &__btnInfo {
    -webkit-mask: url(../../images/icons/info-outlined.svg) no-repeat center;
    mask: url(../../images/icons/info-outlined.svg) no-repeat center;
    width: 17px;
    height: 17px;
    background-color: var(--gray-30);
    padding: 0;
    margin-left: 5px;

    &:focus-visible {
      outline: none;
    }
  }

  p {
    font-size: 14px;
    line-height: 1.4;
    color: var(--gray-60);
    margin: 0 0 32px;
  }

  &__holder {
    display: flex;
  }
  &__frame {
    display: flex;
    flex-direction: column;
    min-width: 500px;
    width: 100%;

    @include media('<desktop') {
      min-width: 100%;
    }
  }

  &__statusWrap {
    width: 150px;
    display: flex;
  }

  // status
  &__status {
    display: inline-flex;
    border-radius: 8px;
    padding: 4px 12px;
    font-family: Rubik;
    font-size: 14px;
    display: flex;
    align-items: center;
    margin-right: 10px;

    &:before {
      content: '';
      width: 6px;
      height: 6px;
      border-radius: 50%;
      margin-right: 8px;
    }

    &.-inquiring {
      background-color: rgba(102, 102, 102, 0.2);
      color: var(--gray-60);

      &:before {
        background-color: var(--gray-60);
      }
    }
    &.-emerging {
      background-color: rgba(0, 0, 45, 0.2);
      color: var(--primary-navy);

      &:before {
        background-color: var(--primary-navy);
      }
    }
    &.-developing {
      background-color: rgba(237, 216, 8, 0.2);
      color: #edd808;

      &:before {
        background-color: #edd808;
      }
    }
    &.-expanding {
      background-color: rgba(255, 144, 21, 0.2);
      color: var(--primary-60);

      &:before {
        background-color: var(--primary-60);
      }
    }
    &.-refining {
      background-color: rgba(16, 156, 84, 0.2);
      color: var(--green-60);

      &:before {
        background-color: var(--green-60);
      }
    }
  }

  // accordion
  &__accordionItem {
    padding: 28px 40px;
    border-radius: 16px;
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
    background-color: var(--panel-background);
    margin-bottom: 15px;
    box-sizing: border-box;

    &:last-child {
      margin-bottom: 0;
    }

    @include media('<tablet') {
      padding: 20px;
    }
  }
  &__accordionOpener {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__accordionOpenerTitle {
    color: var(--primary-navy);
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;

    @include media('<tablet') {
      color: var(--gray-60);
    }
  }
  &__chartLink {
    font-size: 14px;
    color: var(--primary-60);
    background-color: transparent;
    text-decoration: underline;
    display: flex;
    align-items: center;

    &:hover {
      color: var(--primary-navy);
    }

    &:before {
      content: '';
      -webkit-mask: url(../../images/icons/round-editor-insert-chart-outlined.svg) no-repeat center;
      mask: url(../../images/icons/round-editor-insert-chart-outlined.svg) no-repeat center;
      width: 18px;
      height: 18px;
      background-color: var(--primary-navy);
      margin-right: 10px;
    }
  }
  &__accordionContent {
    border-top: 1px solid rgba(204, 204, 204, 0.5);
    padding-top: 32px;
    margin-top: 32px;
  }
  &__detailsList {
    column-count: 2;
    column-gap: 35px;
    margin: 0;
    font-family: Rubik;
    font-size: 14px;

    @include media('<tablet') {
      column-count: 1;
      column-gap: 0;
    }

    dt {
      float: left;
      clear: both;
      color: var(--gray-60);
    }
    dd {
      font-weight: 700;
      text-align: right;
      color: var(--gray-60);
      margin-bottom: 15px;
    }
  }

  // chartBlock
  &__chartBlock {
    margin-bottom: 40px;
  }
  &__chartBlockHolder {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;

    @include media('<tablet') {
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
  &__chart {
    flex-shrink: 0;
    margin: 0 35px;

    @include media('<tablet') {
      width: 100%;
      order: 1;
      margin: 25px 0 0;
    }
  }
  &__chartContainer {
    width: 150px;
    height: 150px;

    @include media('<tablet') {
      margin: 0 auto;
    }

    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }
  &__chartTitle {
    color: var(--primary-navy);
    font-size: 20px;

    @include media('<tablet') {
      font-size: 16px;
    }
  }
  &__chartRow {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
  }
}

// reportItem
.b-reportItemList {
  counter-reset: newCounter;
  margin-bottom: 42px;
}
.b-reportItem {
  padding: 20px 20px;
  box-sizing: border-box;
  border-radius: 16px;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
  background-color: var(--panel-background);
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  min-height: 100px;

  @include media('<tablet') {
    min-height: 0;
    padding-top: 26px;
    padding-bottom: 26px;
  }

  &__name {
    flex-shrink: 0;
    font-size: 16px;
    font-weight: 700;
    display: flex;
    margin-right: 15px;
    width: 100px;
    color: var(--primary-navy);

    @include media('<tablet') {
      color: var(--gray-60);
    }

    &::before {
      counter-increment: newCounter;
      content: counters(newCounter, '.') ' ';
      width: 20px;
      height: 20px;
      background-color: var(--primary-60);
      border-radius: 50%;
      text-align: center;
      line-height: 20px;
      font-family: Rubik;
      font-size: 12px;
      font-weight: bold;
      color: var(--white);
      margin-right: 8px;
      flex-shrink: 0;
    }
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    font-family: Rubik;
    font-size: 12px;
    color: var(--gray-60);
    overflow: auto;
    min-width: 1px;

    @include media('<tablet') {
      display: none;
    }

    li {
      text-align: center;
      margin: 0 8px;
      width: 90px;
    }
  }
  &__num {
    color: var(--primay-50);
    font-size: 20px;
    display: block;
    margin: 2px 0;
  }
  &__arrow {
    -webkit-mask: url(../../images/icons/round-hardware-keyboard-arrow-right.svg) no-repeat center;
    mask: url(../../images/icons/round-hardware-keyboard-arrow-right.svg) no-repeat center;
    width: 24px;
    height: 24px;
    background-color: var(--gray-60);
    margin-left: auto;
    padding: 0;

    &:focus-visible {
      background-color: var(--orange);
    }
  }
  &__frame {
    margin-left: auto;
    display: flex;
    align-items: center;
  }
  &__text {
    max-width: 429px;
    padding-left: 30px;
    p {
      margin: 0 0 5px;
    }
    a {
      color: var(--primary-60);
      text-decoration: underline;
      font-size: 14px;
      &:hover {
        text-decoration: none;
      }
    }
  }
}

// reportItem2
.b-reportItem2 {
  padding: 20px;
  box-sizing: border-box;
  border-radius: 16px;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
  background-color: var(--panel-background);
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  min-height: 100px;

  @include media('<tablet') {
    flex-wrap: wrap;
    min-height: 0;
    padding-top: 28px;
    padding-bottom: 28px;
  }

  &__name {
    display: flex;
    flex-shrink: 0;
    font-family: Rubik;
    font-size: 16px;
    font-weight: 700;
    width: Max(145px, 15%);
    margin-right: 30px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
    color: var(--primary-navy);

    @include media('<tablet') {
      width: Max(145px, 80%);
      margin-right: 15px;
    }

    &::before {
      counter-increment: newCounter;
      content: counters(newCounter, '.') ' ';
      width: 20px;
      height: 20px;
      background-color: var(--primary-60);
      border-radius: 50%;
      text-align: center;
      line-height: 20px;
      font-family: Rubik;
      font-size: 12px;
      font-weight: bold;
      color: var(--white);
      margin-right: 8px;
      flex-shrink: 0;
    }
  }

  &__num {
    color: var(--primay-50);
    font-size: 20px;
    margin-left: auto;
    min-width: 60px;
  }
  &__text {
    flex-grow: 1;
    padding-left: 10px;

    @include media('<tablet') {
      margin-top: 17px;
      padding-left: 0;
      width: 100%;
    }

    p {
      margin: 0 0 5px;
    }
    a {
      color: var(--primary-60);
      text-decoration: underline;
      font-size: 14px;
      cursor: pointer;
      &:hover {
        text-decoration: none;
      }
    }
  }
}

// reportItem3
.b-reportItem3 {
  padding: 28px;
  box-sizing: border-box;
  border-radius: 16px;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
  background-color: var(--panel-background);
  margin-bottom: 12px;
  display: flex;

  @include media('<tablet') {
    flex-direction: column;
  }

  &__left {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-basis: 160px;
    flex-shrink: 0;
    margin-right: 16px;
    word-break: break-word;

    @include media('<tablet') {
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 20px;
      width: 100%;
      flex-basis: initial;
    }
  }
  &__right {
    flex-grow: 1;
  }
  &__name {
    color: var(--primary-navy);
    font-family: Rubik;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 10px;

    @include media('<tablet') {
      margin: 0;
    }
  }
  &__num {
    color: var(--primay-50);
    font-size: 32px;

    @include media('<tablet') {
      font-size: 20px;
    }
  }
  p {
    margin: 0;
  }
}

// nextSteps
.b-nextSteps {
  max-width: 735px;
  display: flex;
  border-radius: 16px;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
  background-color: var(--panel-background);
  padding: 40px;
  font-family: Rubik;
  font-size: 14px;
  color: var(--gray-60);
  box-sizing: border-box;
  margin-bottom: 55px;

  &:last-child {
    margin-bottom: 0;
  }

  @include media('<tablet') {
    flex-direction: column;
    padding: 28px;
  }

  p {
    margin: 0 0 20px;
  }

  &__illustration {
    flex-shrink: 0;
    margin-right: 18px;

    @include media('<tablet') {
      margin: 0 auto 18px;
    }
  }

  &__subtitle {
    display: block;
    letter-spacing: 0.16px;
    color: var(--primary-60);
    font-family: Rubik;
    font-size: 14px;
    margin-bottom: 8px;
  }

  &__title {
    font-size: 20px;
    color: var(--primary-navy);
    display: block;
    margin: 0 0 15px;

    &.-center {
      text-align: center;
    }
  }

  &__listIcon {
    width: 54px;
    height: 36px;
    margin-bottom: 17px;
    background-color: var(--primary-navy);

    @include media('<tablet') {
      margin: 0 20px 0 0;
    }

    &.-item2 {
      -webkit-mask: url('../../images/icons/icon-step2.svg') no-repeat center;
      mask: url('../../images/icons/icon-step2.svg') no-repeat center;
    }
    &.-item1 {
      -webkit-mask: url('../../images/icons/icon-step1.svg') no-repeat center;
      mask: url('../../images/icons/icon-step1.svg') no-repeat center;
    }
    &.-item3 {
      -webkit-mask: url('../../images/icons/icon-step3.svg') no-repeat center;
      mask: url('../../images/icons/icon-step3.svg') no-repeat center;
    }
  }

  &__list {
    display: flex;
    font-family: Rubik;
    font-size: 14px;
    color: var(--gray-60);
    margin: 32px 0;

    @include media('<tablet') {
      flex-direction: column;
      margin-bottom: 15px;
    }

    li {
      display: flex;
      flex-direction: column;
      flex: 1;
      margin-right: 20px;

      @include media('<tablet') {
        margin: 0 0 40px;
        flex-direction: row;
        align-items: center;
      }
    }
  }

  .button {
    font-size: 16px;

    @include media('<tablet') {
      margin: 0 auto;
    }
  }

  &__linkBtn {
    border-radius: 16px;
    height: 48px;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    align-items: center;
    justify-content: center;
    width: max-content;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
    border: 0.5px solid var(--primary-60);
    background-color: var(--primary-60);
    font-weight: bold;
    color: var(--white);
  }

  &__holder {
    display: flex;
    flex-direction: column;

    &.-center {
      justify-content: center;
      align-items: center;
    }
  }
}

// b-reportScore
.b-reportScore {
  color: var(--gray-30);
  font-family: Rubik;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 16px;

  &__num {
    color: var(--primay-50);
  }
}

// b-reportTable
.b-reportTableHolder {
  width: 100%;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
  background-color: var(--white);
  border-radius: 16px;
  overflow: auto;
}
.b-reportTable {
  border-collapse: collapse;
  width: 100%;
  min-width: 735px;

  th,
  td {
    font-family: Rubik;
    font-size: 12px;
    padding: 15px 10px;
    min-width: 82px;

    &:first-child {
      padding-left: 28px;
    }
    &:last-child {
      padding-right: 28px;
    }
  }
  td {
    color: var(--gray-60);

    img {
      display: block;
      margin: 0 auto;
    }
  }
  th {
    color: var(--primary-navy);
    text-align: left;
  }

  thead {
    background-color: var(--panel-background);
    th {
      color: var(--gray-30);
      font-weight: 400;

      b {
        display: block;
      }
    }
  }
  tbody {
    tr {
      background-color: var(--panel-background);
    }
    tr:nth-child(odd) {
      background-color: var(--bg-color-tr-2);
    }
    th {
      vertical-align: top;
    }
  }
}

.CircularProgressbar-text {
  font-weight: bold;
  font-size: 30px;
}

.b-reportElm {
  &__myReportInfo {
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 0.16px;
    color: var(--gray-60);
    margin-bottom: 40px;

    p {
      margin-bottom: 15px;
    }
  }
}
