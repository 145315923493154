@import '../../../../assets/styles/include-media';

.form {
  display: flex;
  margin-top: 0;
  max-width: 770px;

  @include media('<desktop') {
    max-width: 100%;
    flex-direction: column;
    margin-bottom: 30px;
  }

  &__part {
    width: 100%;
    padding-right: 30px;

    @include media('<desktop') {
      width: 100%;
      padding: 0;
    }

    > * {
      width: 100% !important;
    }
    input {
      min-width: 0;
    }
  }
  &__additional {
    display: flex;
    max-width: 770px;
    margin: 20px 0 0 0;

    @include media('<desktop') {
      max-width: 100%;
      flex-direction: column;
    }
  }
  &__additionalCol {
    width: 50%;
    padding-right: 30px;

    &:last-child {
      @include media('<tablet') {
        text-align: center;
      }
    }

    @include media('<desktop') {
      width: 100%;
      padding: 0 0 30px;
    }
  }
  &__buttons {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  &__suborganization {
    //padding: 0 15px;
    &_opener {
      display: flex;
      align-items: center;
      //justify-content: space-between;
      color: var(--gray-60);
      font-size: 20px;
      font-weight: bold;
      font-family: Rubik;
      white-space: nowrap;

      span {
        display: flex;
      }

      .icon {
        width: 10px;
        height: 10px;
        background-color: red;
        -webkit-mask: url(../../../../assets/images/icons/arrow-up.svg) no-repeat center;
        mask: url(../../../../assets/images/icons/arrow-up.svg) no-repeat center;
        background-color: #1d1d1d;
      }
    }

    &_list {
      color: var(--gray-60);
      font-size: 16px;
      font-family: Rubik;

      li {
        padding: 15px 0;
      }
    }
  }

  &__addLink {
    color: var(--primary-60);
    font-family: Rubik;
    cursor: pointer;
    font-weight: 400;
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    padding: 0 15px;
    margin-bottom: 60px;
    margin-top: 15px;

    &:before {
      content: '+';
      background-color: var(--primary-60);
      color: #fff;
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
      text-align: center;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      margin-right: 8px;
    }
  }
  &__affiliation_btn {
    width: 100%;

    .form__addLink {
      display: flex;
      justify-content: center;
    }
  }
}

// toolsPanel
.toolsPanel {
  display: flex;
  align-items: center;
  font-family: Rubik;
  font-size: 14px;
  margin-bottom: 20px;
  position: relative;

  &_button {
    background-color: transparent;
    display: inline-flex;
    align-items: center;
    padding: 0;
    white-space: nowrap;
    margin-left: 7px;

    &:last-child {
      margin-right: 0;
    }
    &:disabled {
      opacity: 0.2;
      color: inherit;
    }
  }

  &__icon {
    background-color: var(--primary-navy);
    margin-right: 8px;

    &_info_outline {
      -webkit-mask: url(../../../../assets/images/icons/round-info-outline.svg) no-repeat center;
      mask: url(../../../../assets/images/icons/round-info-outline.svg) no-repeat center;
      width: 24px;
      height: 24px;
      margin-right: 0;
    }
  }
}

// infoPopup
.infoPopup {
  padding: 24px;
  margin-top: 16px;
  box-sizing: border-box;
  width: 400px;
  background: var(--background);
  //position: absolute;
  left: 0;
  top: 100%;
  z-index: 10;

  &_item {
    padding: 0 0 16px 37px;
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &_title {
    font-size: 20px;
    font-weight: 700;
    color: var(--gray-60);
    display: block;
    text-align: center;
    margin-bottom: 16px;
  }
  &_subTitle {
    display: block;
    font-size: 16px;
    font-weight: 700;
    color: var(--primary-navy);
    margin-bottom: 5px;
  }
  p {
    font-size: 14px;
    line-height: 1.43;
    color: var(--gray-60);
    margin: 0;
    white-space: pre-wrap;
  }
}

.modalBack {
  transform: translateY(-56px);
}
