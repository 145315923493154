.u-spaceBetween {
  display: flex;
  justify-content: space-between;
}

.font-grey {
  color: var(--gray-60) !important;
}

.text-align-center {
  text-align: center;
}

.font-primary80 {
  color: var(--primary-80) !important;
}

.font-disabled {
  opacity: 0.65;
  pointer-events: none;
}

.font-navy {
  color: var(--primary-navy) !important;
}

.font-green {
  color: var(--green-60) !important;
}

.font-yellow {
  color: var(--primay-yellow) !important;
}

.w200px {
  width: 200px;
}

.blue-text {
  color: var(--primary-navy) !important;
}

.orange-text {
  color: var(--primary-70) !important;
}

.orange-light-text {
  color: var(--primary-60) !important;
}

.ellipse {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.white-text {
  color: var(--white) !important;
}

.gray-text {
  color: var(--gray-60) !important;
}
