.check-box-container {
  width: 314px;
  &:focus {
    .indicator {
      border-color: var(--button-blue-click);
    }
  }

  label {
    margin-left: 8px;
    font: 400 14px/1.71 Rubik;
    display: flex;
    //width: 16px;
    //height: 16px;
    //position: relative;
    //cursor: pointer;
  }
}

.check-box-body {
  outline: 0 !important;

  &:focus-visible {
    input:before {
      outline: 1px solid var(--primary-60);
      border-color: var(--primary-60);
    }
  }
}

.check-box-wrap {
  display: flex;

  input {
    &:disabled {
      cursor: not-allowed;
      &:before {
        background-color: var(--gray-10);
      }
    }
  }

  label {
    cursor: pointer;
  }
}

.indicator {
  left: 0;
  position: absolute;
  content: '';
  width: 16px;
  height: 16px;
  display: block;
  border-radius: 2px;
  background-color: #ffffff;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
  border: 1px solid #8f8f91;

  &.lg {
    width: 20px;
    height: 20px;
    border-radius: 5px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
}

input:checked + .indicator .checkIcon {
  display: block;
  color: #00a33d;
  position: absolute;
  font-size: 9px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 10px;
}

input:checked + .indicator {
  border: 1px solid #00a33d;
}

.text {
  font-weight: 400;
  font-size: 13px;
  padding-left: 26px;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  color: #111111;
  width: max-content;

  &.bold {
    font-weight: 700;
  }
}

.error {
  border-color: #ff001c;
}

.black {
  color: #111111;
}

.checkIcon {
  display: none;
}

.lg {
  width: 16px;
  min-width: 16px;
  height: 16px;
  min-height: 16px;
}

input[type='checkbox'] {
  position: relative;
  cursor: pointer;

  &:focus-visible {
    outline-offset: 0;
  }
}
input[type='checkbox']:focus-visible:before {
  background: var(--primay-yellow);
  box-shadow: 0px 0px 0px 3px var(--primay-yellow);
}

input[type='checkbox']:focus-visible:checked:before {
  box-shadow: 0px 0px 0px 3px var(--primay-yellow);
}

input[type='checkbox']:before {
  content: '';
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: -1px;
  left: -2px;
  background-color: var(--background);
  border: 2px solid var(--primary-navy);
  border-radius: 1px;
}
input[type='checkbox']:checked:before {
  content: '';
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: -1px;
  left: -2px;
  border-radius: 1px;
  background-color: var(--primary-60);
  border: 2px solid var(--primary-60);
}
input[type='checkbox']:checked:after {
  content: '';
  display: block;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 2px;
  left: 5px;
}

// custom checkbox styles (by ag)
.checkbox {
  display: flex;
  align-items: center;

  input {
    position: fixed;
    left: 0;
    top: 0;
    opacity: 0;
    z-index: -1;
  }

  &_fakeInput {
    -webkit-mask: url(../../../assets/images/icons/checkbox.svg) no-repeat center;
    mask: url(../../../assets/images/icons/checkbox.svg) no-repeat center;
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    background-color: var(--gray-60);
  }

  input:checked + &_fakeInput {
    -webkit-mask: url(../../../assets/images/icons/checkbox-checked.svg) no-repeat center;
    mask: url(../../../assets/images/icons/checkbox-checked.svg) no-repeat center;
    background-color: var(--primary-60);
  }

  &_fakeLabel {
    margin-left: 8px;
    color: var(--gray-60);
  }
}
