.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  input[type='radio' i] {
    border-radius: 50%;
  }
  input[type='radio' i]:focus-visible {
    outline-offset: 0;
  }
  input[type='radio' i]:focus-visible:before {
    outline-offset: 0;
    box-shadow: 0 0 3px 3px var(--primay-yellow);
  }
}

.tooltip {
  padding: 16px;
  display: flex;
  flex-direction: column;

  &__title {
    font-size: 14px;
    font-weight: bold;
    color: var(--gray-60);
    margin-bottom: 10px;
  }

  &__description {
    color: var(--gray-60);
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
  }
}

.hide {
  display: none;
}
