input[type='radio'] {
  position: relative;
  cursor: pointer;
  margin-right: 8px;
  margin-top: 0;
  height: 17px;
}
input[type='radio' i]:focus-visible {
  outline-offset: 0px;
}
input[type='radio']:before {
  content: '';
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--background);
  border: 2px solid var(--primary-navy);
  border-radius: 16px;
}
input[type='radio']:checked:before {
  content: '';
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  background-color: var(--background);
  border: 2px solid var(--primary-60);
}
input[type='radio']:checked:after {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  content: '';
  display: block;
  width: 4px;
  height: 4px;
  border: 3px solid var(--primary-60);
  background-color: var(--primary-60);
  border-radius: 5px;
  position: absolute;
}

.RadioButton {
  &__input {
    margin-right: 12px !important;
  }
}
