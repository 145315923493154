.b-heading {
  display: flex;
  align-items: center;
  margin-bottom: 32px;

  @include media('<tablet') {
    flex-direction: column;
  }

  .btn-datepicker {
    margin-left: 15px;

    @include media('<tablet') {
      width: 100%;
      margin-left: 0;
      margin-top: 15px;
    }
  }
}

// b-dashboardBoxList
.b-dashboardBoxList {
  display: grid;
  grid-template-columns: repeat(auto-fill, 352px);
  gap: 30px;

  @include media('<widescreen') {
    grid-template-columns: repeat(auto-fill, 300px);
  }

  @include media('<tablet') {
    grid-template-columns: 1fr;
  }

  &__item {
    padding: 32px;
    background-color: var(--panel-background);
    border-radius: 16px;
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    min-width: 1px;
  }
  &__title {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    color: var(--gray-60);
    margin: 0;
  }
  &__subTitle {
    display: block;
    text-align: center;
    font-size: 12px;
    color: var(--gray-60);
    margin-top: 5px;
  }
  &__content {
    padding-top: 20px;
  }
  &__num {
    font-size: 40px;
    color: var(--primary-60);
  }
  &__numTotal {
    text-align: center;
  }
  &__numList {
    display: flex;
    justify-content: center;
    color: var(--gray-60);
    font-size: 14px;

    li {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 25px;
    }
  }
  &__table {
    width: 100%;

    table {
      width: 100%;
      border-collapse: collapse;
    }
    th,
    td {
      background-color: var(--chartBg2);
      font-size: 12px;
      line-height: 1.5;
      color: var(--gray-60);
      padding: 10px;
      text-align: right;

      &:first-child {
        padding-left: 20px;
        text-align: left;
      }
      &:last-child {
        padding-right: 20px;
      }
    }
    th {
      color: var(--primary-navy);
    }
    tr {
      &:nth-child(even) {
        td {
          background-color: #f2f2f2;
        }
      }
    }
  }
  .chartPlaceholder {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
  }
}

// b-statusChart
.b-statusChart {
  width: 206px;
  margin: 0 auto;

  &__container {
    padding-bottom: 24px;
  }

  &__label {
    padding-bottom: 12px;
    font-size: 13px;
    font-weight: bold;
    color: var(--primary-navy);
  }

  &__item {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 3px 0;

    &--inPrep {
      background: url(../../images/chart-Part_1.png) no-repeat center;
    }
    &--prepCompleted {
      background: url(../../images/chart-Part_2.png) no-repeat center;
    }
    &--inProgress {
      background: url(../../images/chart-Part_3.png) no-repeat center;
    }
    &--completed {
      background: url(../../images/chart-Part_4.png) no-repeat center;
    }
  }
  &__title {
    color: #00002d;
    // font-size: 10px;
    font-size: 11px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 8px;
  }
  &__value {
    color: var(--white);
    font-size: 13px;
    font-weight: 700;
    display: flex;
    align-items: center;
  }
  &__divider {
    width: 1px;
    height: 12px;
    background-color: var(--white);
    margin: 0 4px;
  }
}

// b-statusMonthsChart
.b-statusMonthsChart {
  &__chart {
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
  &__col {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 5px;
    min-width: 64px;
    box-sizing: border-box;
  }
  &__valuesHolder {
    width: 40px;
    height: 180px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  &__value {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);
    // font-size: 9px;
    font-size: 11px;
    font-weight: 700;

    &:first-child {
      border-radius: 8px 8px 0 0;
    }

    &:last-child {
      border-radius: 0 0 8px 8px;
    }

    &--inactive {
      color: #555;
      background-color: var(--bd-color2);
    }
    &--completed {
      background-color: var(--primary-80);
    }
    &--in_progress {
      background-color: var(--primary-70);
    }
    &--preparation_completed {
      background-color: var(--primary-60);
    }
    &--in_preparation {
      background-color: var(--primay-50);
    }
  }
  &__monthName {
    color: var(--primary-navy);
    // font-size: 10px;
    font-size: 11px;
    font-weight: 700;
    margin-top: 8px;
    text-transform: capitalize;
  }

  &__legend {
    padding-top: 24px;
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 20px);
  }
  &__legendItem {
    font-size: 12px;
    color: var(--gray-60);
    display: flex;
    margin: 0 24px 8px 0;
    &.-noBefore {
      &:before {
        display: none;
      }
    }
    span.point {
      flex-shrink: 0;
      display: block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      margin-right: 8px;
      margin-top: 4px;
    }

    &:before {
      content: '';
      width: 6px;
      height: 6px;
      border-radius: 50%;
      margin-right: 8px;
      margin-top: 4px;
    }

    &--inactive {
      &:before {
        background-color: var(--gray-10);
      }
    }
    &--completed {
      &:before {
        background-color: var(--primary-80);
      }
    }
    &--inProgress {
      &:before {
        background-color: var(--primary-70);
      }
    }
    &--prepCompleted {
      &:before {
        background-color: var(--primary-60);
      }
    }
    &--inPrep {
      &:before {
        background-color: var(--primay-50);
      }
    }
    &--deleted {
      &:before {
        background-color: var(--primay-yellow);
      }
    }
  }
}

// b-mapBox
.b-mapBox {
  padding: 32px;
  border-radius: 16px;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
  background-color: var(--panel-background);
  height: calc(100% - 170px);
  min-height: 300px;

  &__title {
    font-size: 18px;
    font-weight: 500;
    color: var(--primary-navy);
    margin-bottom: 20px;
  }
  .mapPlaceholder {
    max-width: 100%;
    height: auto;
    display: block;
  }
  &__holder {
    height: calc(100% - 80px);
    min-height: 200px;
  }

  &__buttons {
    display: flex;
    justify-content: center;
    margin-top: 25px;
    user-select: none;
  }
  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 38px;
    cursor: pointer;

    &:first-child {
      margin-left: 0;
    }
    &.-disabled {
      .b-mapBox__indicator {
        background: var(--gray-30) !important;

        &_text {
          color: var(--gray-30);
        }
      }
    }
  }
  &__indicator {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    margin-right: 14px;

    &_org {
      background: #cb2527;
    }
    &_pm {
      background: #fb9312;
    }
    &_text {
      font-size: 14px;
      font-weight: 500;
      color: var(--primary-navy);
    }
  }

  &__popup {
    h2 {
      font-size: 16px;
    }
    &_header {
      display: flex;
      justify-content: space-between;
      align-content: center;
    }
    &_info {
      display: flex;
      flex-direction: column;
      padding: 20px;
      height: 300px;
      width: 280px;
      overflow: auto;
      box-sizing: border-box;

      &.-internal {
        .b-mapBox__popup_left {
          min-width: 175px;
        }
      }
    }
    &_block {
      display: flex;
    }
    &_left {
      width: 125px;
      min-width: 125px;
      font-size: 14px;
      line-height: 2.29;
      color: var(--gray-60);
    }
    &_right {
      max-width: 200px;
      font-size: 14px;
      font-weight: bold;
      line-height: 1.4;
      color: var(--gray-60);
    }
    &_footer {
      font-size: 12px;
      color: var(--gray-60);
      display: flex;
      justify-content: space-between;
      align-items: center;

      .arrow {
        width: 24px;
        height: 24px;
        -webkit-mask: url(../../../assets/images/icons/round-navigation-chevron-left.svg) no-repeat center;
        mask: url(../../../assets/images/icons/round-navigation-chevron-left.svg) no-repeat center;
        background: var(--primary-60) !important;
        cursor: pointer;

        &.-disabled {
          background: var(--gray-30) !important;
          cursor: inherit;
        }
      }

      .next {
        transform: rotate(180deg);
      }

      .slider {
        display: flex;
        align-items: center;
      }
    }
  }

  &__pin {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: red;
    cursor: pointer;
  }

  .mapboxgl-popup {
    z-index: 3;
  }
  .mapboxgl-ctrl-top-left {
    z-index: 4;
  }
}

// btn-datepicker
.btn-datepicker {
  font-size: 12px;
  background-color: rgba(0, 0, 0, 0.05);
  display: inline-flex;
  align-items: center;
  flex-shrink: 0;
  color: var(--primary-navy);
  padding: 10px 15px;
  border-radius: 12px;

  &:before {
    content: '';
    -webkit-mask: url(../../../assets/images/icons/round-action-calendar-today.svg) no-repeat center;
    mask: url(../../../assets/images/icons/round-action-calendar-today.svg) no-repeat center;
    width: 17px;
    height: 17px;
    background-color: var(--primary-navy);
    margin-right: 10px;
    flex-shrink: 0;
  }
}
