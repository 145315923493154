.b-viewDebrief {
  &__loadCover {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: var(--background);
    z-index: 9;
  }

  &__pageTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  }

  &__mainSliderWrapper {
    background-color: var(--background);
  }

  &__chartSlide {
    margin: 60px;
  }

  &__slideTitle {
    font-size: 38px;
    text-transform: uppercase;
    font-weight: 800;
    margin-bottom: 40px;
    margin-top: 50px;
  }

  &__downloadBtn {
    &.-disabled {
      opacity: 0.7;
    }
    background-color: transparent;
    width: 167px;
    height: 48px;
    background-size: 100%;
    background-image: url('../../../assets/images/download-btn.png');
  }

  &__fullScreenBtn {
    z-index: 2;
    background-size: 25px;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 32px;
    height: 32px;
    padding: 4px;
    opacity: 0.5;
    &:hover {
      opacity: 0.7;
    }
    border-radius: 8px;
    background-color: var(--gray-70);
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../../../assets/images/icons/round-navigation-fullscreen@2x.png');
  }

  &__subTitle {
    @media screen and (max-width: 1200px) {
      margin-left: 25px;
    }
    font-size: 20px;
    font-weight: bold;
    line-height: 1.2;
    position: relative;
    color: var(--primary-navy);
  }

  &__slider {
    position: relative;
    margin-top: 40px;
  }

  &__slide {
    display: inline-flex !important;
    align-items: center;
    justify-content: center;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }

    &.-pdf {
      .b-viewDebriefSlide2 {
        &__square {
          &.-yellow {
            height: 125px;
          }
          &.-orange {
            height: 108px;
            font-size: 15px;
          }
          &.-red {
            height: 210px;
          }
        }

        &__title {
          font-size: 17px;
        }

        &__text {
          font-size: 15px;
        }
      }
    }
  }

  &__pag {
    padding: 20px 56px;
    border-radius: 8px;
    background-color: var(--gray-10);
    position: relative;

    .slick-current {
      .b-viewDebrief__pagSlide {
        border: 2px solid var(--primary-60);
      }
    }

    .s-arrow {
      position: absolute;
      width: 28px;
      height: 28px;
      border-radius: 28px;
      top: 50%;
      transform: translateY(-50%);

      background-color: var(--primary-60) !important;

      &:before {
        display: none;
      }
      &:after {
        width: 8px;
        height: 8px;
        border: 2px solid #fff;
        position: absolute;
        content: '';
        border-right-width: 0;
        border-bottom-width: 0;
      }

      &.-disabled {
        opacity: 1 !important;
        background-color: var(--gray-20) !important;
      }
    }

    .slick-arrow {
      display: none !important;
      width: 28px;
      height: 28px;
      border-radius: 28px;

      background-color: var(--primary-60) !important;

      &:before {
        display: none;
      }
      &:after {
        width: 8px;
        height: 8px;
        border: 2px solid #fff;
        position: absolute;
        content: '';
        border-right-width: 0;
        border-bottom-width: 0;
      }
    }

    .s-prev {
      left: 20px;
      &:after {
        transform: rotate(-45deg);
        top: 9px;
        left: 11px;
      }
    }

    .s-next {
      right: 20px;
      &:after {
        transform: rotate(135deg);
        top: 9px;
        left: 7px;
      }
    }
  }

  &__pagSlide {
    margin: 0 4px;
    border-radius: 8px;
    overflow: hidden;
    box-sizing: border-box;
    min-height: 58px;
    height: 58px;
    @media screen and (min-width: 1366px) {
      height: auto;
    }

    img {
      transform: scale(1.1);
      box-sizing: border-box;
      width: 100%;
      height: 100%;
    }
  }

  &__backBtn {
    position: absolute;
    left: -30px;
    cursor: pointer;

    .back-icon {
      width: 24px;
      height: 24px;
      background-color: var(--primary-navy);
      -webkit-mask: url('../../images/icons/round-navigation-arrow-back.svg') no-repeat center;
      mask: url('../../images/icons/round-navigation-arrow-back.svg') no-repeat center;
    }
  }
}

.b-viewDebriefChartSlide {
  position: relative;
  width: 100%;
  &__chart {
    display: flex;
    flex-direction: row;
    align-items: center;
    top: 0;
    width: 100%;
    left: 0;
    box-sizing: border-box;
    padding: 18% 50px 20px;
    position: absolute;
    height: calc(100% - 20px);
    margin-bottom: 20px;
    //overflow: auto;
  }
}

.b-viewDebriefSlide1 {
  width: 100%;
  height: 100%;
  position: relative;

  &__content {
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 100%;
    text-align: center;
    z-index: 3;
    position: absolute;

    h1 {
      color: #fff;
      font-size: 38px;
      text-transform: uppercase;
      font-weight: 800;
    }

    h3 {
      font-size: 28px;
      margin-bottom: 20px;
    }

    h2 {
      font-size: 32px;
      font-weight: 900;
      margin-top: 10px;
    }
  }
}

.b-viewDebriefSlide2 {
  &__wrapper {
    width: 100%;
    position: relative;
  }

  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  height: 100%;
  //width: 100%;
  //max-width: 930px;

  padding: 0 100px;
  padding-top: 60px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &.-fullHeight {
    height: 100vh;
    padding-top: 0px;
  }

  &__decor {
    background-color: rgba(253, 228, 1, 0.3);
    position: relative;
    margin-bottom: 15px;
    &:before {
      position: absolute;
      content: '';
      width: 60px;
      top: 0;
      bottom: 0;
      left: -60px;
      transform: rotate(180deg);
      background: linear-gradient(
        90deg,
        rgba(253, 228, 1, 0.3) 0%,
        rgba(253, 228, 1, 0.3) 5%,
        rgba(253, 228, 1, 0) 100%
      );
    }
    &:after {
      position: absolute;
      content: '';
      width: 60px;
      top: 0;
      bottom: 0;
      right: -60px;
      background: linear-gradient(
        90deg,
        rgba(253, 228, 1, 0.3) 0%,
        rgba(253, 228, 1, 0.3) 5%,
        rgba(253, 228, 1, 0) 100%
      );
    }

    &.-orange {
      background-color: rgba(255, 107, 0, 0.3);
      &:before {
        background: linear-gradient(
          90deg,
          rgba(255, 107, 0, 0.3) 0%,
          rgba(255, 107, 0, 0.3) 5%,
          rgba(255, 107, 0, 0) 100%
        );
      }
      &:after {
        background: linear-gradient(
          90deg,
          rgba(255, 107, 0, 0.3) 0%,
          rgba(255, 107, 0, 0.3) 5%,
          rgba(255, 107, 0, 0) 100%
        );
      }
    }

    &.-red {
      background-color: rgba(234, 0, 41, 0.3);
      &:before {
        background: linear-gradient(
          90deg,
          rgba(234, 0, 41, 0.3) 0%,
          rgba(234, 0, 41, 0.3) 5%,
          rgba(234, 0, 41, 0) 100%
        );
      }
      &:after {
        background: linear-gradient(
          90deg,
          rgba(234, 0, 41, 0.3) 0%,
          rgba(234, 0, 41, 0.3) 5%,
          rgba(234, 0, 41, 0) 100%
        );
      }
    }
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    margin-left: -6px;
    margin-right: -6px;

    &.-noMb {
      margin-bottom: 0;
    }
  }

  &__square {
    margin: 0 6px;
    border-radius: 7px;
    text-align: center;
    //flex-grow: 1;
    flex: 1 1 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &.-yellow {
      background-color: #fde401;
      height: 95px;
      color: #0d0d0d;

      @media screen and (max-width: 1280px) {
        height: 70px;
      }

      @media screen and (min-width: 1900px) {
        height: 135px;
      }
      @media screen and (min-width: 2200px) {
        height: 195px;
      }
    }

    &.-orange {
      height: 78px;
      background-color: var(--primary-70);
      border-radius: 4px;
      color: #fff;
      font-size: 11px;
      font-weight: 700;
      padding: 0 7px;
      @media screen and (max-width: 1280px) {
        height: 65px;
      }
      @media screen and (min-width: 1900px) {
        height: 118px;
        font-size: 20px;
      }
      @media screen and (min-width: 2200px) {
        height: 178px;
      }
    }

    &.-red {
      height: 180px;
      background-color: var(--primary-80);
      color: #fff;
      @media screen and (max-width: 1280px) {
        height: 145px;
      }
      @media screen and (min-width: 1900px) {
        height: 240px;
      }
      @media screen and (min-width: 2200px) {
        height: 280px;
      }
    }
  }

  &__title {
    font-size: 13px;
    padding-bottom: 7px;
    font-weight: 600;
    line-height: 1.8;

    @media screen and (min-width: 1900px) {
      font-size: 23px;
    }
    @media screen and (max-width: 1120px) {
      font-size: 12px;
    }
  }

  &__text {
    font-size: 11px;
    font-weight: 500;
    line-height: 1.67;
    @media screen and (min-width: 1900px) {
      font-size: 21px;
    }
    @media screen and (max-width: 1120px) {
      font-size: 10px;
    }
  }
}
