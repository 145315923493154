.b-panel {
  border-radius: 16px;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
  background-color: var(--panel-background);
}

.b-panelItem {
  margin-right: 32px;
  margin-bottom: 22px;
  box-sizing: border-box;
  width: 352px;
  display: block;
  padding: 36px 32px 29px;
  border-radius: 16px;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
  background-color: var(--panel-background);

  @include media('<desktop') {
    min-width: auto;
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }

  &__statusContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    &.-createdBy {
      justify-content: inherit;
      span {
        color: var(--primary-navy);
        margin-left: 10px;
      }
    }
  }

  &__info {
    display: flex;
  }

  &__left {
    width: 115px;
  }

  &__title {
    font-family: Rubik;
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 0.16px;
    color: var(--gray-60);
    padding-bottom: 15px;
  }

  &__details {
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 0.16px;
    text-align: right;
    text-decoration: underline;
    color: var(--gray-60);
    font-weight: normal;
    background: transparent;
  }

  &__val {
    font-family: Rubik;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.43;
    color: var(--gray-60);
    padding-bottom: 15px;
  }

  &__signUpLink {
    padding-left: 10px;
    font-size: 14px;
    line-height: 1.43;
    font-weight: 400;
    color: var(--gray-60);
    text-decoration: underline;
  }

  &__links {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__link {
    text-decoration: underline;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    color: var(--primary-60);

    &.-disabled {
      color: var(--color-disable);
      pointer-events: none;
    }
  }
}

.b-panelTitleOutside {
  // b-equityAudits__box
  position: relative;
  padding: 36px;
  border-radius: 16px;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
  background-color: var(--panel-background);

  table {
    width: 100%;

    tr {
      display: flex;
      align-items: flex-start;
      &:first-child {
        th,
        td {
          padding-top: 0;
        }
      }
      &:last-child {
        th,
        td {
          padding-bottom: 0;
        }
      }
    }

    th {
      text-align: left;
      font-weight: normal;
      width: 40%;
      flex-shrink: 0;
    }
    th,
    td {
      font-family: Rubik;
      font-size: 14px;
      color: var(--gray-60);
      padding: 10px 12px 10px 0;
    }
    td {
      width: 70%;
      font-weight: bold;
    }
  }

  &__list {
    // b-equityAudits__itemMobileList
    margin-top: 40px;
    margin-left: -20px;
    margin-right: -20px;
  }
  &__container {
    // b-equityAudits__block
    margin-bottom: 40px;
    margin-left: 20px;
    margin-right: 20px;
  }

  &__name {
    // b-equityAudits__blockName
    text-align: center;
    color: var(--primary-60);
    font-family: Rubik;
    font-size: 16px;
    font-weight: bold;
    display: block;
    margin: 0 0 20px;
  }

  &__linksList {
    // b-equityAudits__blockLinksList
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__link {
    // b-equityAudits__blockLink
    background: none;
    text-align: left;
    color: var(--primary-navy);
    display: inline-flex;
    align-items: center;
    padding: 0;
    margin-top: 24px;
    font-family: Rubik;
    font-size: 14px;
    font-weight: bold;
    text-decoration: underline;

    [class*='icon'] {
      margin: 0 8px 0 0;
    }
  }
  &__iconWrapper {
    border-radius: 50%;
    border: 2px solid var(--primary-60);
    padding: 3px;
    .b-button-icon {
      margin: 0;
    }
  }
}

.b-panelData {
  border-radius: 16px;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
  background-color: var(--panel-background);
  width: 100%;
  box-sizing: border-box;
  padding: 28px 40px 16px;
  margin-bottom: 30px;

  &__row {
    margin: 0 0 15px;
    display: grid;
    grid-template-columns: 120px 1fr 120px 1fr;
    grid-column-gap: 20px;

    &.-rw2 {
      grid-template-columns: 120px 1fr;
    }

    @include media('<tablet') {
      grid-template-columns: 120px 1fr;
    }

    dt,
    dd {
      min-height: 20px;
    }

    dd {
      color: var(--primary-navy);
      margin-left: 0;

      &.val {
        width: 100px;
      }
    }

    dt {
      .b-link-icon {
        display: block;
        margin-top: 20px;
      }
    }
  }

  &__fieldTitle {
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 0.16px;
    color: var(--gray-60);
  }

  &__fieldValue {
    color: var(--gray-60);
    font-size: 14px;
    font-weight: 600;
    line-height: 1.43;
    overflow-wrap: anywhere;

    input {
      font-size: 14px;
    }

    &.-primary-60 {
      input {
        color: var(--primary-60);
      }
    }
  }
}
