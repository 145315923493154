.title {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: var(--gray-60);
  max-width: 275px;
  margin-bottom: 2px;
}

.errorContainer {
  margin-bottom: 10px;
}

.container {
  height: 100vh;
}

.error {
  font-size: 14px;
}

.wrapError {
  margin-top: -20px;
}
