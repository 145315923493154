.b-elmReport2 {
  &__noContent {
    padding: 20px 30px;
  }
  &__tabs {
    display: flex;
    align-items: center;
    a {
      font-size: 14px;
      font-weight: bold;
      color: var(--primary-navy);
      cursor: pointer;
      margin-right: 30px;

      &.-active {
        color: var(--primary-60);
        border-bottom: 3px solid var(--primary-60);
      }
    }
  }

  &__headMenu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 23px;
  }

  &__panel {
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    background-color: var(--panel-background);
    margin-bottom: 15px;
  }

  &__rate {
    font-size: 18px;
    font-weight: bold;
    padding: 23px 20px;
    text-align: center;
    color: var(--primary-navy);
  }

  &__rateVal {
    font-size: 36px;
    margin-top: 7px;
    font-weight: bold;
    color: var(--primary-60);
    display: flex;
    justify-content: space-around;

    &-subTitle {
      color: var(--primary-navy);
      font-size: 13px;
    }
  }

  &__pieCharts {
    display: grid;
    grid-template-columns: repeat(auto-fill, 352px);
    grid-gap: 30px;
    gap: 30px;

    @include media('<tablet') {
      display: block;
    }
  }

  &__piePanel {
    padding: 32px;
    background-color: var(--panel-background);
    border-radius: 16px;
    box-shadow: 0 0 40px 0 rgb(0 0 0 / 10%);
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    min-width: 1px;

    @include media('<tablet') {
      width: 100%;
    }
    h2 {
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      color: var(--primary-navy);
      margin: 0 15px;
      padding-bottom: 15px;
      border-bottom: solid 1px var(--bd-color1);
    }
  }

  &__pieWrapper {
    margin: 0 40px;

    @include media('<tablet') {
      margin: 0 30px;
    }
  }

  &__pieChartWrapper {
    margin: 0 40px;
  }
}

.b-elmReport2Guide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 44px;
  &__item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    &.-yellow {
      ul {
        color: var(--primay-yellow);
      }
    }

    &.-orange {
      ul {
        color: var(--primary-60);
      }
    }

    &.-green {
      ul {
        color: var(--green-60);
      }
    }

    ul {
      margin-left: 0 !important;
      margin-right: 10px;
      width: 100px;
    }
  }
}
