.container {
  background-color: var(--background);
  padding: 52px 24px;
  position: relative;
}

.btn {
  width: 130px;
  height: 52px;
  font-size: 16px;
}

.btn__right {
  margin-left: 20px;
}

.btns {
  display: flex;
  justify-content: center;
}

.title {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  text-align: center;
  color: var(--primary-navy);
  margin-bottom: 8px;
}

.text {
  margin-bottom: 45px;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  color: var(--gray-60);
  p {
    margin-bottom: 20px;
  }
}

.close {
  width: 24px;
  height: 24px;
  position: absolute;
  right: 10px;
  top: 10px;
}
