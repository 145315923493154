.b-ewp {
  &__editorField {
    max-width: 423px;
  }
  &__border {
    border-bottom: 1px solid var(--bd-color2);
  }

  &__infoBtn {
    vertical-align: sub;
    color: transparent;
  }

  &__wysiwyg {
    font-size: 14px;

    img {
      max-width: 100%;
    }

    &.-placeholder {
      color: red;
      .b-editorText {
        font-style: italic;
      }
    }
  }

  &__finish {
    max-width: 490px;
    margin: 30px auto;
    text-align: center;
  }
  &__details {
    &-pm {
      grid-template-columns: 120px 0.8fr;
    }
    &-title {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;

      .b-button-icon-back,
      .b-modal__cross {
        position: relative;
        right: 0;
        top: 0;
        left: 0;
      }
    }
    &-pyramid {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 25px;
      margin-bottom: 40px;
      img {
        max-width: 470px;
      }
    }
    &-cards {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      .b-panelItem {
        margin-right: 0;
        width: 401px;
        padding: 37px 30px;
      }

      h3 {
        color: var(--primary-60);
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 20px;
      }
    }
  }
}

.b-ewpElementsTree {
  &__icon {
    width: 30px;
    height: 30px;
    mask-size: 30px !important;
  }

  &__list {
    li {
      list-style-type: disc;
      margin-bottom: 10px;
    }
  }

  &__leftMargin {
    margin-left: 40px;
  }

  &__arrowBtn {
    width: 15px;
    display: block;
    height: 15px;
    background-color: var(--primary-60);
    -webkit-mask: url(../../images/icons/ewp/Triangle.svg) no-repeat center 6px;
    mask: url(../../images/icons/ewp/Triangle.svg) no-repeat center 6px;

    &.-closed {
      transform: rotate(-90deg);
    }
  }

  &__arrow {
    position: absolute;
    bottom: -32px;
    left: 14px;
    width: 18px;
    top: 34px;
    border-radius: 7px;
    border-left: 2px solid var(--gray-60);
    border-bottom: 2px solid var(--gray-60);

    &:after {
      position: absolute;
      content: '';
      width: 8px;
      height: 8px;
      border-right: 2px solid var(--gray-60);
      border-top: 2px solid var(--gray-60);
      transform: rotate(45deg);
      bottom: -6px;
      right: 4px;
    }
  }

  &__arrowHelpers {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    &:before {
      position: absolute;
      content: '';
      background-color: var(--background);
      width: 8px;
      height: 8px;
      top: -2px;
      left: -5px;
    }
    &:after {
      position: absolute;
      content: '';
      background-color: var(--background);
      width: 8px;
      height: 8px;
      bottom: -4px;
      right: -3px;
    }
  }
}

.b-ewpElementCard {
  margin-bottom: 20px;
  position: relative;

  &.-broken {
    border: 1px solid var(--primary-80);
  }

  &__brokenText {
    position: absolute;
    top: -21px;
    font-size: 14px;
    width: 100%;
    font-weight: 500;
    text-align: center;
    left: 0;
    color: var(--primary-80);
  }
}

.b-ewpDashboard {
  &__chartTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: var(--gray-60-30);
    margin-bottom: 30px;
  }
  &__label {
    margin-bottom: 26px;
    .title {
      width: 200px;
      height: 85px;
      padding-top: 10px;
      box-sizing: border-box;
      div {
        font-size: 12px;
        text-align: end;
        font-weight: normal;
        color: var(--gray-60-10) !important;
      }
    }
  }
  .b-customChart__labels {
    max-width: 222px;
    min-width: 222px;
    ul {
      li {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        height: 100px;
        max-height: 100px;
        overflow: hidden;
        justify-content: space-between;

        .b-panelItem__link {
          font-size: 12px;
          font-weight: normal;
          line-height: 1.67;
          letter-spacing: 0.14px;
        }
      }
    }
  }
}
