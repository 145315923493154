@import '../../../assets/styles/include-media';

.ag-userProfile {
  position: relative;

  .ag-icon {
    background-color: var(--gray-60);
    width: 14px;
    height: 18px;
    display: inline-block;
    padding: 0;

    &_delete {
      -webkit-mask: url(../../../assets/images/icons/delete.svg) no-repeat center;
      mask: url(../../../assets/images/icons/delete.svg) no-repeat center;
    }
    &_send {
      -webkit-mask: url(../../../assets/images/icons/send.svg) no-repeat center;
      mask: url(../../../assets/images/icons/send.svg) no-repeat center;
    }
    &_person_off {
      -webkit-mask: url(../../../assets/images/icons/person_off.svg) no-repeat center;
      mask: url(../../../assets/images/icons/person_off.svg) no-repeat center;
    }
    &_person_on {
      -webkit-mask: url(../../../assets/images/icons/person_on.svg) no-repeat center;
      mask: url(../../../assets/images/icons/person_on.svg) no-repeat center;
    }
    &_edit {
      -webkit-mask: url(../../../assets/images/icons/edit_icon.svg) no-repeat center;
      mask: url(../../../assets/images/icons/edit_icon.svg) no-repeat center;
      mask-size: 22px;
      width: 22px;
    }
  }

  &_heading {
    border-bottom: 1px solid var(--bd-color2);
    padding-bottom: 40px;
    margin-bottom: 40px;
  }
  &_headingRow {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    @include media('<tablet') {
      flex-direction: column;
    }
  }
  &_userName {
    font-family: Rubik;
    font-size: 24px;
    font-weight: 700;
    display: block;
    color: var(--primary-navy);
    margin: 0;
  }
  &_tools {
    display: inline-flex;
    align-items: center;
    font-size: 0;
    margin: 0;

    @include media('<tablet') {
      margin-top: 15px;
    }

    li {
      margin-left: 20px;

      @include media('<tablet') {
        margin: 0 10px;
      }
    }
  }
  &_userInfo {
    display: flex;
    align-items: center;
    font-family: Rubik;
    font-size: 14px;

    @include media('<desktop') {
      flex-direction: column;
      align-items: flex-start;
    }
    @include media('<tablet') {
      align-items: center;
      padding-top: 15px;
    }

    li {
      padding-right: 12px;
      position: relative;
      color: var(--gray-60);
      width: 100%;
      max-width: 260px;
      overflow: hidden;
      text-overflow: ellipsis;

      @include media('<tablet') {
        padding-right: 0;
      }
    }

    li + li {
      padding-left: 12px;

      @include media('<desktop') {
        padding: 12px 0 0;
      }

      &:before {
        content: '';
        height: 12px;
        width: 1px;
        background-color: var(--gray-20);
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);

        @include media('<desktop') {
          display: none;
        }
      }
    }
  }
  &_title {
    color: var(--gray-60);
    font-size: 20px;
    line-height: 1.3;
    font-family: Rubik;
    font-weight: 700;
    margin: 0 0 40px;

    @include media('<tablet') {
      text-align: center;
    }
  }
  &_buttons {
    display: flex;
    justify-content: center;

    .button {
      margin: 0 5px;
    }
  }
  &_backBtn {
    position: absolute;
    cursor: pointer;
    left: -35px;
    top: 3px;
    width: 24px;
    height: 24px;
    background-color: var(--primary-navy);
    -webkit-mask: url(../../images/icons/round-navigation-arrow-back.svg) no-repeat center;
    mask: url(../../images/icons/round-navigation-arrow-back.svg) no-repeat center;

    @include media('<tablet') {
      left: 0;
    }
  }
}

// ag-userlist
.ag-userlist {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 30px;
  margin-bottom: 60px;

  @include media('<tablet') {
    grid-template-columns: 1fr;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &_item {
    display: flex;
    flex-direction: column;
  }

  &_title {
    text-align: center;
    color: var(--primary-60);
    font-size: 16px;
    font-family: Rubik;
    font-weight: 700;
    margin: 0 0 20px;
  }

  &_box {
    background-color: var(--panel-background);
    padding: 40px 35px;
    border-radius: 16px;
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
    position: relative;
    display: flex;
    flex-grow: 1;
  }
  &_list {
    width: 100%;

    tr:last-child {
      th,
      td {
        padding-bottom: 0;
      }
    }
    th,
    td {
      color: var(--gray-60);
      font-family: Rubik;
      font-size: 14px;
      padding: 0 0 20px;
      vertical-align: top;
      overflow-wrap: break-word;
    }

    th {
      text-align: left;
      white-space: nowrap;
      font-weight: 400;
      padding-right: 15px;
      width: 1%;
    }

    td {
      font-weight: 700;
    }
  }
}

// Add Affiliation lightbox
.ag-lightbox {
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
  background-color: var(--white);
  width: Min(545px, 100%);
  box-sizing: border-box;
  margin: auto;
  padding: 40px 20px;

  &_holder {
    max-width: 327px;
    margin: 0 auto;

    > * {
      width: 100%;
    }
    select {
      width: 100%;
    }
    .error-notification {
      min-width: 0;
      width: 100%;
    }
  }
  &_title {
    font-family: Rubik;
    font-size: 20px;
    font-weight: bold;
    color: var(--gray-60);
    display: block;
    text-align: center;
    margin-bottom: 40px;
  }
  &_subTitle {
    font-family: Rubik;
    font-size: 16px;
    font-weight: bold;
    color: var(--gray-60);
    margin-bottom: 20px;
    display: flex;
    align-items: center;
  }
  &_infoIcon {
    -webkit-mask: url(../../../assets/images/icons/round-info.svg) no-repeat center;
    mask: url(../../../assets/images/icons/round-info.svg) no-repeat center;
    width: 24px;
    height: 24px;
    margin-left: 5px;
    background-color: var(--gray-60);
  }
  &_buttons {
    display: flex;
    justify-content: center;
    padding-top: 60px;

    .button {
      margin: 0 5px;
    }
  }
}

// noticication lightbox
.ag-lightboxNotification {
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
  background-color: var(--white);
  width: Min(352px, 100%);
  box-sizing: border-box;
  margin: auto;
  padding: 50px 25px;
  text-align: center;

  &_title {
    font-family: Rubik;
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    color: var(--primary-navy);
    display: block;
    margin-bottom: 10px;
  }
  p {
    font-family: Rubik;
    font-size: 16px;
    line-height: 1.5;
    color: var(--gray-60);
    margin: 0;
  }
  &_buttons {
    display: flex;
    justify-content: center;
    padding-top: 30px;

    .button {
      margin: 0 5px;
    }
  }
}

// radio styles
.ag-radio {
  display: flex;
  align-items: center;

  input {
    position: fixed;
    left: 0;
    top: 0;
    opacity: 0;
    z-index: -1;
  }

  &_fakeInput {
    -webkit-mask: url(../../../assets/images/icons/radio.svg) no-repeat center;
    mask: url(../../../assets/images/icons/radio.svg) no-repeat center;
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    background-color: var(--gray-60);
  }

  input:checked + &_fakeInput {
    -webkit-mask: url(../../../assets/images/icons/radio-checked.svg) no-repeat center;
    mask: url(../../../assets/images/icons/radio-checked.svg) no-repeat center;
    background-color: var(--primary-60);
  }

  &_fakeLabel {
    margin-left: 8px;
    font-family: Rubik;
    font-weight: 400;
    font-size: 16px;
    color: var(--gray-60);
  }
}

.ag-radioList {
  li {
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.ag-icons {
  display: flex;
  position: absolute;
  right: 15px;
  top: 15px;
  button {
    margin: 0 5px;
  }
}
