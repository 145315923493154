.pageContainer {
  flex-grow: 1;
  z-index: 1;
}

.btn {
  &__affiliation {
    display: flex;
    position: absolute;
    right: 20px;
    top: 20px;

    button {
      margin: 0 5px;
    }
  }
}

.form {
  &__part {
    width: 100%;
    padding-right: 30px;

    @media screen and (max-width: 1024px) {
      padding-right: 0;
    }
  }

  &__buttons {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  &__addLink {
    color: var(--primary-60);
    font-family: Rubik;
    cursor: pointer;
    font-weight: 400;
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    padding: 0 15px;
    margin-bottom: 60px;
    margin-top: 15px;

    &:before {
      content: '+';
      background-color: var(--primary-60);
      color: #fff;
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
      text-align: center;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      margin-right: 8px;
    }
  }
  &__affiliation_btn {
    width: 100%;
  }
}

.form__addLink {
  display: flex;
  justify-content: center;
}

.btns {
  display: flex;
  width: 100%;
  justify-content: center;
}

.modal {
  min-height: 250px;
}

.info {
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: 0.16px;
  color: var(--gray-60);
  margin-bottom: 40px;
}
