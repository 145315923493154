.form {
  padding: 40px 109px;
}

.mobileForm {
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.buttons {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
}
