.b-step {
  &__step4Text {
    @include media('<tablet') {
      text-align: center;
    }
  }
  &__task {
    text-align: center;
    font-size: 16px;
    color: var(--gray-60);
  }
  &__list {
    padding: 0 20px;
    li {
      color: var(--gray-60);
      line-height: 1.5;
      margin: 10px 0;
    }

    &.-dots {
      li {
        position: relative;
        &:before {
          position: absolute;
          width: 4px;
          height: 4px;
          content: '';
          left: -20px;
          border-radius: 50%;
          background: var(--gray-60);
          top: calc(50% - 2px);
        }
      }
    }
  }
  &__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 33px;

    @include media('<tablet') {
      flex-direction: column-reverse;
    }

    &-left {
      display: flex;
      align-items: center;
      @include media('<tablet') {
        width: 100%;
        margin-bottom: 20px;
        justify-content: space-between;
      }
    }
  }
  &__stepper {
    display: flex;
    column-gap: 32px;
    overflow: hidden;
    justify-content: center;

    &-count {
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--gray-20);
      font-size: 12px;
      font-weight: bold;
      color: var(--white);
      border-radius: 50%;
      position: relative;

      &:first-child {
        &:before {
          display: none;
        }
      }

      &:before {
        position: absolute;
        width: 32px;
        content: '';
        right: 20px;
        background-color: var(--gray-20);
        height: 2px;
      }

      &.-active {
        color: var(--white-black);
        background-color: var(--primary-60);
        &:before {
          background-color: var(--primary-60);
        }
      }
    }
  }

  &__subTitle {
    font-size: 16px;
    color: var(--gray-60);
  }

  &__textCenter {
    text-align: center;
  }

  &__selectCenter {
    display: flex;
    justify-content: center;
  }

  &__container {
    margin: 0 auto;
    width: 100%;
    max-width: 600px;

    &.-w735 {
      max-width: 735px;
    }

    &.-centerContent {
      display: flex;
      min-height: 100%;
      flex-direction: column;
      justify-content: center;
    }
  }

  &__bigTitle {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: var(--primary-navy);
    margin: 0 0 40px;

    @include media('<desktop') {
      font-size: 20px;
    }
  }

  &__p {
    font-size: 16px;
    line-height: 1.5;
    color: var(--gray-60);
    margin-bottom: 24px;
  }

  &__text {
    font-size: 16px;
    line-height: 1.5;
    color: var(--gray-60);
  }

  &__btns {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }

  &__orangeBold {
    font-weight: bold;
    color: var(--primary-60);

    &.-mb5 {
      margin-bottom: 5px;
    }
  }

  &__wBox {
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
    padding: 32px 44px;
    border-radius: 16px;
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
    background-color: var(--panel-background);

    @include media('<desktop') {
      margin-bottom: 60px;
    }

    &.-intro1Pop {
      box-sizing: border-box;
      max-width: 400px;
      padding-left: 100px !important;
    }

    &.-paddings {
      padding: 40px 72px;

      @include media('<desktop') {
        padding: 32px 24px;
      }
    }
    &.-max-w {
      max-width: 735px;
      margin-left: 0;
      margin-right: auto;
    }
  }

  &__num {
    position: relative;
    top: 1px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--gray-30);
    border-radius: 20px;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    color: var(--gray-10);
    margin-right: 16px;
    flex-shrink: 0;
  }

  &__numItem {
    margin-bottom: 16px;
    //align-items: center;
    display: flex;
  }

  &__mr8 {
    margin-right: 8px;
  }

  &__smallTitle {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    color: var(--primary-navy);

    &.-grey {
      color: var(--gray-60);
    }

    span.-orange {
      color: var(--primary-60);
    }
  }

  ul {
    list-style-type: disc;
    font-size: 16px;
    line-height: 1.5;
    color: var(--gray-60);
    margin-left: 20px;

    li {
      margin-bottom: 10px;
    }
  }

  &__contCenter {
    display: flex;
    justify-content: center;
  }

  &__orgWrapper {
    margin-bottom: 40px;
    @include media('<tablet') {
      text-align: center;
    }
  }

  &__orgText {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.2;
    color: var(--primary-60);
  }

  &__info {
    margin-bottom: 60px;
  }

  &__titleWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__textWrapper {
    max-width: 290px;
  }

  &__title {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    color: var(--primary-navy);
  }

  &__add {
    display: flex;
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 0.16px;
    color: var(--primary-60);
    align-items: center;
    margin-top: 20px;

    @include media('<tablet') {
      justify-content: center;
    }

    &__icon {
      margin-right: 8px;
      width: 16px;
      height: 16px;
    }

    &__ic {
      margin-right: 8px;
      width: 16px;
      height: 16px;
      border-radius: 16px;
      background-color: var(--primary-60);

      &.-transparent {
        background-color: transparent;
      }
    }
  }

  &__teamModal {
    &__list {
      margin-top: 20px;
      margin-bottom: 20px;
    }

    &__item {
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__tip {
      margin-bottom: 60px;
    }
  }
}
