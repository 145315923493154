.ql-container {
  height: 200px;
}
.ql-editor.ql-blank::before {
  color: var(--gray-60);
}

.ql-editor {
  color: var(--primary-navy);
}

.ql-formats {
  svg {
    &:hover {
      path,
      line,
      polyline,
      rect {
        stroke: var(--primary-60) !important;
      }
    }
    path,
    line,
    polyline,
    rect {
      stroke: var(--gray-60) !important;
    }
  }
}
