.b-adminToolRoles {
  &__perm {
    padding: 25px 30px;
    box-sizing: border-box;
    min-width: 352px;
    margin-bottom: 20px;
    margin-right: 30px;

    @include media('<desktop') {
      margin-right: 0;
      width: 100%;
    }
  }

  &__newRoleInput {
    margin: 40px 60px 60px;
  }

  &__newRoleModal {
    min-height: 350px;
  }

  &__permItem {
    margin-left: 20px;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    line-height: 1.14;
    color: var(--gray-60);
  }

  &__permName {
    margin-bottom: 15px;
    font-size: 14px;
    line-height: 1.14;
    color: var(--gray-60);
    font-weight: bold;
  }

  &__permList {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
  }

  &__roleName {
    display: flex;
    align-items: flex-start;
  }

  &__roleNameBtn {
    margin-top: 15px;
    margin-left: 15px;
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include media('<desktop') {
      flex-direction: column-reverse;
    }
  }

  &__addBtn {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    color: var(--primary-60);
    position: relative;
    top: -10px;

    &:before {
      content: '';
      position: absolute;
      left: -30px;
      width: 24px;
      height: 24px;
      background-image: url('../../images/icons/round-content-add-circle.svg');
    }

    @include media('<desktop') {
      top: 0;
      margin-bottom: 20px;
    }
  }
}

.b-adminToolUsers {
  &__mobileList {
    margin: 0 -20px;
  }

  &__filterModal {
    margin: 20px 50px 0;

    @include media('<desktop') {
      margin-left: 0px;
      margin-right: 0px;
    }
  }

  &__checkAllMob {
    position: relative;
    top: -3px;
  }

  &__top {
    display: flex;
    margin-bottom: 35px;
    align-items: center;
    justify-content: space-between;

    @include media('<desktop') {
      justify-content: flex-start;
      flex-direction: column-reverse;
      margin-bottom: 0;
    }
  }

  &__statusCell {
    display: flex;
    height: 100%;
    line-height: 1.5;
    flex-direction: column;
    justify-content: center;
  }

  &__userFormInput {
    width: 342px;
    margin-left: auto;
    margin-right: auto;
  }

  &__userModal {
    min-height: 400px;
  }

  &__userForm {
    margin-top: 20px;
  }

  &__userFormRow {
    margin: 0 30px;
    display: flex;
    justify-content: space-between;

    @include media('<desktop') {
      display: block;
    }
  }
}

.b-at-user {
  padding: 28px 48px 28px 28px;
  position: relative;
  margin: 30px 20px;

  &__userName {
    display: block;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 8px;
  }

  &__check {
    position: absolute;
    right: 20px;
    top: 20px;
  }

  &__list {
    li {
      margin-bottom: 8px;
      font-size: 14px;
      color: var(--gray-60);
    }
  }
}

.b-orgUserProfile {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__headText {
    margin-top: 16px;
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 0.16px;
    color: var(--gray-60);

    span {
      padding-left: 25px;
    }
  }

  &__form {
    margin-top: 40px;
    margin-bottom: 24px;
  }

  &__formRow {
    display: flex;
    justify-content: space-between;

    @include media('<tablet') {
      display: block;
    }
  }

  &__formInput {
    width: calc(50% - 16px);
    @include media('<tablet') {
      width: 100%;
    }
  }

  &__orgs {
    margin-top: 60px;
  }

  &__solution {
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 0.16px;
    color: var(--gray-60);
    margin-bottom: 15px;
    p {
      margin-bottom: 6px;
    }
  }
}
