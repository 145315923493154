.elmBlock {
  max-width: 352px;
  box-sizing: border-box;
}

.boxList {
  grid-template-columns: inherit;
}

.total {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
  margin-top: 20px;

  &__number {
    font-size: 16px;
    font-weight: bold;
    color: #fb9312;
  }

  &__title {
    color: var(--gray-60);
    font-size: 12px;
  }
}
