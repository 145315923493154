.b-no-content {
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  color: var(--gray-60);
  margin-top: 20px;

  &__msg {
    font-size: 16px;
    margin-top: 10px;
    font-weight: 400;
  }
}

.b-uploadFileLink {
  &__input {
    position: absolute;
    left: -9999px;
    opacity: 0;
  }

  &__label {
    position: relative;
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 0.16px;
    color: var(--primary-60);
    cursor: pointer;
    padding-left: 24px;

    &:before {
      width: 16px;
      height: 16px;
      display: block;
      background-color: var(--primary-60);
      position: absolute;
      top: 2px;
      left: 0;
      padding-right: 8px;
      content: '';
      -webkit-mask: url(../../../assets/images/icons/upload_ic.svg) no-repeat center;
      mask: url(../../../assets/images/icons/upload_ic.svg) no-repeat center;
    }

    &.-disabled {
      color: var(--gray-60);
      cursor: not-allowed;
      opacity: 0.6;
      &:before {
        background-color: var(--gray-60);
      }
    }
  }
}

.b-uploadInput {
  position: absolute;
  left: -9999px;
  opacity: 0;

  &__label {
    position: relative;
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 0.16px;
    color: var(--primary-60);
    cursor: pointer;

    &.-disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }

    &:before {
      position: relative;
      top: 2px;
      padding-right: 8px;
      content: url('../../../assets/images/icons/upload_ic.svg');
    }
  }
}

.b-dropDownMenu {
  border-radius: 8px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.05);
  background-color: var(--background);
  margin-top: 10px;
  width: 241px;
  overflow: hidden;

  ul {
    font-family: Rubik;
    font-size: 14px;
    color: var(--gray-60);

    li {
      cursor: pointer;
      padding: 12px 16px;

      &.-disabled {
        opacity: 0.3;
        cursor: inherit !important;
        background-color: inherit !important;
      }

      &:hover {
        background-color: var(--gray-10);
      }
    }
  }
}

.b-userManager {
  max-width: calc(50% - 20px);
  width: 100%;
  margin-bottom: 35px;
  @include media('<tablet') {
    display: flex;
    flex-direction: column;
  }

  .b-link-add {
    @include media('<tablet') {
      align-self: center;
    }
  }

  .b-link-add {
    @include media('<tablet') {
      align-self: center;
    }
  }

  &__table {
    width: 100%;
    margin: 0 0 35px;

    thead {
      td,
      th {
        font-weight: 700;
        color: var(--primary-navy);
        padding-right: 12px;
        padding-bottom: 15px;
      }
      td {
        text-align: left;
        min-width: 130px;
      }
    }
    td,
    th {
      font-size: 16px;
      padding: 8px 8px 8px 0;
    }
    tbody {
      th {
        font-weight: 700;
        text-align: left;
      }
      th,
      td {
        color: var(--gray-60);
      }
    }
    td.-right {
      display: flex;
      justify-content: flex-end;
    }
    th,
    td {
      &:last-child {
        text-align: right;
      }
    }
    .checkbox {
      display: inline-flex;
    }
  }
}

.b-hovered {
  position: relative;
  &:hover {
    .b-hovered__body {
      left: 0px;
    }
  }
  &__body {
    position: absolute;
    top: 35px;
    left: -9999px;
    width: 500px;
  }
  &__content {
    position: absolute;
    top: 0;
    left: 0;
    padding: 5px 10px;
    border-radius: 16px;
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
    background-color: var(--panel-background);
  }
}

.b-linkSelect {
  &__options {
    padding: 10px 0;
    position: absolute;
    top: 30px;
    left: 0;
    width: 200px;
    border-radius: 6px;
    background: var(--panel-background);
    box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.35);
  }

  &__item {
    display: flex;
    height: 40px;
    margin: 0 5px;
    padding: 0 10px;
    align-items: center;
    &:hover {
      background-color: var(--chartBg2);
    }
  }
}

.b-triangleIcon {
  border-style: solid;
  transform: rotate(90deg);
  border-width: 0 5px 6px 5px;
  border-color: transparent transparent var(--primary-60) transparent;
  &.-opened {
    transform: rotate(180deg);
  }
}

.b-tableDateFilter {
  &__modal {
    margin-top: 10px;
    padding: 10px;
    max-width: 385px;
    width: 100vw;
  }

  &__opt {
    color: var(--gray-60);
    display: block;
    padding: 8px 12px;
    border-radius: 6px;
    font-size: 16px;

    &:hover {
      background-color: var(--chartBg2);
    }

    &.-active {
      color: var(--primary-60);
    }
  }

  &__row {
    color: var(--gray-60);
    padding: 8px 12px;

    .react-datepicker-wrapper {
      width: 105px;
      input::placeholder {
        color: var(--gray-60);
      }
    }
  }

  &__rowTitle {
    position: relative;
    z-index: 10;
    padding-right: 5px;
  }

  &__date {
    position: relative;
    top: -3px;
  }
}

.b-sendEmailModal {
  &__boxWrapper {
    max-width: 450px;
    width: 100%;
    margin: 0 auto;
  }
  &__box {
    font-size: 16px;
    border: 1px solid var(--gray-60);
    max-width: 450px;
    width: 100%;
    height: 150px;
    padding: 5px;
    overflow: auto;
    box-sizing: border-box;
    align-items: flex-start;
    align-content: flex-start;
    display: flex;
    flex-wrap: wrap;
    cursor: text;
  }
  &__emailItem {
    margin: 5px 10px;
    cursor: pointer;
    font-weight: 700;
    &:after {
      content: ' X';
    }
  }
  &__input {
    color: var(--primary-navy);
    background: transparent;
    min-width: 45%;
    width: 100%;
    margin: 5px 10px 10px;
    border: 0;
    outline: none;
  }
}
