.b-carouselWrapper {
  max-width: 800px;
  position: relative;
  margin-bottom: 40px;

  .react-multi-carousel {
    &-dot {
      &-list {
        bottom: -25px;
      }
      button {
        border-width: 0;
        background: #b2b2b2;
      }
      &--active {
        button {
          background: var(--primary-60);
        }
      }
    }

    &-list {
      padding: 15px 0;
    }
    &-item {
      &--active {
        .item {
          box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
  .react-multiple-carousel {
    &__arrow {
      background-color: var(--primary-60);
      min-width: 32px;
      min-height: 32px;
      &:disabled {
        background-color: var(--gray-10);
        opacity: 1;
        &:before {
          color: #b2b2b2;
        }
      }
      &::before {
        font-size: 14px;
      }
      &--right {
        right: 0;
      }
      &--left {
        left: 0;
      }
    }
  }
}

.b-carouselCommonItem {
  padding: 20px;
  margin: 0 10px;
  height: 200px;
  box-sizing: border-box;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.15) !important;
  display: block;
  position: relative;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;

    &.-session {
      margin-bottom: 0px;
    }
  }

  &__user {
    font-size: 12px;
    font-weight: bold;
    line-height: 1.67;
    color: var(--primary-60);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.-read {
      font-weight: normal;
      opacity: 0.8;
    }
  }

  &__date {
    font-size: 10px;
    font-weight: 500;
    line-height: 2;
    color: var(--text-color-10);

    &.-session {
      margin-bottom: 10px;
    }
  }

  &__dateWrap {
    display: flex;
    align-items: center;
    justify-content: center;

    .b-button-icon {
      margin-left: 4px;
      width: 24px;
      height: 24px;
    }
  }

  &__img {
    width: 25px;
    margin-left: 5px;
  }

  &.-old {
    opacity: 0.3;
  }
}
