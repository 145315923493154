.body {
  text-align: center !important;
  color: #555 !important;
  max-width: 500px;
  border-radius: 20px !important;
  opacity: 1 !important;
  padding: 4px 6px !important;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.15) !important;
  &:hover {
    visibility: visible !important;
    opacity: 1 !important;
  }
}
