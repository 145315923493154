.b-myTasksItemList {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media screen and (min-width: 1360px) {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: 30px;
  }

  @include media('<tablet') {
    display: block;
  }
}

.b-myTasksItem {
  width: 100%;
  max-width: calc(50% - 15px);
  margin-bottom: 30px;
  box-sizing: border-box;
  padding: 36px;
  border-radius: 16px;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
  background-color: var(--panel-background);
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media screen and (min-width: 1360px) {
    max-width: 490px;
    margin-bottom: 0px;
  }

  @include media('<tablet') {
    max-width: 100%;
  }

  &__title {
    color: var(--primary-navy);
    font-size: 14px;
    font-family: Rubik;
    display: block;
    display: flex;
    align-items: center;
  }

  &__subTitle {
    margin-top: 5px;
    font-size: 12px;
    margin-bottom: 20px;
    font-family: Rubik;
    color: var(--primary-navy);
  }

  &__icon {
    background-color: var(--primary-navy);
    width: 16px;
    min-width: 16px;
    height: 16px;
    margin-right: 12px;

    &_resource {
      -webkit-mask: url(../../images/icons/resource_library_ic.svg) no-repeat center;
      mask: url(../../images/icons/resource_library_ic.svg) no-repeat center;
    }
    &_book {
      -webkit-mask: url(../../images/icons/group.svg) no-repeat center;
      mask: url(../../images/icons/group.svg) no-repeat center;
    }
    &_document {
      -webkit-mask: url(../../images/icons/group-5.svg) no-repeat center;
      mask: url(../../images/icons/group-5.svg) no-repeat center;
    }
    &_audit {
      min-width: 17px;
      -webkit-mask: url(../../images/icons/audit.svg) no-repeat center;
      mask: url(../../images/icons/audit.svg) no-repeat center;
    }
    &_elm {
      -webkit-mask: url(../../images/icons/elm.svg) no-repeat center;
      mask: url(../../images/icons/elm.svg) no-repeat center;
    }
    &_ea {
      -webkit-mask: url(../../images/icons/ea.svg) no-repeat center;
      mask: url(../../images/icons/ea.svg) no-repeat center;
    }
    &_calendar {
      -webkit-mask: url(../../images/icons/round-action-calendar-today.svg) no-repeat center;
      mask: url(../../images/icons/round-action-calendar-today.svg) no-repeat center;
    }
    &_cohort {
      -webkit-mask: url(../../images/icons/cohort.svg) no-repeat center;
      mask: url(../../images/icons/cohort.svg) no-repeat center;
    }
    &_ewp {
      width: 20px;
      height: 20px;
      -webkit-mask: url(../../images/icons/ewp/icons-ewp-active.svg) no-repeat center;
      mask: url(../../images/icons/ewp/icons-ewp-active.svg) no-repeat center;
    }
  }

  table {
    width: 100%;
    margin-bottom: 18px;

    td,
    th {
      color: var(--gray-60);
      vertical-align: top;
      padding: 10px 0;
      font-size: 14px;
    }

    th {
      text-align: left;
      font-weight: 400;
      padding-right: 15px;
    }
    td {
      font-weight: 700;
      overflow-wrap: break-word;
    }
  }

  &__tools {
    display: flex;
    justify-content: space-between;
    margin-top: auto;
  }

  &__btn {
    color: var(--primary-60);
    font: 700 16px/1.2 'Rubik';
    display: inline-flex;
    background-color: transparent;
    padding: 0;

    &:hover {
      text-decoration: underline;
    }

    &_reject,
    &_reschedule,
    &_accept {
      &:before {
        content: '+';
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: var(--primary-60);
        margin-right: 6px;
        flex-shrink: 0;
        color: var(--white);
        font-size: 18px;
        line-height: 20px;
        font-weight: 500;
      }
    }

    &_reject {
      margin: 0 auto;
      &:before {
        content: '-';
      }
    }
    &_reschedule {
      &:before {
        content: '';
        border-radius: 0;
        background-color: var(--primary-60);
        -webkit-mask: url(../../images/icons/round-action-calendar-today.svg) no-repeat center;
        mask: url(../../images/icons/round-action-calendar-today.svg) no-repeat center;
      }
    }
  }

  &__delete {
    font: 14px/1.2 'Rubik';
    background-color: transparent;
    text-decoration: underline;
    color: var(--gray-60);
    margin-left: auto;

    &:hover {
      text-decoration: none;
    }
  }
}
