.b-calendar {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .nextLabel {
    transform: rotateY(180deg);
  }

  .react-calendar {
    border: 1px solid var(--gray-20);
    border-radius: 8px;
    width: 328px;
    overflow: hidden;

    &__month-view {
      &__weekdays {
        background-color: var(--bd-color1);
        padding: 0 8px;
        border-bottom: 1px solid var(--gray-20);
        color: var(--black-white);

        &__weekday {
          width: 36px !important;
          max-width: 36px !important;
          margin: 4px !important;
          padding: 0 !important;

          abbr {
            text-transform: capitalize;
            text-decoration: none;
            font-weight: normal;
            font-size: 14px;
          }
        }
      }
      &__days {
        padding: 0 8px;
        background: var(--background);
        &__day {
          &-exist {
            background-color: rgba(216, 216, 216, 0.6) !important;
          }

          &--weekend {
            color: #d10000 !important;
          }

          &--neighboringMonth {
            color: #757575;
          }
        }
      }
    }
    &__tile {
      flex-basis: inherit !important;
      width: 36px !important;
      margin: 4px !important;
      border-radius: 8px;
      font-size: 14px;
      color: var(--black-white);

      &:enabled {
        &:hover {
          background-color: var(--gray-10);
        }
      }

      &--active {
        background-color: var(--primary-60) !important;
        color: var(--white-black);
        font-weight: bold;
      }

      &:disabled {
        background-color: inherit;
        opacity: 0.5;
        //color: var(--black-white);
      }
    }

    &__navigation {
      border-radius: 8px 8px 0 0;
      margin-bottom: 0;
      background-color: var(--bd-color1);

      button {
        background: var(--bd-color1);
      }

      button:disabled {
        background-color: var(--bd-color1);
        opacity: 1;
        span {
          color: #000;
          font-weight: bold;
          font-size: 21px;
        }
      }
    }
  }

  .times {
    display: flex;
    width: 332px;
    flex-wrap: wrap;

    .time {
      width: 77px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: solid 1px var(--gray-20);
      border-radius: 8px;
      cursor: pointer;
      user-select: none;
      padding: 12px 0;
      color: var(--gray-60);
      margin: 4px 2px;
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;

      &-active {
        color: var(--white-black);
        border: solid 1px var(--primary-60);
        background-color: var(--primary-60);
      }
      &-disabled {
        opacity: 0.5;
        cursor: inherit;
        display: none;
      }
    }
  }
  .timeZone {
    margin-top: 7px;
    width: calc(100% - 10px);
  }
  .btns {
    display: flex;
    width: 322px;
    justify-content: space-between;
  }
}
