.modal {
  padding: 40px;

  h2 {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    color: var(--primary-navy);
    text-align: center;
  }

  p {
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    color: var(--gray-60);
  }
}

.cross {
  position: absolute;
  right: 16px;
  top: 16px;
}
