.header {
  font-size: 14px;
  flex: 0 0 auto;
  display: flex;
  height: 80px;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  background-color: var(--background);
  z-index: 99;
}
