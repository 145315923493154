.title {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: var(--gray-60);
  width: 328px;
  margin-bottom: 40px;
}

.wrap {
  align-items: center;
  justify-content: center;
  width: 352px;
  margin-top: 20px;

  @media screen and (max-width: 824px) {
    width: 328px;
  }
}

.options {
  display: flex;
  flex-direction: column;

  &_title {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--primary-navy);
  }

  &_text {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.16px;
    color: var(--gray-60);
  }

  &_item {
    margin-top: 12px;
  }
}

.action {
  color: var(--primary-60);
  text-decoration: underline;
  cursor: pointer;
}

.text {
  margin-top: 2px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.16px;
  color: var(--gray-60);
}
