.title_wrapper {
  margin-bottom: 30px !important;
}
.forgot_password {
  margin-top: 10px !important;
}

.errorWrap {
  margin: -10px 0 0 0;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

//.login_modal {
//  transform: translate(-50%, -58%) !important;
//
//  @media screen and (max-width: 824px) {
//    transform: translate(-50%, -45%) !important;
//  }
//}
